import GuideRoom from '../views/Guide/GuideRoom/GuideRoom.vue';
import LiveCamera from '../views/Guide/GuideRoom/LiveCamera.vue';

export default
[
  {
    name: 'GuideRoom',
    path: '/GuideRoom',
    component: GuideRoom,
    meta: {
      title: 'Guide Room'
      // requiresAuth: true
    }
  },
  {
    name: 'LiveCamera',
    path: '/LiveCamera',
    component: LiveCamera,
    meta: {
      title: 'Live Camera'
    }
  }
];
