<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="modelName">ModelID/mcID</label>
              <input type="text" class="form-control"
                v-model="cond.mixModelName" />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="class1">Gen Unit / 元件種類</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{rdGenUnit===10 ? 'ARC' : 'Platform'}}</button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#"
                      v-if="isRDArc==='Y'"
                      v-on:click.prevent="changeRdUnit(10)">ARC</a>
                    <a class="dropdown-item" href="#"
                      v-if="isRDPlatform==='Y'"
                      v-on:click.prevent="changeRdUnit(20)">Platform</a>
                  </div>
                </div>
                <select id="class1" class="form-control" v-model="cond.deviceCategory">
                  <option value=""></option>
                  <option v-for="(item, index) in optClass1"
                    v-bind:value="item.categoryId" :key="index">
                    {{ item.categoryName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="class1">建立者</label>
              <emp-picker v-model="multiEmp"></emp-picker>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="class1">建立時間</label>
              <div>
                <date-picker
                  v-model="cond.createdTimeStart"
                  :clearable="false"
                  style="width: 45%"
                  format="YYYY/MM/DD"
                ></date-picker>
                <date-picker
                  v-model="cond.createdTimeEnd"
                  :clearable="false"
                  style="width: 45%"
                  format="YYYY/MM/DD"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div v-if="cond.deviceCategory === 60"
            class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="deviceActiveLayer">元件主動層</label>
              <select id="deviceActiveLayer" v-model="cond.deviceActiveLayer"
                :class="{ 'form-control': true, 'is-invalid': failed }">
                <option value=""></option>
                <option v-for="(item, index) in optClass2"
                  v-bind:value="item.categoryId" :key="index">
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
          </div>

          <template v-if="cond.deviceCategory === 60 && cond.deviceActiveLayer === 170">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="fabName">Fab Name </label>
                <select id="fabName" v-model="cond.fabName"
                  :class="{ 'form-control': true, 'is-invalid': failed }">
                  <option value=""></option>
                  <option v-for="(item, index) in selectOptions.optFabName"
                    v-bind:value="item.value" :key="index">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="pep">PEP </label>
                <select id="pep" v-model="cond.pep"
                  :class="{ 'form-control': true, 'is-invalid': failed }">
                  <option value=""></option>
                  <option v-for="(item, index) in selectOptions.optPep"
                    v-bind:value="item.value" :key="index">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="cfStracture">CF Structure </label>
                <select id="cfStracture" v-model="cond.cfStracture"
                  :class="{ 'form-control': true, 'is-invalid': failed }"
                  @change="getPfCondOptions()">
                  <option value=""></option>
                  <option v-for="(item, index) in selectOptions.optCfStracture"
                    v-bind:value="item.value" :key="index">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="m1M2">M1/M2 </label>
                <select id="m1M2" v-model="cond.m1M2"
                  :class="{ 'form-control': true, 'is-invalid': failed }">
                  <option value=""></option>
                  <option v-for="(item, index) in selectOptions.optM1M2"
                    v-bind:value="item.value" :key="index">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="tftQualityRecipe">元件膜質</label>
                <input type="text" class="form-control"
                  v-model="cond.tftQualityRecipe"/>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="tftChannelMetalLen">TFT Channel Metal Length
                </label>
                <input type="text" class="form-control"
                  v-model="cond.tftChannelMetalLen"/>
              </div>
            </div>
          </template>

        </div>
      </div>
      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="clearCond()"
        >
          <i class="fas fa-times"></i> 清空
        </button>
        <button
          class="btn btn-primary"
          v-on:click.prevent="search(true)"
          type="button"
        >
          <i class="fas fa-search"></i> 查詢
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-right">
        <button
          v-if="uploadPermission==='Y'"
          class="btn btn-info"
          v-on:click.prevent="createModelCard1()"
        >
          <i class="fas fa-plus-circle"></i> 新增 Model Card
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>mcID</th>
                  <th>Gen Unit</th>
                  <th>元件資訊</th>
                  <th>Fab Name</th>
                  <th>W / L</th>
                  <th>其他資訊</th>
                  <th>修改者</th>
                  <th style="width: 150px">內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in dataList" :key="item.mcId">
                  <td class="align-middle">
                    {{ item.mcId }}
                  </td>
                  <td class="align-middle">{{ item.rdGenUnitTitle }}</td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 30 || item.deviceCategory === 60">
                      {{ item.deviceCategoryTitle}} > {{ item.deviceActiveLayerTitle }}
                    </span>
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      {{ item.deviceCategoryTitle}} > {{ item.ledColorTitle }}
                    </span>
                    <span v-if="item.deviceCategory === 50">
                      {{ item.deviceCategoryTitle}} > {{ item.oledProcessTitle }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      -
                    </span>
                    <span v-else>
                      {{ item.fabName }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 30 || item.deviceCategory === 60">
                      {{ item.tegChannelWidth}}
                      / {{item.tegChannelLength}}{{ item.tftChannelMetalLen}}
                    </span>
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      {{ item.ledWidth}} / {{ item.ledLength}}
                    </span>
                    <span v-if="item.deviceCategory === 50">
                      -
                    </span>
                  </td>
                  <td class="align-middle">
                    <template v-if="item.deviceCategory === 60 && item.deviceActiveLayer === 170">
                      <span class="badge badge-light mx-1" title="PEP">
                        {{item.pepTitle}}
                      </span>
                      <span class="badge badge-light mx-1" title="M1/M2">
                        {{item.m1M2Title}}
                      </span>
                    </template>
                    <template v-else>
                      <span class="badge badge-light mx-1" title="ModelID">
                        {{item.modelId}}
                      </span>
                    </template>
                  </td>
                  <td class="align-middle small">
                    {{ item.lastUser + " / " + item.lastUserName }}<br />
                    {{ item.lastTime | formatDateTime }}
                  </td>
                  <td class="align-middle">
                    <template v-if="uploadPermission === 'Y'">
                      <button class="btn btn-sm btn-secondary"
                        @click.prevent="viewModelCard(item.mcId,
                          item.deviceCategory, item.deviceActiveLayer)">
                        <i class="fas fa-upload"></i> 檢視
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pagination :totalRecords="totalRecords"
      @pageChangeEvent="pageChange" ref="pagin">
    </pagination>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import EmpPicker from '@/components/EmpPicker.vue';
import {
  RawDataApiService,
  ModelCardApiService,
  CodeMappingApiService
} from '@/utils/apiService';

export default {
  components: {
    Pagination,
    EmpPicker
  },
  data() {
    return {
      cond: {
        pageSize: 10,
        pageNum: 1,
        createdUser: null,
        rdGenUnit: null,
        deviceCategory: null,
        deviceActiveLayer: null
      },
      totalRecords: 0,
      dataList: [],
      optClass1: [],
      optClass2: [],
      optClass3: [],
      selectOptions: {
        optFabName: [],
        optPep: [], // 60
        optCfStracture: [], // 60
        optM1M2: [] // 60
      },
      multiEmp: {}
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      uploadPermission: 'getUploadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    })
  },
  watch: {
    'cond.deviceCategory': function (val) {
      const vm = this;
      vm.optClass2 = [];
      vm.cond.deviceActiveLayer = null;
      if (val) {
        vm.getCategoryList(val, 3);
      }
    },
    'cond.deviceActiveLayer': function (val) {
      const vm = this;
      if (val) {
        vm.$bus.$emit('setLoading', true);
        switch (val) {
          case 170:
            Promise.all([
              vm.getCodeMapping('pep', 1),
              vm.getCodeMapping('cf_stracture', 1),
              vm.getCodeMapping('m1_m2', 1)
            ]).then((res) => {
              vm.selectOptions.optPep = res[0];
              vm.selectOptions.optCfStracture = res[1];
              vm.selectOptions.optM1M2 = res[2];
            });
            break;
        }
        vm.$bus.$emit('setLoading', false);
      }
    }
  },
  methods: {
    changeRdUnit(unitId) {
      const vm = this;
      store.dispatch('setRdUnit', unitId); // vuex
      console.log('assign to', vm.rdGenUnit);
      vm.clearCond();
      vm.cond.rdGenUnit = vm.rdGenUnit; // required
      vm.getCategoryList(vm.rdGenUnit, 2);
    },
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList,
        ifCheckAuth: 1 // upload
      };

      RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        // console.log(result);
        if (result.success) {
          switch (categoryLevel) {
            case 2:
              vm.optClass1 = result.data;
              break;
            case 3:
              vm.optClass2 = result.data;
              break;
          }
        }
      });
    },
    getCodeMapping(typeName, ifValue2Int) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        if (!ifValue2Int) { return result.data; }
        // 轉數字
        let r2int = [];
        if (result.data && result.data.length > 0) {
          r2int = result.data.map((item) => {
            const a = item;
            a.value = Number(a.value);
            return a;
          });
        }
        return r2int;
      });
    },
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 10,
        pageNum: 1,
        createdUser: null,
        deviceCategory: null,
        deviceActiveLayer: null
      };
      vm.multiEmp = {};
    },
    search(isNewCondition) {
      const vm = this;

      if (isNewCondition) {
        vm.cond.pageNum = 1;
        vm.$refs.pagin.resetPage();
      }

      vm.cond.rdGenUnit = vm.rdGenUnit; // required
      vm.cond.roleIdList = vm.roleIdList;
      vm.cond.createdUser = null; // emppicker
      if (vm.multiEmp && vm.multiEmp.empNo) {
        vm.cond.createdUser = vm.multiEmp.empNo;
      }
      ModelCardApiService.getModelCardList1(vm.cond).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.dataList = res.data.items;
          vm.totalRecords = res.data.totalRecords;
        }
      });
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    },
    createModelCard1() {
      const vm = this;
      vm.$router.push({
        name: 'CreateModelCard1'
      });
      vm.$bus.$emit('setLoading', true);
    },
    viewModelCard(id, dCategory, dActiveLayer) {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardDetail',
        params: {
          mcId: id,
          deviceCategory: dCategory,
          deviceActiveLayer: dActiveLayer,
          retUrl: 'ModelCardIndex'
        }
      });
      vm.$bus.$emit('setLoading', true);
    }
  },
  mounted() {
    const vm = this;
    vm.$bus.$emit('setLoading', true);
    // 下載不區分權限(上傳要)
    // if (!vm.rdGenUnit) {
    //   vm.$router.push({
    //     name: 'Home'
    //   });
    // }

    // 上傳權限: 重取 ARC/Platform
    console.log('index/isRDArc:', vm.isRDArc);
    console.log('index/isRDPlatform:', vm.isRDPlatform);
    if (vm.isRDArc === 'Y') {
      store.dispatch('setRdUnit', 10);
    }
    if (vm.isRDPlatform === 'Y') {
      store.dispatch('setRdUnit', 20);
    }

    // vm.cond.createdUser = vm.userInfo.userId;
    vm.multiEmp = {
      empNo: vm.userInfo.userId,
      empName: vm.userInfo.cname
    };

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCategoryList(vm.rdGenUnit, 2),
      vm.getCodeMapping('fab')
    ]).then((res) => {
      vm.selectOptions.optFabName = res[1];
      vm.$bus.$emit('setLoading', false);
    });

    vm.search();
  }
};
</script>
