<template>
  <div class="upload-drag card border">
    <label :for="'fu-' + _uid.toString()" class="m-0 file-uploads-html5">
      <div class="upload card-body text-center p-2">
        <div
          v-if="files.length"
          class="border-0 card-group"
          style="min-height: 240px"
        >
          <div v-for="(ufile, index) in files" :key="index">
            <div class="card border">
              <div class="card-body">
                <button
                  type="button"
                  class="float-right mr-2 btn btn-outline-secondary btn-sm"
                  v-on:click.prevent="deleteFile(ufile, index)"
                >
                  <i class="fas fa-times"></i>
                </button>
                <div class="card-img-top text-sencondary pt-5">
                  <i class="fas fa-3x fa-file"></i>
                </div>
                <h6 class="card-title m-2">{{ ufile.name }}</h6>
                <label class="text-muted small mt-0 mb-1">
                  ( {{ ufile.size | filesize }} )
                </label>
                <div class="form-inline">
                  <label class="text-muted small mr-2">檔案說明</label>
                  <input type="text"
                    :id="'fdesc' + ufile.index"
                    class="form-control"
                    v-model="ufile.fDesc"
                    placeholder="請輸入說明"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="py-4">
          <div class="text-secondary h5" for="file">
            <i class="fas fa-plus"></i>
            <div class="my-1">點擊或拖拉上傳</div>
            <template v-if="fileType === 'xls'">
              <small class="text-muted">(支援csv、xls、xlsx)</small>
            </template>
            <template v-if="fileType === 'img'">
              <small class="text-muted">(支援jpg、png、gif)</small>
            </template>
            <template v-if="fileType === 'mod'">
              <small class="text-muted">(支援txt、mod)</small>
            </template>
            <template v-if="fileType === 'zip'">
              <small class="text-muted">(支援zip)</small>
            </template>
            <template v-if="fileType === 'ppt'">
              <small class="text-muted">(支援ppt、pptx)</small>
            </template>
          </div>
        </div>
      </div>
    </label>
    <file-upload
      :name="'fu-' + _uid.toString()"
      @input-file="inputFile"
      :headers="{
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'Cache-Control': 'no-cache',
      }"
      :multiple="isMultiple"
      :drop="true"
      v-model="files"
      @input-filter="inputFilter"
      :timeout="5000"
    >
    </file-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: []
    };
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: true
    },
    fileType: {
      type: String,
      default: 'xls'
    }
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      const vm = this;

      if (newFile && !oldFile) {
        // Filter file extension
        let regex = null;
        let typeMismatchErr = '';
        switch (vm.fileType) {
          case 'xls':
            regex = RegExp(/\.(csv|xls|xlsx)$/i);
            typeMismatchErr = '請上傳 csv、xls 或 xlsx 檔案!';
            break;
          case 'img':
            regex = RegExp(/\.(jpg|png|gif)$/i);
            typeMismatchErr = '請上傳 jpg、png 或 gif 檔案!';
            break;
          case 'mod':
            regex = RegExp(/\.(txt|mod)$/i);
            typeMismatchErr = '請上傳 txt 或 mod 檔案!';
            break;
          case 'ppt':
            regex = RegExp(/\.(ppt|pptx)$/i);
            typeMismatchErr = '請上傳 ppt 或 pptx 檔案!';
            break;
          case 'zip':
            regex = RegExp(/\.(zip)$/i);
            typeMismatchErr = '請上傳 zip 檔案!';
            break;
        }

        if (!regex.test(newFile.name)) {
          vm.$alert.warning(typeMismatchErr);
          return prevent();
        }
        if (newFile.size > 1024 * 1024 * 11) {
          vm.$alert.warning('檔案大小超過10MB!');
          return prevent();
        }
        if (newFile.name.length >= 100) {
          vm.$alert.warning('檔名超長或包含不合法字元!');
          return prevent();
        }
      }

      return true;
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        this.$emit('onUploaded', newFile);
      }
      if (!newFile && oldFile) {
        // remove
        this.$emit('onCancelUpload');
      }
    },
    deleteFile(file, index) {
      const vm = this;
      vm.$confirm('是否要删除檔案?').then((result) => {
        if (result.value) {
          vm.files.splice(index, 1);
          this.$emit('onDeleted', file, index);
        }
      });
    }
  }
};
</script>

<style scoped>
.text-sencondary {
  color: #c5afd2;
}
.upload-drag .py-4 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0px !important;
}
.upload-drag h5 {
  margin-bottom: 0px !important;
}
.file-uploads {
  display: none;
}
</style>
