<template>
  <div>
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
        <div class="chart-block my-2 p-2">
          <scatter-chart
            :chartData="getChartData()"
            :chartOptions="getChartOptions()"
            css-classes="chart-block"
            :width="600"
          ></scatter-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScatterChart from '@/components/Chart/ScatterChart.vue';
import _ from 'lodash';

export default {
  name: 'SpectrumChart',
  components: {
    ScatterChart
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    chartType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    chartData: {
      get() {
        return this.value;
      }
    }
  },
  methods: {
    getChartTitle() {
      const vm = this;
      let title = '';
      switch (vm.chartType) {
        case 'ColorFilterSpectrum':
          title = 'Color Filter Spectrum';
          break;
        case 'LedSpectrum':
          title = 'Led Spectrum';
          break;
        case 'plotLedWithColorFilterSpectrum':
          title = 'Led With Color Filter Spectrum';
          break;
      }
      return title;
    },
    getDataList() {
      const vm = this;
      const dataList = {
        r: [],
        g: [],
        b: []
      };

      switch (vm.chartType) {
        case 'ColorFilterSpectrum':
          dataList.r = _.map(vm.chartData.colorFilterSpectrum, (obj) => ({
            x: obj.spectrum,
            y: obj.valueR
          }));
          dataList.g = _.map(vm.chartData.colorFilterSpectrum, (obj) => ({
            x: obj.spectrum,
            y: obj.valueG
          }));
          dataList.b = _.map(vm.chartData.colorFilterSpectrum, (obj) => ({
            x: obj.spectrum,
            y: obj.valueB
          }));
          break;
        case 'LedSpectrum':
          dataList.r = _.map(vm.chartData.ledSpectrumR, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          dataList.g = _.map(vm.chartData.ledSpectrumG, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          dataList.b = _.map(vm.chartData.ledSpectrumB, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          break;
        case 'plotLedWithColorFilterSpectrum':
          dataList.r = _.map(vm.chartData.ledWithColorFilterSpectrumR, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          dataList.g = _.map(vm.chartData.ledWithColorFilterSpectrumG, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          dataList.b = _.map(vm.chartData.ledWithColorFilterSpectrumB, (obj) => ({
            x: obj.spectrum,
            y: obj.value
          }));
          break;
      }

      return dataList;
    },
    getChartData() {
      const vm = this;
      const dataList = vm.getDataList();

      const chartData = {
        datasets: [
          {
            label: 'R',
            data: dataList.r,
            fill: false,
            tension: 0.4,
            backgroundColor: '#C0504D',
            borderColor: '#C0504D',
            borderWidth: 3,
            pointStyle: 'circle',
            pointRadius: 3,
            showLine: true,
            order: 3
          },
          {
            label: 'G',
            data: dataList.g,
            fill: false,
            tension: 0.4,
            backgroundColor: '#9BBB59',
            borderColor: '#9BBB59',
            borderWidth: 3,
            pointStyle: 'circle',
            pointRadius: 3,
            showLine: true,
            order: 2
          },
          {
            label: 'B',
            data: dataList.b,
            fill: false,
            tension: 0.4,
            backgroundColor: '#4F81BD',
            borderColor: '#4F81BD',
            borderWidth: 3,
            pointStyle: 'circle',
            pointRadius: 3,
            showLine: true,
            order: 1
          }
        ]
      };

      return chartData;
    },
    getChartOptions() {
      const vm = this;
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        elements: {
          line: {
            fill: false
          },
          point: {
            radius: 0
          }
        },
        title: {
          display: true,
          text: vm.getChartTitle(),
          fontSize: 20,
          fontStyle: 'bold',
          lineHeight: 1.2,
          padding: 20
        },
        legend: {
          display: true,
          position: 'bottom',
          reverse: true
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: 'λ',
              fontColor: '#666666',
              fontSize: 14,
              fontStyle: 'bold',
              lineHeight: 1.2,
              padding: 10
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: 'S(λ)',
              fontColor: '#666666',
              fontSize: 14,
              fontStyle: 'bold',
              lineHeight: 1.2,
              padding: 10
            },
            ticks: {
              stepSize: 20
            }
          }]
        }
      };

      return chartOptions;
    }
  }
};
</script>
