import Vue from 'vue';
import moment from 'moment';
import Swal from 'sweetalert2';

// Time format
Vue.filter('formatDate', (value) => (value ? moment(value).format('YYYY/MM/DD') : 'N/A'));
Vue.filter('formatDateTime', (value) => (value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : 'N/A'));
Vue.filter('formatTimeHM', (value) => (value ? moment(value).format('HH:mm') : 'N/A'));
Vue.filter('moment', (value, format) => (value ? moment(value).format(format || 'YYYY/MM/DD HH:mm:ss') : 'N/A'));

// fileSize
Vue.filter('filesize', (size) => {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
  } if (size > 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  } if (size > 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  } if (size > 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${size.toString()} B`;
});

// string
Vue.filter('preStr', (value, preStr, midStr) => {
  if (value || value === 0) {
    if (midStr && midStr.length > 0) {
      return `${preStr}${midStr}${value}`;
    }
    return `${preStr}${value}`;
  }
  return '';
});
Vue.filter('appStr', (value, appStr, midStr) => {
  if (value || value === 0) {
    if (midStr && midStr.length > 0) {
      return `${value}${midStr}${appStr}`;
    }
    return `${value}${appStr}`;
  }
  return '';
});

Vue.filter('percentage', (value, precision) => {
  if (value || value === 0) {
    return `${Number(value * 100).toFixed(precision)}%`;
  }
  return '0%';
});

// Alert
const Toast = Swal.mixin({
  // toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 1000
});

Vue.prototype.$alert = {
  error(message) {
    return Toast.fire({
      icon: 'error',
      title: message,
      timer: 0
    });
  },
  warning(message) {
    return Toast.fire({
      icon: 'warning',
      title: message
    });
  },
  info(message) {
    return Toast.fire({
      icon: 'info',
      title: message
    });
  },
  question(message) {
    return Toast.fire({
      icon: 'question',
      title: message
    });
  },
  success(message) {
    return Toast.fire({
      icon: 'success',
      title: message
    });
  }
};

// Confirm
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});
Vue.prototype.$confirm = (message) => swalWithBootstrapButtons.fire({
  text: message,
  icon: 'question',
  showCancelButton: true,
  confirmButtonText: '確定',
  cancelButtonText: '取消',
  reverseButtons: true
});
