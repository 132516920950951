<template>
  <div>
    <div class="card">
      <div class="card-body pb-0">
        <div>
          <basic-info :mcInfo="mcInfo" :sheetPrefix="sheetPrefix" />
        </div>
        <div class="card border bg-info">
          <div class="card-body pt-2 pb-0">
            <div class="row mb-2">
              <div
                v-if="sheetPrefix === 'Pixel'"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="pixelMeasurementMethod"
                    >Pixel Measurement Method</label
                  >
                  <select
                    id="pixelMeasurementMethod"
                    v-model="cond.pixelMeasurementMethod"
                    class="form-control"
                  >
                    <option value=""></option>
                    <option
                      v-for="(
                        item, index
                      ) in selectOptions.pixelMeasurementMethodList"
                      v-bind:value="item"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="temp">Temp</label>
                  <select id="temp" v-model="cond.temp" class="form-control">
                    <option value=""></option>
                    <option
                      v-for="(item, index) in selectOptions.tempList"
                      v-bind:value="item"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="width">Width</label>
                  <select id="width" v-model="cond.width" class="form-control">
                    <option value=""></option>
                    <option
                      v-for="(item, index) in selectOptions.widthList"
                      v-bind:value="item"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="length">Length</label>
                  <select
                    id="legnth"
                    v-model="cond.length"
                    class="form-control"
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in selectOptions.lengthList"
                      v-bind:value="item"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="card border">
              <div class="card-header bg-dark">
                <h5 class="text-white mb-0">IdVg</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <chart-info
                      :sheetPrefix="sheetPrefix"
                      sheetSuffix="IdVg"
                      :dataList="idVgFittingData"
                    ></chart-info>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card border">
              <div class="card-header bg-dark">
                <h5 class="text-white mb-0">IdVd</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <chart-info
                      :sheetPrefix="sheetPrefix"
                      sheetSuffix="IdVd"
                      :dataList="idVdFittingData"
                    ></chart-info>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card border">
              <div class="card-header bg-dark">
                <h5 class="text-white mb-0">比較表</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <template v-if="sheetPrefix === 'Pixel'">
                      <pixel-comparison
                        :dataList="pixelComparisonDataList"
                        :sheetPrefix="sheetPrefix"
                        :comparisonType="1"
                      ></pixel-comparison>
                    </template>
                    <template v-if="sheetPrefix === 'GOA'">
                      <goa-comparison-1
                        :dataList="goaComparisonDataList1"
                        :sheetPrefix="sheetPrefix"
                        :comparisonType="2"
                      ></goa-comparison-1>
                      <goa-comparison-2
                        class="mt-2"
                        :dataList="goaComparisonDataList2"
                        :sheetPrefix="sheetPrefix"
                        :comparisonType="3"
                      ></goa-comparison-2>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-secondary"
          style="width: 120px"
          v-on:click.prevent="goBack()"
        >
          <i class="fas fa-arrow-circle-left"></i> 返回
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInfo from '@/views/Core/IVComparison/MeasurementAndFitting/BasicInfo.vue';
import ChartInfo from '@/views/Core/IVComparison/MeasurementAndFitting/ChartInfo.vue';
import PixelComparison from '@/views/Core/IVComparison/MeasurementAndFitting/PixelComparison.vue';
import GoaComparison1 from '@/views/Core/IVComparison/MeasurementAndFitting/GOAComparison1.vue';
import GoaComparison2 from '@/views/Core/IVComparison/MeasurementAndFitting/GOAComparison2.vue';
import {
  ModelCardApiService,
  MeasurementNFittingService
} from '@/utils/apiService';
import _ from 'lodash';

export default {
  components: {
    BasicInfo,
    ChartInfo,
    PixelComparison,
    GoaComparison1,
    GoaComparison2
  },
  data() {
    return {
      cond: {
        pixelMeasurementMethod: null,
        temp: null,
        length: null,
        width: null
      },
      selectOptions: {
        pixelMeasurementMethodList: [],
        tempList: [],
        widthList: [],
        lengthList: []
      },
      mcInfo: {},
      idVgFittingData: [],
      idVdFittingData: [],
      pixelComparisonDataList: null,
      goaComparisonDataList1: null,
      goaComparisonDataList2: null
    };
  },
  props: {
    mcId: { type: Number, default: 0 },
    sheetPrefix: { type: String, default: '' }
  },
  watch: {
    cond: {
      handler() {
        const vm = this;
        vm.refeshData();
      },
      deep: true
    }
  },
  methods: {
    refeshData() {
      const vm = this;

      vm.idVgFittingData = [];
      vm.idVdFittingData = [];

      vm.$bus.$emit('setLoading', true);
      Promise.all([vm.getIdVgFittingData(), vm.getIdVdFittingData()])
        .then((res) => {
          vm.idVgFittingData = res[0];
          vm.idVdFittingData = res[1];
        })
        .finally(() => {
          vm.$bus.$emit('setLoading', false);
        });
    },
    goBack() {
      const vm = this;
      vm.$router.push({
        name: 'MeasurementAndFitting'
      });
    },
    getMCInfo() {
      const vm = this;
      if (vm.mcId === 0) {
        return null;
      }

      const params = {
        mcId: vm.mcId,
        deviceCategory: 60,
        deviceActiveLayer: 170
      };

      return ModelCardApiService.getModelCardDetail1(params).then(
        (response) => {
          const result = response.data;
          if (result.success) {
            vm.mcInfo = result.data;
          } else {
            vm.$alert.error(response.message);
          }
          return result.data;
        }
      );
    },
    getIdVgFittingData() {
      const vm = this;
      const param = {
        mcId: vm.mcId,
        sheetPrefix: vm.sheetPrefix,
        sheetSuffix: 'IdVg',
        temp: vm.cond.temp,
        width: vm.cond.width,
        length: vm.cond.length,
        pixelMeasurementMethod: null
      };

      if (vm.sheetPrefix === 'Pixel') {
        param.pixelMeasurementMethod = vm.cond.pixelMeasurementMethod;
      }

      return MeasurementNFittingService.getFittingData(param).then(
        (response) => {
          const result = response.data;
          return result.data;
        }
      );
    },
    getIdVdFittingData() {
      const vm = this;
      const param = {
        mcId: vm.mcId,
        sheetPrefix: vm.sheetPrefix,
        sheetSuffix: 'IdVd',
        temp: vm.cond.temp,
        width: vm.cond.width,
        length: vm.cond.length,
        pixelMeasurementMethod: null
      };

      if (vm.sheetPrefix === 'Pixel') {
        param.pixelMeasurementMethod = vm.cond.pixelMeasurementMethod;
      }

      return MeasurementNFittingService.getFittingData(param).then(
        (response) => {
          const result = response.data;
          return result.data;
        }
      );
    },
    getComparisonData(comparisonType) {
      const vm = this;

      const param = {
        mcId: vm.mcId,
        sheetPrefix: vm.sheetPrefix,
        comparisonType
      };

      return MeasurementNFittingService.getComparisonInfo(param).then(
        (response) => {
          const result = response.data;
          return result.data;
        }
      );
    }
  },
  mounted() {
    const vm = this;

    vm.getMCInfo().then((response) => {
      vm.mcInfo = response;

      vm.$bus.$emit('setLoading', true);
      const promiseList = [vm.getIdVgFittingData(), vm.getIdVdFittingData()];

      if (vm.sheetPrefix === 'Pixel') {
        promiseList.push(vm.getComparisonData(1));
      }
      if (vm.sheetPrefix === 'GOA') {
        promiseList.push(vm.getComparisonData(2));
        promiseList.push(vm.getComparisonData(3));
      }

      Promise.all(promiseList)
        .then((res) => {
          vm.idVgFittingData = res[0];
          vm.idVdFittingData = res[1];
          const allFilttingData = _.concat(res[0], res[1]);
          if (vm.sheetPrefix === 'Pixel') {
            vm.selectOptions.pixelMeasurementMethodList = _.uniq(
              _.map(allFilttingData, 'pixelMeasurementMethod')
            );
          }
          vm.selectOptions.tempList = _.uniq(_.map(allFilttingData, 'temp'));
          vm.selectOptions.widthList = _.uniq(_.map(allFilttingData, 'width'));
          vm.selectOptions.lengthList = _.uniq(
            _.map(allFilttingData, 'length')
          );

          if (vm.sheetPrefix === 'Pixel') {
            vm.pixelComparisonDataList = res[2];
          }
          if (vm.sheetPrefix === 'GOA') {
            vm.goaComparisonDataList1 = res[2];
            vm.goaComparisonDataList2 = res[3];
          }
        })
        .finally(() => {
          vm.$bus.$emit('setLoading', false);
        });
    });
  }
};
</script>
