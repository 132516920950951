<template>
  <div>
    <div class="row mt-3 mb-3">
      <div class="col-auto">
        <div class="h3 text-muted">申請權限請洽 Yuwei</div>
        <!-- <div class="img-responsive text-center">
          <img src="../assets/img/F224auth1.png" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
