import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {
  BE_SERVICE_URL,
  RDAUTHORITY_SERVICE_URL,
  RDAUTHORITY_SYSTEM_ID,
  GUIDE_SERVICE_URL
} from '@/utils/config';
import qs from 'qs';
import {
  capApiUrl,
  capCompany,
  capSysId
} from '@/capAuth';

const guideBaseURL = String(GUIDE_SERVICE_URL);

/* axios default Setting */
axios.defaults.baseURL = BE_SERVICE_URL;
// axios.defaults.baseURL = guideBaseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json charset=utf-8';
// 解決cache 問題
axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    // 統一每個Requset 要執行的工作
    axios.interceptors.request.use(
      (config) => {
        Vue.prototype.$bus.$emit('setLoading', true);
        return config;
      },
      (error) => {
        Vue.prototype.$bus.$emit('setLoading', false);
        return Promise.reject(error);
      }
    );
    // 統一每個Response 要執行的工作
    axios.interceptors.response.use(
      (response) => {
        if (
          Object.prototype.hasOwnProperty.call(response.data, 'success')
          && response.data.success === false
        ) {
          Vue.prototype.$alert.error(response.data.message);
        }
        // console.log(response);
        Vue.prototype.$bus.$emit('setLoading', false);
        return response;
      },
      (error) => {
        Vue.prototype.$bus.$emit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    // TODO: authorize here
  },

  query(resource, params) {
    return Vue.axios.get(resource, {
      params,
      // 參數可傳Array
      paramsSerializer() {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        });
      }
    });
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  patch(resource, slug = '', params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },
  patchBatch(resource, params, headers) {
    return Vue.axios.patch(`${resource}`, params, headers);
  },
  delete(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}`);
  },
  deleteByParams(resource, params) {
    return Vue.axios.delete(`${resource}`, { params });
  },
  postFile(resource, params, headers) {
    return Vue.axios.post(`${resource}`, params, headers);
  },
  downloadFile(resource, params) {
    return Vue.axios.get(resource, {
      params,
      responseType: 'blob'
    });
  }
};
// 建立沒有Interceptors的Axios For Timer
const CleanAxios = axios.create();

// eslint-disable-next-line no-unused-vars
const offInterceptorsApiService = {
  query(resource, params) {
    return CleanAxios.get(resource, {
      params,
      // 參數可傳Array
      paramsSerializer() {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        });
      }
    });
  },

  get(resource, slug = '') {
    return CleanAxios.get(`${resource}/${slug}`);
  }
};

// 認證用的Axios
const CAPAuthorizateAxios = axios.create({
  baseURL: capApiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Solved Chrome Cros Problem
CAPAuthorizateAxios.defaults.headers.common = {};

export const CAPAuthorizateApi = {
  get(token) {
    return CAPAuthorizateAxios.get('/GetUserInfoByToken', {
      params: {
        authToken: token
      }
    });
  },
  getRoleList(token) {
    return CAPAuthorizateAxios.get('/GetRoleList', {
      params: {
        authToken: token,
        company: capCompany,
        sysId: capSysId
      }
    });
  }
};
export default ApiService;

export const EmpDataAllApiService = {
  url: 'api/EmpData',
  searchList(params) {
    return ApiService.query(`${this.url}/EmpOptionList`, params);
  }
};

export const CodeMappingApiService = {
  url: 'api/CodeMapping',
  get(codeTypeName) {
    return offInterceptorsApiService.get(this.url, codeTypeName);
  }
};

export const RoleUserApiService = {
  url: 'api/RoleUser',
  get(userId) {
    return offInterceptorsApiService.get(this.url, userId);
  }
};

export const FileService = {
  url: 'api/File',
  config: {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  upload(params) {
    return ApiService.postFile(`${this.url}`, params, this.config);
  },
  download(params) {
    return ApiService.downloadFile(`${this.url}`, params);
  },
  getFileList(params) {
    return offInterceptorsApiService.query(`${this.url}/FileList`, params);
  },
  updateBizId(params) {
    return ApiService.post(`${this.url}/UpdateBizId`, params);
  },
  deleteFile(params) {
    return ApiService.deleteByParams(`${this.url}`, params);
  }
};

export const RawDataApiService = {
  url: 'api/RawData',
  getCategoryList(params) {
    return offInterceptorsApiService.query(`${this.url}/CategoryList`, params);
  }
};

export const ModelCardApiService = {
  url: 'api/ModelCard',
  getModelCardFile(params) {
    return ApiService.downloadFile(`${this.url}/ModelCardFile`, params);
  },
  searchPfColOpts(params) {
    return offInterceptorsApiService.query(`${this.url}/searchPfColOpts`, params);
  },
  uploadFittingData(params) {
    const cfg = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return ApiService.postFile(`${this.url}/uploadFittingData`, params, cfg);
  },
  // new
  createModelCard1(params) {
    return ApiService.post(`${this.url}/createModelCard1`, params);
  },
  getModelCardList1(params) {
    return ApiService.query(`${this.url}/getModelCardList1`, params);
  },
  getModelCardDetail1(params) {
    return ApiService.query(`${this.url}/getModelCardDetail1`, params);
  },
  updateModelCard1(params) {
    return ApiService.put(`${this.url}/updateModelCard1`, params);
  },
  getMCFileList1(params) {
    return offInterceptorsApiService.query(`${this.url}/getModelCardFileList1`, params);
  },
  uploadMeasureData(params) {
    const cfg = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return ApiService.postFile(`${this.url}/UploadMeasureData`, params, cfg);
  },
  uploadOpticalData(params) {
    const cfg = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return ApiService.postFile(`${this.url}/UploadOpticalData`, params, cfg);
  }
};

export const AccessReportApiService = {
  url: 'api/AccessReport',
  getAccessInfo(params) {
    return ApiService.query(`${this.url}`, params);
  }
};

export const CategoryConfigApiService = {
  url: 'api/CategoryConfig',
  getTree() {
    return ApiService.get(`${this.url}/Tree`);
  },
  updateCategory(params) {
    return ApiService.put(`${this.url}`, params);
  },
  deleteCategory(params) {
    return ApiService.deleteByParams(`${this.url}`, params);
  },
  addCategory(params) {
    return ApiService.post(`${this.url}`, params);
  },
  runF2Command(params) {
    return ApiService.post(`${this.url}/setF2log`, params);
  }
};

export const AuthorityApiService = {
  url: 'api/Authority',
  getAuthority(params) {
    return ApiService.query(`${this.url}`, params);
  }
};

const RDAuthorityAxios = axios.create({
  baseURL: RDAUTHORITY_SERVICE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const RDAuthorityApi = {
  getRoleList(empNo) {
    return RDAuthorityAxios.get('/RoleList', {
      params: {
        systemId: RDAUTHORITY_SYSTEM_ID,
        empNo
      }
    });
  }
};

export const PanelCalcApiService = {
  url: 'api/PanelCalc',
  getPanelSize(params) {
    return offInterceptorsApiService.query(`${this.url}/PanelSize`, params);
  },
  getAspectRatio(params) {
    return offInterceptorsApiService.query(`${this.url}/AspectRatio`, params);
  },
  getPixelPerInch(params) {
    return offInterceptorsApiService.query(`${this.url}/PixelPerInch`, params);
  },
  checkValidModelVer(params) {
    return ApiService.query(`${this.url}/CheckValidModelVer`, params);
  },
  getLedDataList(params) {
    return ApiService.query(`${this.url}/LedDataList`, params);
  },
  getLedVoltageList(params) {
    return ApiService.query(`${this.url}/LedVoltageList`, params);
  },
  getOpticalDataList(params) {
    return ApiService.query(`${this.url}/OpticalDataList`, params);
  },
  getColorFilterSpectrum(params) {
    return ApiService.query(`${this.url}/ColorFilterSpectrum`, params);
  },
  getLedSpectrum(params) {
    return ApiService.query(`${this.url}/LedSpectrum`, params);
  },
  getLedWithColorFilterSpectrum(params) {
    return ApiService.query(`${this.url}/LedWithColorFilterSpectrum`, params);
  },
  calculate(params) {
    return ApiService.post(`${this.url}/Calculate`, params);
  },
  getPanelCalcDataList(params) {
    return ApiService.query(`${this.url}/PanelCalcDataList`, params);
  },
  getPanelCalcData(params) {
    return ApiService.query(`${this.url}/PanelCalcData`, params);
  },
  deletePanelCalcData(params) {
    return ApiService.deleteByParams(`${this.url}`, params);
  },
  release(params) {
    return ApiService.post(`${this.url}/Release`, params);
  }
};

export const MeasurementNFittingService = {
  url: 'api/MeasurementNFitting',
  getFittingData(params) {
    return offInterceptorsApiService.query(`${this.url}/FittingData`, params);
  },
  getComparisonInfo(params) {
    return offInterceptorsApiService.query(`${this.url}/ComparisonInfo`, params);
  }
};

// 認證用的Axios
const AuoGuideAxios = axios.create({
  baseURL: guideBaseURL,
  headers: {
    // 'Content-Type': 'application/json' // blocked by preflight response
  }
});

// Solved Chrome Cros Problem
AuoGuideAxios.defaults.headers.common = {};

// eslint-disable-next-line no-unused-vars
const offLoadingService = {
  query(resource, params) {
    return AuoGuideAxios.get(resource, {
      params,
      // 參數可傳Array
      paramsSerializer() {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        });
      }
    });
  },
  getQs(resource, qStr) {
    return AuoGuideAxios.get(`${resource}?${String(qStr)}`);
  },
  get(resource, slug = '') {
    return AuoGuideAxios.get(`${resource}/${slug}`);
  }
};

export const GuideRoomService = {
  url: 'api/v1/smartauo',
  getDayDetail(qStr) {
    return offLoadingService.getQs(`${this.url}/custDetail`, qStr);
  }
};
