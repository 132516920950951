<template>
  <div>
    <div class="fixed-top text-center">
      <span class="fl-noyet px-1 float-right small">{{ cond.updatedTime }}</span>
      <span class="px-1 small">
        <a class="badge badge-info" href="#"
          v-on:click.prevent="go2Camera1()">外場域 LiveCam</a>
      </span>
      <span class="px-1 small">
        <a class="badge badge-info" href="#"
          v-on:click.prevent="go2Camera2()">內場域 LiveCam</a>
      </span>
    </div>
    <div class="card" style="margin-bottom: .5rem !important;">
      <div class="card-body p-3">
        <div class="row">
          <div class="col mx-auto myScrollbar">
            <canvas id="pixiMap" ref="cMap1" class="myfont">
            </canvas>
            <!-- <img src="../../../assets/img/map0306_1x.jpg" v-show="false" ref="imgMap"> -->
            <!-- <img src="../../../assets/img/map0309_1x.jpg" v-show="false" ref="imgMap"> -->
            <img src="../../../assets/img/map0313_1x.jpg" v-show="false" ref="imgMap">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover">
              <thead>
                <tr class="text-center">
                  <th rowspan="2" class="align-middle">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button"
                        data-toggle="dropdown" aria-expanded="false">
                        場次: {{ cond.qdate }}
                      </button>
                      <div class="dropdown-menu">
                        <a v-for="(dd, index) in cond.guideDayList" :key="index"
                          class="dropdown-item my-1 py-0 w-75" href="#"
                          v-on:click.prevent="search(dd)">{{ dd }}</a>
                      </div>
                    </div>
                  </th>
                  <th rowspan="2" class="align-middle">預計導覽時間</th>
                  <th rowspan="2" class="align-middle">狀態</th>
                  <th class="align-middle py-1" style="min-width: 5em;">預計導覽站點</th>
                  <th rowspan="2" class="align-middle">導覽員</th>
                </tr>
                <tr class="align-middle text-center">
                  <th class="align-middle py-1">實際導覽站點</th>
                </tr>
              </thead>
              <tbody>
                <tr v-show="!dataList || dataList.length === 0">
                  <td colspan="5" class="text-center font-weight-bold"
                    style="padding: 8rem;">
                    查無 {{ cond.qdate }} 場次資料
                  </td>
                </tr>
                <tr v-for="(item, index) in dataList" :key="index" class="text-center">
                  <td class="align-middle" :title="item.companies3">
                    {{ item.no }}<br />
                    {{ item.companies2 }}<br />
                    <span v-show="item.vip" class="badge badge-info">vip</span>&nbsp;
                    {{ item.checkin_status | appStr(' 人') }}
                  </td>
                  <td class="align-middle">
                    <!-- <div class="mb-5">{{ item.useTime1 }}</div>
                    <div class="mt-5">
                      {{ item.useTime2 }}<br />
                      <span v-show="item.status1 === 1"
                        class="font-weight-bold small">
                        (~{{ item.expectTime | formatTimeHM }})
                      </span>
                    </div> -->
                    <div class="">
                      <span class="">{{ item.guide_time }}</span>
                    </div>
                  </td>
                  <td class="align-middle font-weight-bold">{{ item.status }}</td>
                  <td class="align-middle">
                    <div class="row small">
                      <label v-for="(ff, index1) in item.flow" :key="index1"
                        class="col border shadow-sm align-middle" :class="ff.css1">
                        <span class="font-weight-bold align-middle">{{ ff.name }}</span><br />
                        <span class="align-middle">({{ ff.staymi | appStr('分') }})</span>
                      </label>
                    </div>
                    <div class="row small">
                      <div v-for="(ff, index2) in item.flow" :key="index2"
                        class="col border shadow-sm fl-item" :class="ff.css2">
                        <label v-show="(ff.starttime)">
                          {{ ff.name }}<br />
                          ({{ ff.actualmi | appStr('分') }})<br />
                          <span class="font-weight-bold">{{ ff.starttime | formatTimeHM }}</span>
                        </label>
                        <label v-show="(!ff.starttime)" class="align-middle">
                          <span>
                            &nbsp;&nbsp;&nbsp;<br />
                          </span>
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle small">
                    <span v-html="item.docent"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WEB_IMG_URL } from '@/utils/config';
import { GuideRoomService } from '@/utils/apiService';
import moment from 'moment';
import * as PIXI from 'pixi.js';

const PXSpr = PIXI.Sprite;
const PXText = PIXI.Text; // BitmapText
const PXCont = PIXI.Container;
const PXPartCont = PIXI.ParticleContainer;

export default {
  data() {
    return {
      cond: {
        qdate: '',
        qstr: '',
        updatedTime: '',
        guideDayList: ['0320', '0321', '0322', '0323', '0324', '0325', '0326', '0327', '0328', '0329', '0330', '0331']
      },
      urlWebImg: WEB_IMG_URL,
      dataList: [],
      px: {
        app: null,
        styPoint: null,
        styText: null,
        contBg: null,
        contT1: null,
        rTicker: null
      },
      imgRate: {
        oriw: 2433,
        orih: 577,
        w2h: 0.237,
        h2w: 4.216,
        rate: 0,
        role: 0.28
      },
      mapLocaList: [
        {
          id: 11, name: 'R棟報到處', pox: 10, poy: 510, tex: 10, tey: 418
        }, {
          id: 12, name: '西大墩窯', pox: 10, poy: 392, tex: 10, tey: 222
        }, {
          id: 16, name: '高鐵接駁', pox: 10, poy: 392, tex: 10, tey: 20
        }, {
          id: 13, name: 'V棟大廳視覺牆', pox: 10, poy: 275, tex: 408, tey: 418
        }, {
        //   id: 14, name: 'V122接待室', pox: 10, poy: 170, tex: 408, tey: 222
        // }, {
        //   id: 15, name: 'V121接待室', pox: 10, poy: 40, tex: 408, tey: 20
        // }, {
          id: 17, name: '接待室', pox: 10, poy: 40, tex: 400, tey: 20
        }, {
          id: 21, name: 'A形象牆導覽', pox: 1160, poy: 370, tex: 1670, tey: 392
        }, {
          id: 22, name: 'B內場域主題介紹', pox: 740, poy: 355, tex: 1272, tey: 368
        }, {
          id: 23, name: 'C供應鏈全流程', pox: 500, poy: 55, tex: 1020, tey: 142
        }, {
          id: 24, name: 'D節能工程', pox: 1490, poy: 110, tex: 1809, tey: 151
        }, {
          id: 25, name: 'E瑕疵檢測解決方案', pox: 1670, poy: 155, tex: 2121, tey: 178
        }, {
          id: 26, name: 'F污泥減量', pox: 1726, poy: 450, tex: 2111, tey: 400
        }, {
          id: 27, name: 'G', pox: 420, poy: 260, tex: 952, tey: 296
        }, {
          id: 28, name: 'H', pox: 420, poy: 438, tex: 1000, tey: 475
        }
      ],
      mapCompList: [
        {
          no: 'A032305', companies2: '增你強,台..', id: 24, station: 'C展點', expectTime: '16:40'
        }, {
          no: 'A032306', companies2: '台塑', id: 25, station: 'V棟大廳', expectTime: '12:30'
        }, {
          no: 'A032307', companies2: '景碩,景碩..', id: 13, station: 'V棟大廳', expectTime: '11:40'
        }, {
          no: 'A032308', companies2: 'Darwi..', id: 28, station: 'V棟大廳', expectTime: '13:10'
        }, {
          no: 'A032309', companies2: 'Micros..', id: 21, station: 'V棟大廳', expectTime: '13:40'
        }, {
          no: 'A032301', companies2: '陽明交大', id: 26, station: 'V棟大廳', expectTime: '16:20'
        }, {
          no: 'A032302', companies2: '億光,健鼎..', id: 23, station: 'V棟大廳', expectTime: '17:00'
        }, {
          no: 'A032303', companies2: '高達能源,..', id: 22, station: 'V棟大廳', expectTime: '10:11'
        }, {
          no: 'A032304', companies2: '上銀科技', id: 27, station: 'V棟大廳', expectTime: '16:40'
        }
      ],
      timerID: null,
      refPeriod: 6868
    };
  },
  methods: {
    search(qdate) {
      const vm = this;
      vm.cond.updatedTime = `updated @ ${moment(new Date()).format('HH:mm:ss')}`;

      if (qdate) {
        vm.cond.qdate = qdate;
      }
      if (!vm.cond.qdate) {
        vm.cond.qdate = moment(new Date()).format('MMDD');
      }

      // vm.$bus.$emit('setLoading', true);
      console.log(`--- go refresh: ${vm.cond.qdate}`);
      vm.cond.qstr = `date=${vm.cond.qdate}`;
      GuideRoomService.getDayDetail(vm.cond.qstr).then((response) => {
        const res = response.data;
        if (res.success) {
          if (!res.data || res.data.length <= 0) {
            vm.dataList = [];
          }

          // table
          vm.dataList = res.data
            .map((item) => {
              const obj = item;
              if (obj) {
                if (obj.companies && obj.companies.length > 0) {
                  obj.companies2 = obj.companies.join(',');
                  obj.companies3 = obj.companies2;
                  if (obj.companies2 && obj.companies2.length > 5) {
                    obj.companies2 = `${obj.companies2.substr(0, 5)}..`;
                  }
                } else {
                  obj.companies2 = 'NA';
                }

                if (obj.docent.indexOf('、', 0) > 0) {
                  obj.docent = obj.docent.replace('、', '<br />');
                }
                obj.guide_time = obj.guide_time.replace('~', ' ~ ');

                obj.status1 = 5;
                if (obj.status === '導覽結束') {
                  obj.status1 = 9;
                } else if (obj.status === '導覽中') {
                  obj.status1 = 1;
                }

                obj.currStation = null;
                obj.lastTime = null; // updated time
                obj.useTime1 = 0; // expect time
                obj.useTime2 = 0; // actual time
                obj.restTime = 0; // rest time
                if (obj.flow && obj.flow.length > 0) {
                  obj.useTime1 = obj.flow.reduce(
                    (acc, currObj) => acc + currObj.staymi, 0
                  );

                  obj.flow.map((floItem) => {
                    const fof = floItem;
                    fof.css1 = '';
                    fof.css2 = 'fl-noyet';
                    fof.actualmi = 0;

                    if (fof.must) {
                      fof.css1 = 'text-danger'; // must
                    }
                    if (fof.starttime) {
                      // obj.lastTime = fof.starttime;
                      if (fof.endtime) {
                        fof.css2 = 'fl-pass text-white'; // pass station
                        fof.actualmi = moment(fof.endtime).diff(fof.starttime, 'minutes');
                        obj.useTime2 += fof.actualmi;
                        // console.log(`${fof.id} actualmi: ${fof.actualmi}`);
                      }
                    }
                    if (fof.id === obj.current_station) {
                      obj.currStation = fof.name;
                      obj.lastTime = moment(fof.starttime);
                      fof.css2 = 'fl-ontrip text-primary'; // current
                    }
                    if (fof.id >= obj.current_station && fof.staymi) {
                      obj.restTime += fof.staymi; // 預計剩餘時間
                    }
                    return fof;
                  });
                }

                if (obj.restTime < 0) {
                  obj.restTime = 0;
                } else {
                  obj.expectTime = moment(obj.lastTime)
                    .add(obj.restTime, 'minutes'); // 預期完成時間
                }
              }
              return obj;
            })
            .sort((a, b) => a.status1 - b.status1 || a.no > b.no);
          // console.log(vm.dataList);

          // map
          const irate = vm.imgRate.rate;
          vm.mapCompList = vm.dataList
            .filter((item1) => item1.currStation && item1.currStation.length > 1)
            .map((item2) => {
              const mmc = { id: 0 };
              const loca = vm.mapLocaList.find((obj) => item2.currStation.includes(obj.name));
              if (loca) {
                mmc.no = item2.no;
                mmc.companies2 = item2.companies2;
                mmc.station = item2.currStation;
                mmc.expectTime = moment(item2.expectTime).format('HH:mm');

                mmc.id = loca.id;
                mmc.pox = (loca.pox * irate).toFixed(1);
                mmc.poy = (loca.poy * irate).toFixed(1);
                mmc.tex = (loca.tex * irate).toFixed(1);
                mmc.tey = (loca.tey * irate).toFixed(1);
                // console.log(`loca-point x: ${String(loca.pox)} / y:${String(loca.poy)}`);
                // console.log(`mmc-point x: ${String(mmc.pox)} / y:${String(mmc.poy)}`);
                // console.log(`loca-text x: ${String(loca.tex)} / y:${String(loca.tey)}`);
                // console.log(`mmc-text x: ${String(mmc.tex)} / y:${String(mmc.tey)}`);
              } else {
                console.log(`no loca map: ${String(item2.no)} ${item2.currStation}`);
              }
              return mmc;
            })
            .filter((item3) => item3.id !== 0); // remove null object
          // console.log(vm.mapCompList);

          // draw map
          vm.pxDraw();
        }
        vm.$bus.$emit('setLoading', false);
      }).catch((error) => {
        vm.dataList = [];
        console.log(error);
        // vm.$bus.$emit('setLoading', false);
      });
    },
    pxDraw() { // pixi
      const vm = this;
      console.log(`--- map ontrip: ${String(vm.mapCompList.length)} items`);
      // const autiger = vm.$refs.tiger;
      // eslint-disable-next-line new-cap
      // vm.px.tiger = new PXSpr.from(String(autiger.src)); // avator
      // const roleScale = vm.imgRate.role * vm.imgRate.rate; // role

      // guide on-trip
      if (vm.px.contT1 && vm.px.contT1.parent) { // clean cont
        vm.px.contT1.parent.removeChild(vm.px.contT1);
        vm.px.contT1.destroy({ children: true, texture: true, baseTexture: true });
        vm.px.contT1 = null;
      }
      vm.px.contT1 = new PXCont(); // new

      // if (vm.px.rTicker) { // clean ticker
      //   vm.px.rTicker.stop();
      //   vm.px.rTicker.destroy();
      //   vm.px.rTicker = null;
      // }
      // vm.px.rTicker = new PIXI.Ticker(); // new

      const spStation = vm.mapLocaList.find((obj) => obj.id === 17).name; // fixed
      vm.mapLocaList.forEach((ttloca) => {
        // console.log(ttloca);
        let tmsg = ''; // name text
        const matchComp = vm.mapCompList.filter((obj) => obj.id === ttloca.id);
        matchComp.forEach((fcomp) => { // multi-name
          // console.log(fcomp);
          if (fcomp) {
            if (fcomp.station.includes(spStation)) {
              const tRoom = fcomp.station.substring(0, 4);
              tmsg += `●${fcomp.no}/${fcomp.companies2}(${tRoom})~${fcomp.expectTime} \r\n`;
            } else {
              tmsg += `●${fcomp.no}/${fcomp.companies2}~${fcomp.expectTime} \r\n`;
            }
          }
        });

        if (tmsg.length > 1) {
          console.log(`${ttloca.id}. ${ttloca.name}: ${tmsg}`);
          // // 取消 point
          // // eslint-disable-next-line new-cap
          // const ava = new PXSpr.from(String(autiger.src)); // avator
          // ava.scale.set(roleScale, roleScale);
          // ava.x = (Number(matchComp[0].pox)
          //   + Number(ava.width / 1.4)).toFixed(1);
          // ava.y = (Number(matchComp[0].poy)
          //   + Number(ava.height / 1.2)).toFixed(1);
          // ava.anchor.set(0.5);
          // vm.px.contT1.addChild(ava);
          // vm.px.rTicker.add(() => {
          //   ava.rotation += 0.032;
          // });
          // const tPoint = new PXText('●', vm.px.styPoint);
          // tPoint.x = matchComp[0].pox;
          // tPoint.y = matchComp[0].poy;
          // vm.px.contT1.addChild(tPoint);
          // text
          const tText = new PXText(tmsg, vm.px.styText);
          tText.x = matchComp[0].tex;
          tText.y = matchComp[0].tey;
          vm.px.contT1.addChild(tText);
        }
      });
      vm.px.app.stage.addChild(vm.px.contT1); // add container
      // vm.px.rTicker.start();
      // vm.px.rTicker.speed = 1;
    },
    pxLoc() {
      // const vm = this;
      // const irate = vm.imgRate.rate;

      // vm.mapLocaList.forEach((loca) => {
      //   const tlComp = vm.mapCompList.find((obj) => obj.id === loca.id);
      //   if (tlComp) {
      //     console.log(`${loca.id}. ${loca.name}: ${tlComp.companies2}`);
      //     tlComp.pox = (loca.pox * irate).toFixed(2);
      //     tlComp.poy = (loca.poy * irate).toFixed(2);
      //     tlComp.tex = (loca.tex * irate).toFixed(2);
      //     tlComp.tey = (loca.tey * irate).toFixed(2);
      //     // console.log(`tLoc-point x: ${String(loca.pox)} / y:${String(loca.poy)}`);
      //     // console.log(`tlComp-point x: ${String(tlComp.pox)} / y:${String(tlComp.poy)}`);
      //     // console.log(`tLoc-text x: ${String(loca.tex)} / y:${String(loca.tey)}`);
      //     // console.log(`tlComp-text x: ${String(tlComp.tex)} / y:${String(tlComp.tey)}`);
      //   }
      // });
    },
    pxInit() { // pixi
      const vm = this;

      const canvE = document.getElementById('pixiMap');
      const maxw = (window.innerWidth - 82).toFixed(0); // padding 20*2 + 16*2
      const maxh = (window.innerHeight * 0.48).toFixed(0); // screen 1/2
      console.log(`max screen = ${String(maxw)} x ${String(maxh)}`);

      // 長寬比: 設定為長占滿 screen 1/2
      let bgh = (Number(maxh) - 2);
      let bgw = (bgh * vm.imgRate.h2w).toFixed(0);
      console.log(`-> fit by height = ${String(bgw)} x ${String(bgh)}`);
      // 長寬比: 設定為寬占滿
      if (bgw > maxw) {
        bgw = (Number(maxw) - 2);
        bgh = (bgw * vm.imgRate.w2h).toFixed(0);
        console.log(`-> fit by width = ${String(bgw)} x ${String(bgh)}`);
      }

      vm.px.app = new PIXI.Application({
        width: bgw,
        height: bgh,
        antialias: true,
        transparent: true,
        view: canvE
        // forceCanvas: true
      });
      vm.px.app.renderer.autoResize = true;
      console.log(`px.width = ${String(vm.px.app.screen.width)}`);
      console.log(`px.height = ${String(vm.px.app.screen.height)}`);

      // bg
      const img1 = vm.$refs.imgMap;
      const imgSrc4 = String(img1.src);
      console.log(img1);
      // eslint-disable-next-line new-cap
      const bg0 = new PXSpr.from(imgSrc4);
      // bg0.height = (vm.px.app.screen.height - 5);
      // bg0.width = (bg0.height * vm.imgRate.h2w).toFixed(3);
      bg0.height = bgh;
      bg0.width = bgw;
      vm.imgRate.rate = (bg0.width / vm.imgRate.oriw).toFixed(3); // rate
      console.log(`ori_img = ${String(vm.imgRate.oriw)} x ${String(vm.imgRate.orih)}`);
      console.log(`new_img = ${String(bg0.width)} x ${String(bg0.height)}`);
      console.log(`rate: ${String(vm.imgRate.rate)}`);

      vm.px.contBg = new PXPartCont();
      vm.px.contBg.interactive = false; // 互動否
      vm.px.contBg.buttonMode = false; // pointer: cursor
      vm.px.contBg.x = 0; // container x
      vm.px.contBg.y = 0; // container y
      vm.px.contBg.addChild(bg0);
      vm.px.app.stage.addChild(vm.px.contBg);

      // 文字樣式
      vm.px.styPoint = new PIXI.TextStyle({
        fontSize: 18,
        fill: 'yellow',
        stroke: '#ff3300',
        strokeThickness: 4,
        dropShadow: true,
        dropShadowDistance: 3,
        dropShadowAlpha: 0.7,
        dropShadowColor: '#808080'
      });
      vm.px.styText = new PIXI.TextStyle({
        fontName: 'Lato',
        fontSize: 13,
        fill: 'red'
        // stroke: '#FFFCCC', // yellow FFFCCC
        // strokeThickness: 2
        // wordWrap: true, // 是否折行
        // wordWrapWidth: 250 // 多少寬度折行
        // lineJoin: 'round' // 文字轉折處樣式為圓角
      });

      // map location ---> pass
      vm.pxLoc();
    },
    drawCanvas() { // canvas
      const vm = this;

      const ctx = vm.$refs.cMap1.getContext('2d'); // canvas
      const img1 = vm.$refs.imgMap;

      // image
      // const imgSrc = '../../../assets/img/map_origin.png';
      const imgSrc = `${vm.urlWebImg}map_origin.png`;
      console.log(imgSrc);

      const imaMap = new Image();
      imaMap.src = img1.src;
      console.log(imaMap.src);
      ctx.drawImage(imaMap, 0, 0, 800, 600);

      // 點
      ctx.font = '20px Arial'; // 設定文字字型 大小
      ctx.fillText('o', 10, 100); // 填滿文字
      // ctx.beginPath();
      // ctx.rect(2, 2, 200, 100);
      // ctx.stroke();
    },
    go2Camera1() {
      // const vm = this;
      // const routeNew = vm.$router.resolve({ name: 'LiveCamera' });
      const urlc = 'http://10.96.15.121:1931/ui/live_view_cell';
      window.open(urlc, '_blank');
    },
    go2Camera2() {
      const urlc = 'http://10.96.10.159:1901/ui/live_view_cell';
      window.open(urlc, '_blank');
    }
  },
  mounted() {
    const vm = this;
    // vm.$bus.$emit('setLoading', true);
    const qs = vm.$route.query;
    console.log(qs);
    if (qs && qs.period && parseInt(qs.period, 10) > 0) {
      vm.refPeriod = Number(qs.period) * 1000; // second
    }

    vm.pxInit();
    // vm.drawCanvas();

    vm.cond.qdate = moment(new Date()).format('MMDD');
    vm.search(vm.cond.qdate);

    // set timer
    vm.timerID = window.setInterval(() => {
      vm.search();
    }, vm.refPeriod);
  },
  beforeDestroy() {
    const vm = this;
    window.clearInterval(vm.timerID); // clear timer
    vm.timerID = null;
  }
};
</script>

<style scoped>
  .fl-pass {
    background-color: #64748b;
  }
  .fl-ontrip {
    background-color: #ffc864;
  }
  .fl-noyet {
    background-color: #ddd6ef;
  }
  .fl-item:hover {
    /* box-shadow: 0px 0.5rem 0.5rem rgba(0,0,0,0.15) !important; */
    top: -0.2rem !important;
    margin: 0 0.2rem 0.2rem 0.2rem;
    color: #005087;
  }
  .myfont {
    font-family: 'Lato', 'Source Sans Pro', 'Helvetica Neue', 'Helvetica,Arial',
      'sans-serif', 'Microsoft JhengHei', 'Microsoft YaHei';
  }
  .myScrollbar {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch
  }
    ::-webkit-scrollbar-track {
      border-radius: 0.1rem;
      background-color: lightgray;
    }
    ::-webkit-scrollbar {
      width: 0.68rem;
      border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0.2rem;
      background-color: gray;
    }
</style>
