<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
import Layout from './views/Layout.vue';

export default {
  name: 'App',
  components: {
    Layout
  }
};
</script>
<style>
  div:not(.btn-group) > button ,button ,a {
    margin-right: .25em;
    margin-left: .25em;
  }
</style>
