<template>
  <div class="col-12" v-show="totalRecords > 0">
    <div class="fa-pull-right">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">每頁顯示筆數</span>
        </div>
        <select class="form-control" v-model.number="pageSize">
          <option v-for="(item,index) in pagesSize" :key="index" :value="item">{{item}}</option>
          <!-- <option value="1000000" v-if="pagesSizeAllShowed">ALL</option> -->
        </select>
      </div>
    </div>
    <p class="fa-pull-left m-2">{{ startIndex + 1 }} - {{ endIndex }} : total {{ totalRecords }}</p>
    <b-pagination
      v-model="page"
      :total-rows="totalRecords"
      :per-page="pageSize"
      @change="pageChange"
      class="col-8"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  data() {
    return {
      pageSize: this.defaultPageSize,
      page: 1
    };
  },
  watch: {
    pageSize(val) {
      this.page = 1;
      this.$emit('pageChangeEvent', this.page, val);
    },
    totalRecords() {
      this.page = 1;
    }
  },
  props: {
    totalRecords: {
      type: Number,
      required: true
    },
    pagesSize: {
      type: Array,
      default() {
        return [5, 10, 20, 50];
      }
    },
    pagesSizeAllShowed: {
      type: Boolean,
      default() {
        return true;
      }
    },
    defaultPageSize: {
      type: Number,
      default() {
        return 10;
      }
    }
  },
  methods: {
    pageChange(page) {
      this.page = page;
      this.$emit('pageChangeEvent', this.page, this.pageSize);
    },
    resetPage() {
      this.page = 1;
    }
  },
  computed: {
    startIndex() {
      return (this.page - 1) * this.pageSize;
    },
    endIndex() {
      const end = this.page * this.pageSize;
      return end > this.totalRecords ? this.totalRecords : end;
    }
  }
};
</script>
