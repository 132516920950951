<template>
  <div>
    <table
      v-if="dataList && dataList[0].vd"
      class="table table-bordered table-condensed table-responsive-sm"
    >
      <thead>
        <tr class="bg-secondary">
          <th>GOA</th>
          <th>IdVg / IdVd</th>
          <th>Ion / Ioff</th>
          <th colspan="2">Vd / Vg</th>
          <th>Measure</th>
          <th>Fitting</th>
          <th>(Fitting-Measure)/Measure %</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="dataList[0].vd">
          <td rowspan="6">W10000</td>
          <td rowspan="2">IdVg</td>
          <td rowspan="2">Ioff</td>
          <td>Vd(V)</td>
          <td>{{ dataList[0].vd }}</td>
          <td rowspan="2">
            {{
              dataList[0].measure != null
                ? dataList[0].measure
                : null
            }}
          </td>
          <td rowspan="2">
            {{
              dataList[0].fitting != null
                ? dataList[0].fitting
                : null
            }}
          </td>
          <td rowspan="2">{{ dataList[0].rate | percentage(2) }}</td>
        </tr>
        <tr v-if="dataList[0].vd">
          <td>Vg(V)</td>
          <td>{{ dataList[0].vg }}</td>
        </tr>
        <tr v-if="dataList[1].vd">
          <td rowspan="4">IdVd</td>
          <td rowspan="2">Ion</td>
          <td>Vd(V)</td>
          <td>{{ dataList[1].vd }}</td>
          <td rowspan="2">
            {{
              dataList[1].measure != null
                ? dataList[1].measure
                : null
            }}
          </td>
          <td rowspan="2">
            {{
              dataList[1].fitting != null
                ? dataList[1].fitting
                : null
            }}
          </td>
          <td rowspan="2">{{ dataList[1].rate | percentage(2) }}</td>
        </tr>
        <tr v-if="dataList[1].vd">
          <td>Vg(V)</td>
          <td>{{ dataList[1].vg }}</td>
        </tr>
        <tr v-if="dataList[2].vd">
          <td rowspan="2">Ion</td>
          <td>Vd(V)</td>
          <td>{{ dataList[2].vd }}</td>
          <td rowspan="2">
            {{
              dataList[2].measure != null
                ? dataList[2].measure
                : null
            }}
          </td>
          <td rowspan="2">
            {{
              dataList[2].fitting != null
                ? dataList[2].fitting
                : null
            }}
          </td>
          <td rowspan="2">{{ dataList[2].rate | percentage(2) }}</td>
        </tr>
        <tr v-if="dataList[2].vd">
          <td>Vg(V)</td>
          <td>{{ dataList[2].vg }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    mcId: { type: Number, default: 0 },
    sheetPrefix: { type: String, default: '' },
    dataList: { type: Array, default: null }
  }
};
</script>

<style scoped>
.table thead tr th {
  background-color: #6c757d;
  color: white !important;
  text-align: center;
}
.table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid gray;
}
</style>
