import _ from 'lodash';
// import { AuthorityApiService } from '../utils/apiService';

const getters = {
  getUserInfo: (state) => state.userInfo,
  getUserRole: (state) => state.userRole,
  getRoleIdList: (state) => _.map(state.userRole, 'roleId'),
  isITAdmin: (state) => state.isITAdmin,
  isRDAdmin: (state) => state.isRDAdmin,
  isRDArc: (state) => state.isRDArc,
  isRDPlatform: (state) => state.isRDPlatform,
  getRdUnit: (state) => state.rdGenUnit,
  getUploadPermission: (state) => state.uploadPermission,
  getDownloadPermission: (state) => state.downloadPermission
};

const state = {
  userInfo: {
    // userId: 'O000641',
    cname: 'Demo User !!'
  },
  userRole: [{ roleId: 8, roleName: 'ITAdmin' }],
  isITAdmin: 'N',
  isRDAdmin: 'N',
  isRDArc: 'N',
  isRDPlatform: 'N',
  rdGenUnit: 0,
  uploadPermission: 'N',
  downloadPermission: 'Y'
};

const mutationsType = {
  USER_INFO: 'USER_INFO',
  USER_ROLE: 'USER_ROLE',
  IS_ITADMIN: 'IS_ITADMIN',
  IS_RDADMIN: 'IS_RDADMIN',
  IS_RDARC: 'IS_RDARC',
  IS_RDPLATFORM: 'IS_RDPLATFORM',
  RD_GEN_UNIT: 'RD_GEN_UNIT',
  UPLOAD_PERMISSION: 'UPLOAD_PERMISSION',
  DOWNLOAD_PERMISSION: 'DOWNLOAD_PERMISSION'
};

const actions = {
  setUserInfo({ commit }, payload) {
    commit(mutationsType.USER_INFO, payload);
  },
  setUserRole({ commit }, payload) {
    commit(mutationsType.USER_ROLE, payload);

    commit(mutationsType.IS_ITADMIN, 'Y');
    commit(mutationsType.IS_RDADMIN, 'Y');
    commit(mutationsType.IS_RDARC, 'Y');
    commit(mutationsType.IS_RDPLATFORM, 'Y');
    commit(mutationsType.UPLOAD_PERMISSION, 'Y');
    commit(mutationsType.DOWNLOAD_PERMISSION, 'Y');

    // const params = {
    //   roleIdList: _.map(state.userRole, 'roleId')
    // };

    // AuthorityApiService.getAuthority(params).then((response) => {
    //   const res = response.data; // 取資料會延遲
    //   if (res.success) {
    //     const authority = res.data;
    //     commit(mutationsType.IS_ITADMIN, authority.isITAdmin ? 'Y' : 'N');
    //     commit(mutationsType.IS_RDADMIN, authority.isRDAdmin ? 'Y' : 'N');
    //     commit(mutationsType.IS_RDARC, authority.isRDArc ? 'Y' : 'N');
    //     commit(mutationsType.IS_RDPLATFORM, authority.isRDPlatform ? 'Y' : 'N');
    //     commit(mutationsType.UPLOAD_PERMISSION, authority.hasUploadPermission ? 'Y' : 'N');
    //     commit(mutationsType.DOWNLOAD_PERMISSION, authority.hasDownloadPermission ? 'Y' : 'N');
    //     // console.log('state isRDArc ', state.isRDArc);
    //     // console.log(state.isRDPlatform);
    //   }
    // });
  },
  setRdUnit({ commit }, payload) {
    commit(mutationsType.RD_GEN_UNIT, payload);
  },
  setLogout({ commit }) {
    commit(mutationsType.USER_INFO, null);
    commit(mutationsType.USER_ROLE, null);
  }
};

const mutations = {
  [mutationsType.USER_INFO](state, payload) {
    let userInfo = null;
    if (payload) {
      userInfo = {
        userId: payload.EmployeeId,
        cname: payload.RealName,
        originData: payload
      };
    }
    state.userInfo = userInfo;
  },
  [mutationsType.USER_ROLE](state, payload) {
    state.userRole = payload || [];
  },
  [mutationsType.IS_ITADMIN](state, payload) {
    state.isITAdmin = payload;
  },
  [mutationsType.IS_RDADMIN](state, payload) {
    state.isRDAdmin = payload;
  },
  [mutationsType.IS_RDARC](state, payload) {
    state.isRDArc = payload;
  },
  [mutationsType.IS_RDPLATFORM](state, payload) {
    state.isRDPlatform = payload;
  },
  [mutationsType.RD_GEN_UNIT](state, payload) {
    state.rdGenUnit = payload;
  },
  [mutationsType.UPLOAD_PERMISSION](state, payload) {
    state.uploadPermission = payload;
  },
  [mutationsType.DOWNLOAD_PERMISSION](state, payload) {
    state.downloadPermission = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
