import CategoryConfig from '../views/Config/CategoryConfig/CategoryConfig.vue';

export default
[
  {
    name: 'CategoryConfig',
    path: '/Config/CategoryConfig',
    component: CategoryConfig,
    meta: {
      title: '類別設定',
      requiresAuth: true
    }
  }
];
