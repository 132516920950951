<template>
  <div>
    <div class="row">
      <table class="table bg-light py-1" v-show="isReadonly">
        <tr class="text-center"><td class="h4">尺寸資訊</td></tr>
      </table>
      <div class="col-12 table-responsive p-4 mb-0">
        <form>
          <div class="form-group row" v-show="!isReadonly">
            <label for="modelName" class="col-sm-4 col-form-label text-center"
              >ModelName <span class="required">*</span></label
            >
            <div class="col-sm-8 form-inline">
              <validation-provider
                name="ModelName"
                v-slot="{ failed, failedRules }"
                :rules="{ required: true, length: 9 }"
              >
                  <input
                    id="modelName"
                    type="text"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    placeholder="Name"
                    v-model="formData.modelName"
                    :disabled="isReadonly"
                  />
                    <span v-if="failedRules['length']" class="mx-2 text-danger">{{
                      failedRules["length"]
                    }}</span>
              </validation-provider>
              <validation-provider
                v-slot="{ failed }"
                :rules="{ required: true, number: true }"
              >
                <input
                  id="version"
                  type="number"
                  :class="{ 'form-control': true, 'is-invalid': failed }"
                  style="width: 100px"
                  placeholder="Version"
                  v-model="formData.version"
                  :disabled="isReadonly"
                />
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <label for="versionDesc" class="col-sm-4 col-form-label text-center"
              >Version Description
            </label>
            <div class="col-sm-8">
              <textarea
                id="versionDesc"
                type="text"
                class="form-control"
                v-model="formData.versionDesc"
                :disabled="isReadonly"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="pixelSize" class="col-sm-4 col-form-label text-center"
              >Pixel Size <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="pixelSize"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 230px; max-width: 230px"
                    v-model="formData.pixelSize"
                    :disabled="isReadonly"
                  />
                </validation-provider>
                <label class="input-group-text">um</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="vPixelNumber"
              class="col-sm-4 col-form-label text-center"
              >Vertical Pixel Number <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <validation-provider
                v-slot="{ failed }"
                :rules="{ required: true, number: true }"
              >
                <input
                  id="vPixelNumber"
                  type="number"
                  :class="{ 'form-control': true, 'is-invalid': failed }"
                  style="width: 230px; max-width: 230px"
                  v-model="formData.vPixelNumber"
                  :disabled="isReadonly"
                />
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="hPixelNumber"
              class="col-sm-4 col-form-label text-center"
              >Horizontal Pixel Number <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <validation-provider
                v-slot="{ failed }"
                :rules="{ required: true, number: true }"
              >
                <input
                  id="hPixelNumber"
                  type="number"
                  :class="{ 'form-control': true, 'is-invalid': failed }"
                  style="width: 230px; max-width: 230px"
                  v-model="formData.hPixelNumber"
                  :disabled="isReadonly"
                />
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="panelShape"
              class="col-sm-4 col-form-label text-center"
              >Panel Shape <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <validation-provider
                v-slot="{ failed }"
                :rules="{ required: true }"
              >
                <div class="form-check form-check-inline mx-2">
                  <input
                    type="radio" id="panelShapeS" name="inRadioPanelShape"
                    value="1" v-model="formData.panelShape"
                    :disabled="isReadonly"
                    :class="{ 'form-check-input': true, 'is-invalid': failed }"
                  />
                  <label for="panelShapeS" class="form-check-label">方形</label>
                </div>
                <div class="form-check form-check-inline mx-2">
                  <input
                    type="radio" id="panelShapeC" name="inRadioPanelShape"
                    value="2" v-model="formData.panelShape"
                    :disabled="isReadonly || (formData.vPixelNumber != formData.hPixelNumber)"
                    :class="{ 'form-check-input': true, 'is-invalid': failed }"
                  />
                  <label for="panelShapeC" class="form-check-label">圓形</label>
                </div>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <label for="panelSize" class="col-sm-4 col-form-label text-center"
              >Panel Size
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <input
                  id="panelSize"
                  type="number" step="0.01"
                  class="form-control"
                  style="width: 230px; max-width: 230px"
                  v-model="formData.panelSize"
                  readonly
                />
                <label class="input-group-text">inch</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="aspectRatio" class="col-sm-4 col-form-label text-center"
              >Aspect Ratio
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <input
                  id="aspectRatio"
                  type="text"
                  class="form-control"
                  style="width: 230px; max-width: 230px"
                  v-model="formData.aspectRatio"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label
              for="pixelPerInch"
              class="col-sm-4 col-form-label text-center"
              >Pixel per Inch
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <input
                  id="pixelPerInch"
                  type="number"
                  class="form-control"
                  style="width: 230px; max-width: 230px"
                  v-model="formData.pixelPerInch"
                  readonly
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { PanelCalcApiService } from '@/utils/apiService';

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    isReadonly: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    'formData.pixelSize': function (val) {
      const vm = this;
      if (vm.isReadonly) {
        return;
      }

      vm.getPanelSize(val, vm.formData.vPixelNumber, vm.formData.hPixelNumber,
        vm.formData.panelShape);
      vm.getPixelPerInch(val);
    },
    'formData.vPixelNumber': function (val) {
      const vm = this;
      if (vm.isReadonly) {
        return;
      }

      vm.getPanelSize(vm.formData.pixelSize, val, vm.formData.hPixelNumber,
        vm.formData.panelShape);
      vm.getAspectRatio(val, vm.formData.hPixelNumber);
    },
    'formData.hPixelNumber': function (val) {
      const vm = this;
      if (vm.isReadonly) {
        return;
      }

      vm.getPanelSize(vm.formData.pixelSize, vm.formData.vPixelNumber, val,
        vm.formData.panelShape);
      vm.getAspectRatio(vm.formData.vPixelNumber, val);
    },
    'formData.panelShape': function (val) {
      const vm = this;
      if (vm.isReadonly) {
        return;
      }

      vm.getPanelSize(vm.formData.pixelSize, vm.formData.vPixelNumber,
        vm.formData.hPixelNumber, val);
    }
  },
  methods: {
    getPanelSize(pixelSize, vPixelNumber, hPixelNumber, panelShape) {
      const vm = this;

      if (!pixelSize || !vPixelNumber || !hPixelNumber) {
        return;
      }

      setTimeout(() => {
        const params = {
          pixelSize,
          vPixelNumber,
          hPixelNumber,
          panelShape
        };
        PanelCalcApiService.getPanelSize(params).then((response) => {
          const result = response.data;
          vm.formData.panelSize = result.data;
          // console.log(`${vm.formData.panelSize} / ${result.data}`);
        });
      }, 500);
    },
    getAspectRatio(vPixelNumber, hPixelNumber) {
      const vm = this;
      if (!vPixelNumber || !hPixelNumber) {
        return;
      }

      if (vPixelNumber !== hPixelNumber) {
        vm.formData.panelShape = '1';
      }

      setTimeout(() => {
        const params = {
          vPixelNumber,
          hPixelNumber
        };
        PanelCalcApiService.getAspectRatio(params).then((response) => {
          const result = response.data;
          vm.formData.aspectRatio = result.data;
        });
      }, 500);
    },
    getPixelPerInch(pixelSize) {
      const vm = this;
      if (!pixelSize) {
        return;
      }

      setTimeout(() => {
        const params = {
          pixelSize
        };
        PanelCalcApiService.getPixelPerInch(params).then((response) => {
          const result = response.data;
          vm.formData.pixelPerInch = result.data;
        });
      }, 500);
    }
  }
};
</script>
