<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="chart-block my-2 p-2">
          <scatter-chart
            :chartData="getChartData('R')"
            :chartOptions="getChartOptions('R')"
            css-classes="chart-block"
          ></scatter-chart>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="chart-block my-2 p-2">
          <scatter-chart
            :chartData="getChartData('G')"
            :chartOptions="getChartOptions('G')"
            css-classes="chart-block"
          ></scatter-chart>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="chart-block my-2 p-2">
          <scatter-chart
            :chartData="getChartData('B')"
            :chartOptions="getChartOptions('B')"
            css-classes="chart-block"
          ></scatter-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScatterChart from '@/components/Chart/ScatterChart.vue';
import _ from 'lodash';

export default {
  name: 'CurrentYieldChart',
  components: {
    ScatterChart
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    chartType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    chartData: {
      get() {
        return this.value;
      }
    }
  },
  methods: {
    getChartData(color) {
      const vm = this;
      let pointColor = '';
      let dataList = [];
      const pointColorCY = '#FFE000';
      let dataListCY = [];

      switch (color) {
        case 'R':
          pointColor = '#C0504D';
          dataList = _.map(vm.chartData.currentYieldR, (obj) => ({
            x: obj.current,
            y: obj.yield
          }));
          if (vm.chartData.currentYieldR1) {
            dataListCY = _.map(vm.chartData.currentYieldR1, (obj) => ({
              x: obj.current,
              y: obj.yield
            }));
          }
          break;
        case 'G':
          pointColor = '#9BBB59';
          dataList = _.map(vm.chartData.currentYieldG, (obj) => ({
            x: obj.current,
            y: obj.yield
          }));
          if (vm.chartData.currentYieldG1) {
            dataListCY = _.map(vm.chartData.currentYieldG1, (obj) => ({
              x: obj.current,
              y: obj.yield
            }));
          }
          break;
        case 'B':
          pointColor = '#4F81BD';
          dataList = _.map(vm.chartData.currentYieldB, (obj) => ({
            x: obj.current,
            y: obj.yield
          }));
          if (vm.chartData.currentYieldB1) {
            dataListCY = _.map(vm.chartData.currentYieldB1, (obj) => ({
              x: obj.current,
              y: obj.yield
            }));
          }
          break;
      }

      const chartData = {
        datasets: [
          {
            label: '',
            data: dataList,
            fill: false,
            tension: 0.4,
            backgroundColor: pointColor,
            borderColor: pointColor,
            borderWidth: 3,
            pointStyle: 'circle',
            pointRadius: 3,
            spanGaps: true,
            showLine: true,
            order: 2
          },
          {
            label: '',
            data: dataListCY,
            fill: true,
            tension: 0.4,
            backgroundColor: pointColor,
            borderColor: pointColorCY,
            borderWidth: 6,
            pointStyle: 'triangle',
            pointRadius: 6,
            spanGaps: true,
            showLine: true,
            order: 1
          }
        ]
      };

      return chartData;
    },
    getChartOptions(color) {
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        elements: {
          line: {
            fill: false
          },
          point: {
            radius: 0
          }
        },
        title: {
          display: true,
          text: `Current vs Yield (${color})`,
          fontSize: 20,
          fontStyle: 'bold',
          lineHeight: 1.2,
          padding: 20
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Current (uA)',
                fontColor: '#666666',
                fontSize: 14,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Yield (cd/A)',
                fontColor: '#666666',
                fontSize: 14,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              }
            }
          ]
        }
      };

      return chartOptions;
    }
  }
};
</script>
