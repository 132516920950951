<template>
  <div>
    <!-- <div class="fixed-top text-center">
      <span class="px-1 small">
        <a class="badge badge-light" href="#"
          v-on:click.prevent="">鏡頭看板</a>
      </span>
    </div> -->
    <div class="card" style="margin-bottom: .5rem !important;">
      <div class="card-body p-3">
        <div class="row">
          <div class="col">
            <div class="w-75 mx-auto">
              <img src="../../../assets/img/map0310_2camera.jpg"
                class="img-fluid" ref="imgMap" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-2">
            <iframe id="tcCamera" :src="srcUrl" ref="frCamera"
              class="w-100" style="min-height: 600px;"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      srcUrl: 'http://10.96.15.121:1931/ui/live_view_cell',
      cond: {
      }
    };
  },
  methods: {
  },
  mounted() {
    const vm = this;
    // vm.$bus.$emit('setLoading', true);
    const qs = vm.$route.query;
    console.log(qs);
  }
};
</script>

<style scoped>
  .fl-pass {
    background-color: #64748b;
  }
  .fl-ontrip {
    background-color: #ffc864;
  }
  .fl-noyet {
    background-color: #ddd6ef;
  }
</style>
