<template>
  <div>
  </div>
</template>

<script>
// import store from '@/store';
// import { mapGetters } from 'vuex';

export default {
  components: {
  },
  // computed: {
  //   ...mapGetters({
  //     isITAdmin: 'isITAdmin',
  //     isRDArc: 'isRDArc',
  //     isRDPlatform: 'isRDPlatform',
  //     rdGenUnit: 'getRdUnit'
  //   })
  // },
  watch: {
    // isRDArc(val) {
    //   const vm = this;
    //   if (val && val === 'Y') {
    //     console.log('home/isRDArc:', vm.isRDArc);
    //     if (!vm.rdGenUnit) {
    //       // set default by Authority
    //       store.dispatch('setRdUnit', 10);
    //       console.log('set rdGenUnit by Auth:', vm.rdGenUnit);
    //     }
    //   }
    // },
    // isRDPlatform(val) {
    //   const vm = this;
    //   if (val && val === 'Y') {
    //     console.log('home/isRDPlatform:', vm.isRDPlatform);
    //     if (!vm.rdGenUnit) {
    //       // set default by Authority
    //       store.dispatch('setRdUnit', 20);
    //       console.log('set rdGenUnit by Auth:', vm.rdGenUnit);
    //     }
    //   }
    // }
  }
};
</script>
