<template>
  <div>
    <div class="row">
      <div class="col-md-2 col-sm-2 col-12
        justify-content-center mx-1"
      >
        <div class="row p-2">
          <div class="col-12 my-2">
            <label class="text-secondary text-center">
              NEW Color Space
            </label>
          </div>
          <div class="col-12 my-2">
            <label class="badge badge-danger">
              <span class="mx-2">R</span>
            </label>
            <div class="text-secondary text-center h4">
              x: {{ chartData.rPoint.x }}<br />
              y: {{ chartData.rPoint.y }}
            </div>
          </div>
          <div class="col-12 my-2">
            <label class="badge badge-success">
              <span class="mx-2">G</span>
            </label>
            <div class="text-secondary text-center h4">
              x: {{ chartData.gPoint.x }}<br />
              y: {{ chartData.gPoint.y }}
            </div>
          </div>
          <div class="col-12 my-2">
            <label class="badge badge-info">
              <span class="mx-2">B</span>
            </label>
            <div class="text-secondary text-center h4">
              x: {{ chartData.bPoint.x }}<br />
              y: {{ chartData.bPoint.y }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-12
        justify-content-center"
      >
        <div class="my-2 p-2">
          <scatter-chart
            :chartData="getChartData()"
            :chartOptions="getChartOptions()"
            :plugins="plugins"
            css-classes="chart-block"
          ></scatter-chart>
        </div>
      </div>
      <div class="col-md-3 col-sm-3 col-12
        justify-content-center mx-2"
      >
        <div class="row p-2">
          <div class="col-12 my-2">
            <button name="scNtsc" id="scNtsc"
              class="btn btn-secondary text-secondary"
              style="background-color: #FAFA5A;"
              @click.prevent="onSpaceTypeChange(chartNtsc.label)"
            >
              <i class="far fa-square" v-show="!plotTypeList.includes(chartNtsc.label)"></i>
              <i class="far fa-check-square" v-show="plotTypeList.includes(chartNtsc.label)"></i>
              <span class="mx-2">{{ chartNtsc.label }}</span>
            </button>
            <div class="text-primary text-center h4">
              <span class="display-3">{{ chartNtsc.rate }}</span>%
            </div>
          </div>
          <div class="col-12 my-2">
            <button name="scRec2020" id="scRec2020"
              class="btn btn-secondary"
              style="background-color: #F09BFF;"
              @click.prevent="onSpaceTypeChange(chartRec2020.label)"
            >
              <i class="far fa-square" v-show="!plotTypeList.includes(chartRec2020.label)"></i>
              <i class="far fa-check-square" v-show="plotTypeList.includes(chartRec2020.label)"></i>
              <span class="mx-2">{{ chartRec2020.label }}</span>
            </button>
            <div class="text-primary text-center h4">
              <span class="display-3">{{ chartRec2020.rate }}</span>%
            </div>
          </div>
          <div class="col-12 my-2">
            <button name="scDciP3" id="scDciP3"
              class="btn btn-secondary"
              style="background-color: #00E6FF;"
              @click.prevent="onSpaceTypeChange(chartDcip3.label)"
            >
              <i class="far fa-square" v-show="!plotTypeList.includes(chartDcip3.label)"></i>
              <i class="far fa-check-square" v-show="plotTypeList.includes(chartDcip3.label)"></i>
              <span class="mx-2">{{ chartDcip3.label }}</span>
            </button>
            <div class="text-primary text-center h4">
              <span class="display-3">{{ chartDcip3.rate}}</span>%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScatterChart from '@/components/Chart/ScatterChart.vue';

export default {
  name: 'ColorSpace',
  components: {
    ScatterChart
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      plotTypeList: [],
      chartNtsc: {
        label: 'NTSC',
        rate: 0,
        area: 0.1582, // 面積
        rgba4: 0.01, // 透明度
        rPoint: { x: 0.6700, y: 0.3300 },
        gPoint: { x: 0.2100, y: 0.7100 },
        bPoint: { x: 0.1400, y: 0.0800 }
      },
      chartRec2020: {
        label: 'REC2020',
        rate: 0,
        area: 0.2118665,
        rgba4: 0.01,
        rPoint: { x: 0.7080, y: 0.2920 },
        gPoint: { x: 0.1700, y: 0.7970 },
        bPoint: { x: 0.1310, y: 0.0460 }
      },
      chartDcip3: {
        label: 'DCI-P3',
        rate: 0,
        area: 0.152,
        rgba4: 0.01,
        rPoint: { x: 0.6800, y: 0.3200 },
        gPoint: { x: 0.2650, y: 0.6900 },
        bPoint: { x: 0.1500, y: 0.0600 }
      },
      plugins: [
        {
          beforeDraw(chart) {
            const { ctx } = chart;
            const {
              top, bottom, left, right
            } = chart.chartArea;

            const width = right - left;
            const height = bottom - top;

            const image = new Image();
            image.src = require('../../../../assets/img/CIE_1931xy.png');
            if (image.complete) {
              ctx.drawImage(image, left, top, width, height);

              const convertToCanvasX = function (x) {
                return left + (x / 0.8) * width;
              };
              const convertToCanvasY = function (y) {
                return top + height - (y / 0.9) * height;
              };

              // draw triangle
              const colorData = chart.data.datasets;
              // console.log(colorData);
              colorData.forEach((item) => {
                ctx.strokeStyle = item.borderColor;
                ctx.lineWidth = 3;
                ctx.beginPath(); // start line
                ctx.moveTo(
                  convertToCanvasX(item.data.rPoint.x),
                  convertToCanvasY(item.data.rPoint.y)
                );
                ctx.lineTo(
                  convertToCanvasX(item.data.gPoint.x),
                  convertToCanvasY(item.data.gPoint.y)
                );
                ctx.lineTo(
                  convertToCanvasX(item.data.bPoint.x),
                  convertToCanvasY(item.data.bPoint.y)
                );
                ctx.closePath(); // close line
                ctx.stroke(); // draw outline
              });

              ctx.restore();
            } else {
              image.onload = function () {
                chart.draw();
              };
            }
          }
        }
      ]
    };
  },
  computed: {
    chartData: {
      get() {
        return this.value;
      }
    }
  },
  methods: {
    getChartData() {
      const vm = this;
      const chartData1 = {
        datasets: [
          {
            label: 'NEW',
            fill: true,
            borderColor: '#404040',
            backgroundColor: '#404040',
            data: vm.chartData,
            order: 0
          },
          {
            label: vm.chartNtsc.label,
            fill: true,
            borderColor: `rgba(250, 250, 90, ${vm.chartNtsc.rgba4})`,
            backgroundColor: `rgba(250, 250, 90, ${vm.chartNtsc.rgba4})`,
            data: vm.chartNtsc,
            order: 1
          },
          {
            label: vm.chartRec2020.label,
            fill: true,
            borderColor: `rgba(240, 155, 255, ${vm.chartRec2020.rgba4})`,
            backgroundColor: `rgba(240, 155, 255, ${vm.chartRec2020.rgba4})`,
            data: vm.chartRec2020,
            order: 2
          },
          {
            label: vm.chartDcip3.label,
            fill: true,
            borderColor: `rgba(0, 230, 255, ${vm.chartDcip3.rgba4})`,
            backgroundColor: `rgba(0, 230, 255, ${vm.chartDcip3.rgba4})`,
            data: vm.chartDcip3,
            order: 3
          }
        ]
      };

      return chartData1;
    },
    getChartOptions() {
      const chartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        title: {
          display: true,
          text: 'CIE 1931',
          fontSize: 20,
          fontStyle: 'bold',
          lineHeight: 1.2,
          padding: 20
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'x',
                fontColor: '#666666',
                fontSize: 14,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              },
              ticks: {
                max: 0.8,
                min: 0.0,
                stepSize: 0.1
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'y',
                fontColor: '#666666',
                fontSize: 14,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              },
              ticks: {
                max: 0.9,
                min: 0.0,
                stepSize: 0.1
              }
            }
          ]
        }
      };

      return chartOptions;
    },
    onSpaceTypeChange(csType) {
      const vm = this;
      vm.$bus.$emit('setLoading', true);
      // console.log(csType);

      const pos = vm.plotTypeList.indexOf(csType);
      if (pos < 0) {
        vm.plotTypeList.push(csType); // add
        vm.$alert.success(`check ${csType}!`);
      } else {
        vm.plotTypeList.splice(pos, 1); // remove
        vm.$alert.success(`uncheck ${csType}!`);
      }

      // 設定 三角形透明度
      if (vm.plotTypeList.includes(vm.chartNtsc.label)) {
        vm.chartNtsc.rgba4 = 1.0;
      } else {
        vm.chartNtsc.rgba4 = 0.01;
      }
      if (vm.plotTypeList.includes(vm.chartRec2020.label)) {
        vm.chartRec2020.rgba4 = 1.0;
      } else {
        vm.chartRec2020.rgba4 = 0.01;
      }
      if (vm.plotTypeList.includes(vm.chartDcip3.label)) {
        vm.chartDcip3.rgba4 = 1.0;
      } else {
        vm.chartDcip3.rgba4 = 0.01;
      }

      // console.log(vm.plotTypeList);
      vm.$bus.$emit('setLoading', false);
    }
  },
  mounted() {
    const vm = this;
    // 先算面積比
    vm.chartNtsc.rate = ((vm.chartData.area / vm.chartNtsc.area) * 100).toFixed(0);
    vm.chartRec2020.rate = ((vm.chartData.area / vm.chartRec2020.area) * 100).toFixed(0);
    vm.chartDcip3.rate = ((vm.chartData.area / vm.chartDcip3.area) * 100).toFixed(0);
  }
};
</script>
