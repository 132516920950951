<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <button class="btn btn-primary" @click.exact="addNode"
           @click.ctrl="ifShowF2M = true">
          <i class="fas fa-plus-circle"></i> 新增第一階分類
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <vue-tree-list
          @change-name="onChangeName"
          @delete-node="onDel"
          @add-node="onAddNode"
          :model="treeData"
          default-tree-node-name="new item"
          default-leaf-node-name="new item"
          v-bind:default-expanded="false"
        >
          <span class="icon ml-2 tree-node-btn" slot="addTreeNodeIcon" title="Add"
            ><i class="fas fa-plus"></i
          ></span>
          <span slot="addLeafNodeIcon"></span>
          <span class="icon ml-2 tree-node-btn" slot="editNodeIcon" title="Edit"
            ><i class="fas fa-pen"></i
          ></span>
          <span class="icon ml-2 tree-node-btn" slot="delNodeIcon" title="Delete"
            ><i class="fas fa-times"></i
          ></span>
          <span class="icon ml-2 mr-1" slot="leafNodeIcon"></span>
          <span class="icon ml-2 mr-1" slot="treeNodeIcon"></span>
        </vue-tree-list>
      </div>
    </div>

    <!-- Modal -->
    <vue-final-modal v-model="ifShowF2M"
      :lock-scroll="true"
      :click-to-close="false"
      :esc-to-close="true"
      :z-index-auto="true"
      name="f2Command"
      classes="modal-container"
      content-class="modal-content dark-mode"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="f2CommandTitle">F2 command</h5>
        <button type="button" class="close" v-on:click="ifShowF2M = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <textarea rows="15" class="form-control" v-model="commandF2">
            </textarea>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-3">
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text">工號</span></div>
                <input type="text" class="form-control" v-model="empF2"
                  :disabled="(!commandF2 || commandF2.length < 20)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default"
          v-on:click="ifShowF2M = false">Cancel</button>
        <button type="button" class="btn btn-success" v-on:click.prevent="goF2()">
          <i class="fas fa-save"></i> 執行!
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list';
import { CategoryConfigApiService } from '@/utils/apiService';
import _ from 'lodash';

export default {
  components: {
    VueTreeList
  },
  data() {
    return {
      treeData: new Tree([]),
      ifShowF2M: false,
      commandF2: 'your command here.',
      empF2: ''
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  methods: {
    onDel(node) {
      const vm = this;

      vm.$confirm('是否要删除?').then((result) => {
        if (result.value) {
          node.remove();
          vm.deleteCategory(node.id);
        }
      });
    },
    onChangeName: _.debounce(function (params) {
      const vm = this;
      vm.renameCategory(params.id, params.newName);
    }, 500),
    onAddNode(params) {
      const vm = this;
      vm.addCategory(params.pid, params.name);
    },
    addNode() {
      const vm = this;
      const node = new TreeNode({ name: 'new item', isLeaf: false });
      if (!this.treeData.children) this.treeData.children = [];
      this.treeData.addChildren(node);
      vm.addCategory(1, 'new item');
    },
    getTree() {
      const vm = this;
      CategoryConfigApiService.getTree().then((response) => {
        const res = response.data;
        if (res.success) {
          vm.treeData = new Tree(res.data);
        }
      });
    },
    renameCategory(id, newName) {
      const vm = this;
      const params = {
        userId: vm.userInfo.userId,
        data: {
          id,
          name: newName
        }
      };

      CategoryConfigApiService.updateCategory(params).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.getTree();
        }
      });
    },
    deleteCategory(id) {
      const vm = this;
      const params = {
        categoryId: id,
        userId: vm.userInfo.userId
      };

      CategoryConfigApiService.deleteCategory(params).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.getTree();
        }
      });
    },
    addCategory(pid, name) {
      const vm = this;
      const params = {
        userId: vm.userInfo.userId,
        data: {
          pid,
          name
        }
      };

      CategoryConfigApiService.addCategory(params).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.getTree();
        }
      });
    },
    goF2() {
      const vm = this;

      if (!vm.commandF2 || !vm.empF2) {
        vm.$alert.error('欄位不得為空!');
        return;
      }
      if (vm.empF2 !== vm.userInfo.userId) {
        vm.$alert.error('工號錯誤?!');
        return;
      }

      vm.$confirm('是否要執行?').then((result) => {
        if (result.value) {
          const params = {
            userId: vm.userInfo.userId,
            data: { name: vm.commandF2 }
          };

          CategoryConfigApiService.runF2Command(params).then((response) => {
            const res = response.data;
            if (res.success) {
              vm.$alert.success('執行成功');
            } else {
              vm.$alert.error(result.message);
            }
          });
        }
      });
    }
  },
  mounted() {
    const vm = this;
    vm.getTree();
  },
  beforeDestroy() {
    console.log('vue-final-modal remove is original bug!?');
  }
};
</script>

<style scoped>
.tree-node-btn {
  cursor: pointer;
}
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 72%;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
