<template>
  <div>
    <div class="card">
      <div class="card-body bg-secondary">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>元件主動層</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.deviceActiveLayerTitle"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>Fab Name</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.fabName"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>PEP</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.pepTitle"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>CF Structure</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.cfStractureTitle"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>M1/M2</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.m1M2Title"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>元件膜質</label>
              <input
                type="text"
                class="form-control"
                v-model="mcInfo.tftQualityRecipe"
                disabled
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>TFT Channel Metal Length</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="mcInfo.tftChannelMetalLen"
                  disabled
                />
                <label class="input-group-text">um</label>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label>Pixel / GOA</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="sheetPrefix"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    mcInfo: { type: Object, default: null },
    sheetPrefix: { type: String, default: '' }
  },
  methods: {
  },
  mounted() {
  }
};
</script>
