<template>
  <div>
    <div class="card">
      <div class="card-body p-2">
        <div class="row justify-content-center" v-show="!isReadonly">
          <div class="col-6">
            <step-progress
              :steps="stepList"
              :current-step="currStep"
              icon-class="fas fa-check"
              :line-thickness="6"
              activeColor="lightskyblue"
              passiveColor="lightgray"
            >
            </step-progress>
          </div>
        </div>
        <div class="row justify-content-center" v-show="isReadonly">
          <div class="col-6">
            <div class="input-group">
              <label class="input-group-text">ModelName</label>
              <input
                id="modelName"
                type="text"
                :class="{ 'form-control': true }"
                v-model="formData.modelName"
                :readonly="true"
                style="width: 350px; max-width: 350px"
              />
              <input
                id="version"
                type="number"
                :class="{ 'form-control': true }"
                style="width: 100px; max-width: 100px"
                v-model="formData.version"
                :readonly="true"
              />
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary step-btn"
            @click.prevent="returnToList()"
          >
            <i class="fas fa-arrow-up"></i> 返回列表
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body py-3">
        <div class="row justify-content-center mb-2"
          v-show="currStep != 0 && !isReadonly">
          <div class="col-6">
            <div class="input-group">
              <label class="input-group-text">ModelName</label>
              <input
                id="modelName"
                type="text"
                :class="{ 'form-control': true }"
                v-model="formData.modelName"
                :readonly="true"
                style="width: 350px; max-width: 350px"
              />
              <input
                id="version"
                type="number"
                :class="{ 'form-control': true }"
                style="width: 100px; max-width: 100px"
                v-model="formData.version"
                :readonly="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8 offset-2 border pb-0">
            <validation-observer ref="formSizeInfo">
              <size-info
                ref="sizeInfo"
                v-show="currStep == 0 || isReadonly"
                v-model="formData"
                :isReadonly="isReadonly"
              ></size-info>
            </validation-observer>
            <validation-observer ref="formLightingInfo">
              <lighting-info
                ref="lightingInfo"
                v-show="currStep == 1 || isReadonly"
                v-model="formData"
                :isReadonly="isReadonly"
              ></lighting-info>
            </validation-observer>
            <validation-observer ref="formFileInfo">
              <file-info
                ref="fileInfo"
                v-show="currStep == 2 || isReadonly"
                v-model="formData"
                :isReadonly="isReadonly"
              ></file-info>
            </validation-observer>
            <calc-result
              ref="calcResult"
              v-show="currStep == 3 || isReadonly"
              v-model="formData"
              :isReadonly="isReadonly"
            ></calc-result>
          </div>
        </div>
      </div>
      <div class="card-footer text-center pt-0">
        <button
          type="button"
          class="btn btn-secondary step-btn"
          @click.prevent="returnToList()"
        >
          <i class="fas fa-arrow-up"></i> 返回列表
        </button>
        <button
          type="button"
          class="btn btn-warning step-btn"
          v-show="!isReadonly"
          @click.prevent="stepBack()"
          :disabled="currStep === 0"
        >
          <i class="fas fa-arrow-circle-left"></i> 上一步
        </button>
        <button
          type="button"
          class="btn btn-success step-btn"
          v-show="!isReadonly"
          @click.prevent="stepForward()"
          :disabled="currStep > 2"
        >
          <i class="fas fa-arrow-circle-right"></i> 下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PanelCalcApiService } from '@/utils/apiService';
import StepProgress from 'vue-step-progress';
import SizeInfo from '@/views/Core/PanelCalc/SizeInfo.vue';
import LightingInfo from '@/views/Core/PanelCalc/LightingInfo.vue';
import FileInfo from '@/views/Core/PanelCalc/FileInfo.vue';
import CalcResult from '@/views/Core/PanelCalc/CalcResult.vue';

export default {
  components: {
    'step-progress': StepProgress,
    SizeInfo,
    LightingInfo,
    FileInfo,
    CalcResult
  },
  props: {
    mode: { type: String, default: 'create' },
    panelCalcDataId: { type: Number, default: 0 }
  },
  data() {
    return {
      stepList: [
        'Step1.尺寸資訊',
        'Step2.發光資訊',
        'Step3.光學檔案',
        'Step4.計算結果'
      ],
      currStep: 0,
      currMode: 'create',
      formData: {
        panelSize: null,
        panelShape: null,
        aspectRatio: null,
        pixelPerInch: null,
        isReleased: null,
        whiteLightCieX: null,
        whiteLightCieY: null,
        colorFilterTemplate: 999,
        panelNitR: null,
        panelNitG: null,
        panelNitB: null,
        subPixelCurrR: null,
        subPixelCurrG: null,
        subPixelCurrtB: null
      }
    };
  },
  computed: {
    isReadonly() {
      const vm = this;
      if (vm.currMode === 'create') {
        return false;
      }
      if (vm.currMode === 'view') {
        return true;
      }
      if (vm.formData.isReleased === 'Y') {
        return true;
      }
      return false;
    }
  },
  watch: {
    formData() {
      const vm = this;
      if (vm.formData.panelCalcDataId && vm.currMode === 'create') {
        // 計算後轉換處理
        vm.currMode = 'edit';
        vm.panelCalcDataId = vm.formData.panelCalcDataId;
        console.log(`calc ok: ${vm.currMode}/${vm.panelCalcDataId}`);
      }
    }
  },
  methods: {
    returnToList() {
      const vm = this;
      vm.$router.push({
        name: 'PanelCalcIndex'
      });
    },
    stepBack() {
      const vm = this;
      vm.currStep -= 1;
    },
    stepForward() {
      const vm = this;
      let refComponent = null;

      switch (vm.currStep) {
        case 0:
          refComponent = vm.$refs.formSizeInfo;
          break;
        case 1:
          refComponent = vm.$refs.formLightingInfo;
          // default value
          if (!vm.formData.colorFilterTemplate) {
            vm.formData.colorFilterTemplate = 999;
          }
          if (!vm.formData.whiteLightCieX || !vm.formData.whiteLightCieY) {
            vm.formData.whiteLightCieX = 0.313;
            vm.formData.whiteLightCieY = 0.329;
          }
          break;
        case 2:
          refComponent = vm.$refs.formFileInfo;
          // default value
          if (vm.formData.ledSpectrumData !== 'Y' && !vm.formData.panelCurrent) {
            vm.formData.panelNitR = Math.round(vm.formData.panelBrightness * 0.26 * 100) / 100;
            vm.formData.panelNitG = Math.round(vm.formData.panelBrightness * 0.695 * 100) / 100;
            vm.formData.panelNitB = Math.round((Number(vm.formData.panelBrightness)
              - Number(vm.formData.panelNitR) - Number(vm.formData.panelNitG))
              * 100) / 100; // 小數點總和問題
          }
          break;
      }

      if (vm.isReadonly) {
        vm.currStep += 1;
      } else {
        refComponent.validate().then((result) => {
          if (!result) {
            vm.$alert.warning('請檢查填寫資料是否完整');
          } else if (vm.currStep === 0) {
            vm.checkValidModelVer(); // 先檢查名稱 model + version
          } else {
            vm.currStep += 1;
          }
        });
      }
    },
    getFormData() {
      const vm = this;

      const params = {
        panelCalcDataId: vm.panelCalcDataId
      };

      PanelCalcApiService.getPanelCalcData(params).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.formData = result.data;

          if (result.message !== null) {
            vm.$alert.warning(result.message);
          }
        } else {
          vm.$alert.error(result.message);
        }
      });
    },
    checkValidModelVer() {
      const vm = this;

      const params = {
        modelName: vm.formData.modelName,
        version: vm.formData.version,
        mcId: vm.panelCalcDataId
      };

      PanelCalcApiService.checkValidModelVer(params).then((response) => {
        const result = response.data;
        if (result.success) {
          if (result.data === 'Y') {
            vm.currStep += 1;
          } else {
            vm.$alert.warning('Model/Version 名稱重複請修正!');
          }
        } else {
          vm.$alert.error(result.message);
        }
      });
    }
  },
  mounted() {
    const vm = this;
    vm.currMode = vm.mode;
    console.log(`prop: ${vm.mode}/${vm.panelCalcDataId}`);

    if (vm.currMode !== 'create') {
      vm.getFormData();
    }
  }
};
</script>

<style scoped>
.step-btn {
  width: 120px;
}
</style>
