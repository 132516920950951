<template>
  <div v-if="downloadPermission==='Y'">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12">
        <div class="card text-center">
          <div class="card-header bg-light">
            {{ fileTypeName[0] }} Files
            <span class="float-right">
              <button v-if="modelCardFileList && modelCardFileList.length > 1"
                class="btn btn-sm btn-outline-info my-0" title="改版紀錄"
                @click.prevent="viewList(0)">
                <i class="fas fa-list"></i>
              </button>
            </span>
          </div>
          <div class="card-body"
            v-if="!modelCardFileList || modelCardFileList.length <= 0">
            <label class="my-4">無資料</label>
          </div>
          <div class="card-body"
            v-if="modelCardFileList && modelCardFileList.length > 0">
            <h5 class="card-title mb-2">{{ modelCardFile1.fileName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ modelCardFile1.fileDesc }}
            </h6>
            <div class="text-muted small">
              {{ modelCardFile1.creatorEmpNo + " / " + modelCardFile1.creatorEmpName }}
            </div>
            <div class="text-muted small mb-2">
              {{ modelCardFile1.createDate | formatDateTime }}
            </div>
            <button class="btn btn-sm btn-secondary btn-block"
              @click.prevent="downloadFileM(modelCardFile1.fileId,
                modelCardFile1.fileName, modelCardFile1.modelCardVerId)">
              <i class="fas fa-download"></i> 下載
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12">
        <div class="card text-center">
          <div class="card-header bg-light">
            {{ fileTypeName[1] }} Files
            <span class="float-right">
              <button v-if="qAReportFileList && qAReportFileList.length > 1"
                class="btn btn-sm btn-outline-info" title="改版紀錄"
                @click.prevent="viewList(1)">
                <i class="fas fa-list"></i>
              </button>
            </span>
          </div>
          <div class="card-body"
            v-if="!qAReportFileList || qAReportFileList.length <= 0">
            <label class="my-4">無資料</label>
          </div>
          <div class="card-body"
            v-if="qAReportFileList && qAReportFileList.length > 0">
            <h5 class="card-title mb-2">{{ qAReportFile1.fileName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ qAReportFile1.fileDesc }}
            </h6>
            <div class="text-muted small">
              {{ qAReportFile1.creatorEmpNo + " / " + qAReportFile1.creatorEmpName }}
            </div>
            <div class="text-muted small mb-2">
              {{ qAReportFile1.createDate | formatDateTime }}
            </div>
            <button class="btn btn-sm btn-secondary btn-block"
              @click.prevent="downloadFileM(qAReportFile1.fileId,
                qAReportFile1.fileName, qAReportFile1.modelCardVerId)">
              <i class="fas fa-download"></i> 下載
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12">
        <div class="card text-center">
          <div class="card-header bg-light">
            {{ fileTypeName[2] }} Files
            <span class="float-right">
              <button v-if="measurementDataFileList && measurementDataFileList.length > 1"
                class="btn btn-sm btn-outline-info" title="改版紀錄"
                @click.prevent="viewList(2)">
                <i class="fas fa-list"></i>
              </button>
            </span>
          </div>
          <div class="card-body"
            v-if="!measurementDataFileList || measurementDataFileList.length <= 0">
            <label class="my-4">無資料</label>
          </div>
          <div class="card-body"
            v-if="measurementDataFileList && measurementDataFileList.length > 0">
            <h5 class="card-title mb-2">{{ measurementDataFile1.fileName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ measurementDataFile1.fileDesc }}
            </h6>
            <div class="text-muted small">
              {{ measurementDataFile1.creatorEmpNo + " / " + measurementDataFile1.creatorEmpName }}
            </div>
            <div class="text-muted small mb-2">
              {{ measurementDataFile1.createDate | formatDateTime }}
            </div>
            <button class="btn btn-sm btn-secondary btn-block"
              @click.prevent="downloadFileM(measurementDataFile1.fileId,
                measurementDataFile1.fileName, measurementDataFile1.modelCardVerId)">
              <i class="fas fa-download"></i> 下載
            </button>
          </div>
        </div>
      </div>

      <div v-if="isPfaSi" class="col-md-3 col-sm-6 col-12">
        <div class="card text-center">
          <div class="card-header bg-light">
            {{ fileTypeName[3] }} Files
            <span class="float-right">
              <button v-if="goldenSampleFileList && goldenSampleFileList.length > 1"
                class="btn btn-sm btn-outline-info" title="改版紀錄"
                @click.prevent="viewList(3)">
                <i class="fas fa-list"></i>
              </button>
            </span>
          </div>
          <div class="card-body"
            v-if="!goldenSampleFileList || goldenSampleFileList.length <= 0">
            <label class="my-4">無資料</label>
          </div>
          <div class="card-body"
            v-if="goldenSampleFileList && goldenSampleFileList.length > 0">
            <h5 class="card-title mb-2">{{ goldenSampleFile1.fileName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ goldenSampleFile1.fileDesc }}
            </h6>
            <div class="text-muted small">
              {{ goldenSampleFile1.creatorEmpNo + " / " + goldenSampleFile1.creatorEmpName }}
            </div>
            <div class="text-muted small mb-2">
              {{ goldenSampleFile1.createDate | formatDateTime }}
            </div>
            <button class="btn btn-sm btn-secondary btn-block"
              @click.prevent="downloadFileM(goldenSampleFile1.fileId,
                goldenSampleFile1.fileName, goldenSampleFile1.modelCardVerId)">
              <i class="fas fa-download"></i> 下載
            </button>
          </div>
        </div>
      </div>

      <div v-if="isLed" class="col-md-3 col-sm-6 col-12">
        <div class="card text-center">
          <div class="card-header bg-light">
            {{ fileTypeName[4] }} Files
            <span class="float-right">
              <button v-if="ledOpticalFileList && ledOpticalFileList.length > 1"
                class="btn btn-sm btn-outline-info" title="改版紀錄"
                @click.prevent="viewList(4)">
                <i class="fas fa-list"></i>
              </button>
            </span>
          </div>
          <div class="card-body"
            v-if="!ledOpticalFileList || ledOpticalFileList.length <= 0">
            <label class="my-4">無資料</label>
          </div>
          <div class="card-body"
            v-if="ledOpticalFileList && ledOpticalFileList.length > 0">
            <h5 class="card-title mb-2">{{ ledOpticalFile1.fileName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ ledOpticalFile1.fileDesc }}
            </h6>
            <div class="text-muted small">
              {{ ledOpticalFile1.creatorEmpNo + " / " + ledOpticalFile1.creatorEmpName }}
            </div>
            <div class="text-muted small mb-2">
              {{ ledOpticalFile1.createDate | formatDateTime }}
            </div>
            <button class="btn btn-sm btn-secondary btn-block"
              @click.prevent="downloadFileM(ledOpticalFile1.fileId,
                ledOpticalFile1.fileName, ledOpticalFile1.modelCardVerId)">
              <i class="fas fa-download"></i> 下載
            </button>
          </div>
        </div>
      </div>

    </div>

    <!-- Modal -->
    <vue-final-modal name="Model1" v-model="ifShowModal1"
      :lock-scroll="false" :click-to-close="false"
      :esc-to-close="true" :z-index-auto="true"
      classes="modal-container" content-class="modal-content dark-mode">
      <div class="modal-header">
        <h5 class="modal-title" id="Modal1">
          {{ fileTypeName[fileModel.fType] }} File Version List
        </h5>
        <button type="button" class="close" v-on:click="ifShowModal1 = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <table class="table table-striped table-hover border">
              <thead>
                <tr>
                  <th>檔案名稱</th>
                  <th>檔案說明</th>
                  <th style="width: 200px">建立者</th>
                  <th style="width: 200px">建立時間</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in fileModel.fList" :key="item.fileId">
                  <td class="align-middle">{{ item.fileName }}</td>
                  <td class="align-middle small">{{ item.fileDesc }}</td>
                  <td class="align-middle">
                    {{ item.creatorEmpNo + " / " + item.creatorEmpName }}
                  </td>
                  <td class="align-middle">{{ item.createDate | formatDateTime }}</td>
                </tr>
                <tr v-if="!fileModel.fList || fileModel.fList.length === 0">
                  <td class="align-middle text-center" colspan="4">無資料</td>
                </tr>
              </tbody>
            </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default"
          v-on:click="ifShowModal1 = false">Close</button>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ModelCardApiService } from '@/utils/apiService';

export default {
  data() {
    return {
      fileTypeName: ['Model Card', 'QA Report', 'Measurement Data', 'Golden Sample Selection', 'LED Optical'],
      modelCardFileList: [],
      qAReportFileList: [],
      measurementDataFileList: [],
      goldenSampleFileList: [],
      ledOpticalFileList: [],
      modelCardFile1: {},
      qAReportFile1: {},
      measurementDataFile1: {},
      goldenSampleFile1: {},
      ledOpticalFile1: {},
      ifShowModal1: false,
      fileModel: {
        fType: 0,
        fList: []
      }
    };
  },
  props: {
    mcId: { type: Number, default: 0 },
    isPfaSi: { type: Boolean, default: false },
    isLed: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      downloadPermission: 'getDownloadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin'
    })
  },
  methods: {
    getModelCardFileList() {
      const vm = this;
      if (vm.mcId === 0) {
        return null;
      }

      const params = {
        mcId: vm.mcId,
        roleIdList: vm.roleIdList
      };

      return ModelCardApiService.getMCFileList1(params).then((response) => {
        const result = response.data;
        if (result.success) {
          const retL = result.data;
          vm.modelCardFileList = retL.filter((obj) => (obj.modelCardFileType === '1'));
          vm.qAReportFileList = retL.filter((obj) => (obj.modelCardFileType === '2'));
          vm.measurementDataFileList = retL.filter((obj) => (obj.modelCardFileType === '3'));
          vm.goldenSampleFileList = retL.filter((obj) => (obj.modelCardFileType === '4'));
          vm.ledOpticalFileList = retL.filter((obj) => (obj.modelCardFileType === '5'));
          // console.log(vm.retL);
          if (vm.modelCardFileList && vm.modelCardFileList.length > 0) {
            vm.modelCardFile1 = vm.modelCardFileList[0];
          }
          if (vm.qAReportFileList && vm.qAReportFileList.length > 0) {
            vm.qAReportFile1 = vm.qAReportFileList[0];
          }
          if (vm.measurementDataFileList && vm.measurementDataFileList.length > 0) {
            vm.measurementDataFile1 = vm.measurementDataFileList[0];
          }
          if (vm.goldenSampleFileList && vm.goldenSampleFileList.length > 0) {
            vm.goldenSampleFile1 = vm.goldenSampleFileList[0];
          }
          if (vm.ledOpticalFileList && vm.ledOpticalFileList.length > 0) {
            vm.ledOpticalFile1 = vm.ledOpticalFileList[0];
          }
        }
      });
    },
    downloadFileM(fileId, fileName, mcVerId) {
      const vm = this;

      const params = {
        modelCardVerId: mcVerId,
        fileId,
        userId: vm.userInfo.userId
      };

      ModelCardApiService.getModelCardFile(params).then((response) => {
        if (response.status === 200) {
          // console.log(response);
          if (response.data.size !== 0) {
            const blob = new Blob([response.data], {
              type: 'application/octet-binary'
            });

            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = fileName;
              link.click();
            }
          } else {
            vm.$alert.error('檔案下載失敗', 'error');
          }
        } else {
          vm.$alert.error(response.text);
        }
      });
    },
    viewList(fileT) {
      const vm = this;
      if (vm.mcId === 0) {
        return;
      }
      vm.fileModel.fType = fileT;
      vm.getModelCardFileVersionList();
      vm.ifShowModal1 = true;
    },
    getModelCardFileVersionList() {
      const vm = this;
      vm.fileModel.fList = [];

      const params = {
        mcId: vm.mcId,
        fileType: vm.fileModel.fType + 1, // api 起始為 1
        roleIdList: vm.roleIdList
      };

      ModelCardApiService.getMCFileList1(params).then((response) => {
        const result = response.data;
        if (result.success) {
          const retL = result.data;
          vm.fileModel.fList = retL;
          console.log(retL);
        }
      });
    }
  },
  mounted() {
    const vm = this;
    if (vm.mcId === 0) {
      return;
    }

    vm.$bus.$emit('setLoading', true);
    const p1 = new Promise((resolve) => {
      resolve(vm.getModelCardFileList());
    });

    p1.then(() => {
      vm.$bus.$emit('setLoading', false);
    });
  },
  beforeDestroy() {
    console.log('vue-final-modal remove is original bug!?');
  }
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 72%;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
