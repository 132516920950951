<script>
import { Scatter, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Scatter,
  mixins: [reactiveProp],
  props: ['chartOptions'],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>
