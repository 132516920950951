<template>
  <div>
    <div class="card">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-6">
              <step-progress :steps="stepList" :current-step="currStep"
                icon-class="fas fa-check" :line-thickness="6"
                activeColor="lightskyblue" passiveColor="lightgray">
              </step-progress>
            </div>
          </div>
        </div>
    </div>

    <div class="card" v-show="currStep === 0">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="alert alert-light text-center" role="alert">
              <p class="h3 text-danger">* 請注意 *</p>
              <p class="h4 text-danger">重新上傳新版 Model Card，舊版檔案將會 PhaseOut 無法下載!</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button type="button" class="btn btn-secondary"
          v-on:click.prevent="goBack()">
          <i class="fas fa-times-circle"></i> 取消
        </button>
        <button type="button" class="btn btn-success"
          v-if="uploadPermission==='Y'"
          v-on:click.prevent="(checkStep(0))">
          <i class="fas fa-arrow-circle-right"></i> 下一步
        </button>
      </div>
    </div>

    <div class="card" v-show="currStep === 1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <label>Model Card Files <span class="required">*</span></label>
              <ex-file-upload ref="modelCardFiles"
                @onUploaded="onUploadedForModelCard"
                @onCancelUpload="onCancelUploadForModelCard"
                @onDeleted="onDeletedForModelCard"
                :fileType="isPfaSi ? 'zip' : 'mod'" :isMultiple="false">
              </ex-file-upload>
            </div>
          </div>
          <div v-if="!isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <label>QA Report Files <span class="required">*</span></label>
              <ex-file-upload ref="qAReportFiles"
                @onUploaded="onUploadedForQAReport"
                @onCancelUpload="onCancelUploadForQAReport"
                @onDeleted="onDeletedForQAReport"
                fileType="ppt" :isMultiple="false">
              </ex-file-upload>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button type="button" class="btn btn-default"
          v-on:click.prevent="checkStep(-1)">
          <i class="fas fa-arrow-circle-left"></i> 上一步
        </button>
        <button type="button" class="btn btn-success"
          v-on:click.prevent="checkStep(currStep)">
          <i class="fas fa-arrow-circle-right"></i> 下一步
        </button>
      </div>
    </div>

    <div class="card" v-show="currStep === 2">
      <div class="card-body">
        <div class="row">
          <div v-if="!isPfaSi" class="col-md-6 col-sm-6 col-12">
            <template v-if="isLed || isLtps">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label
                      >Measurement Data Files <span class="required">*</span>
                      <a v-if="isLed"
                        :href="urlWebImg+'template_led_measurement-202205.xlsx'"
                        class="badge bg-secondary mx-1"
                        target="_self"
                        title="download template"
                        >template
                      </a>
                      <a v-if="isLtps"
                        :href="urlWebImg+'template_ltps_measurement-202205.xls'"
                        class="badge bg-secondary mx-1"
                        target="_self"
                        title="download template"
                        >template
                      </a>
                    </label>
                    <ex-file-upload
                      ref="measurementDataFiles"
                      @onUploaded="onUploadedForMeasureParsing"
                      @onCancelUpload="onCancelUploadForMeasureParsing"
                      @onDeleted="onDeletedForMeasureParsing"
                      fileType="xls"
                      :isMultiple="false"
                    >
                    </ex-file-upload>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <pre
                      v-if="
                        formData.measurementDataFiles &&
                        formData.measurementDataFiles.length > 0
                      "
                      class="alert alert-primary h4"
                    >{{ formData.measurementDataFiles[0].retMsg.trim() }}</pre
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Measurement Data Files <span class="required">*</span>
                    </label>
                    <ex-file-upload ref="measurementDataFiles"
                      @onUploaded="onUploadedForMeasure"
                      @onCancelUpload="onCancelUploadForMeasure"
                      @onDeleted="onDeletedForMeasure"
                      fileType="xls" :isMultiple="false">
                    </ex-file-upload>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Measurement Data Files <span class="required">*</span>
                    <a :href="urlWebImg+'template_fitting.xlsx'" class="badge bg-secondary mx-1"
                      target="_self" title="download template">template</a>
                  </label>
                  <ex-file-upload ref="measurementDataFiles"
                    @onUploaded="onUploadedForFitting"
                    @onCancelUpload="onCancelUploadForFitting"
                    @onDeleted="onDeletedForFitting"
                    fileType="xls" :isMultiple="false">
                  </ex-file-upload>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <pre v-if="formData.measurementDataFiles
                    && formData.measurementDataFiles.length > 0"
                    class="alert alert-primary h4">
                    {{formData.measurementDataFiles[0].retMsg.trim()}}
                  </pre>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Golden Sample Selection Files <span class="required">*</span>
                    <a :href="urlWebImg+'template_sample.xlsx'" class="badge bg-secondary mx-1"
                      target="_self" title="download template">template</a>
                  </label>
                  <ex-file-upload ref="goldenSampleFiles"
                    @onUploaded="onUploadedForSample"
                    @onCancelUpload="onCancelUploadForSample"
                    @onDeleted="onDeletedForSample"
                    fileType="xls" :isMultiple="false">
                  </ex-file-upload>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <pre v-if="formData.goldenSampleFiles
                    && formData.goldenSampleFiles.length > 0"
                    class="alert alert-primary h4">
                    {{formData.goldenSampleFiles[0].retMsg.trim()}}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button type="button" class="btn btn-default"
          v-on:click.prevent="checkStep(-1)">
          <i class="fas fa-arrow-circle-left"></i> 上一步
        </button>
        <button type="button" class="btn btn-success"
          v-on:click.prevent="save()">
          <i class="fas fa-arrow-circle-right"></i> 確認改版
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WEB_IMG_URL } from '@/utils/config';
import StepProgress from 'vue-step-progress';
import ExFileUpload from '@/components/ExFileUpload.vue';
import {
  FileService,
  ModelCardApiService
} from '@/utils/apiService';

export default {
  components: {
    ExFileUpload,
    'step-progress': StepProgress
  },
  data() {
    return {
      urlWebImg: WEB_IMG_URL,
      stepList: ['Step1.改版說明', 'Step2.ModelCard檔案', 'Step3.量測檔案'],
      currStep: 0,
      formData: {
        modelCardFiles: [],
        qAReportFiles: [],
        measurementDataFiles: [],
        goldenSampleFiles: []
      }
    };
  },
  props: {
    mcId: { type: Number, default: 0 },
    deviceCategory: { type: Number, default: 0 },
    deviceActiveLayer: { type: Number, default: 0 },
    isPfaSi: { type: Boolean, default: false },
    isLed: { type: Boolean, default: false },
    isLtps: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      uploadPermission: 'getUploadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    })
  },
  methods: {
    checkStep(stepNo) {
      const vm = this;
      switch (stepNo) {
        case 0:
          if (!vm.mcId) {
            vm.$alert.warning('資訊有誤，請重新操作!');
            vm.goBack();
          }
          vm.currStep += 1;
          break;
        case 1:
          if (vm.formData.modelCardFiles.length === 0) {
            vm.$alert.warning('請上傳 Model Card 檔案');
            return;
          }
          // ARC / PLATFORM
          if (vm.formData.qAReportFiles.length === 0
            && !vm.isPfaSi) {
            vm.$alert.warning('請上傳 QA Report Data 檔案');
            return;
          }
          vm.currStep += 1;
          break;
        case 2:
          if (vm.formData.measurementDataFiles.length === 0) {
            vm.$alert.warning('請上傳 Measurement Data 檔案');
            return;
          }
          // PLATFORM TFT
          if (vm.formData.goldenSampleFiles.length === 0
            && vm.isPfaSi) {
            vm.$alert.warning('請上傳 Golden Sample 檔案');
            return;
          }
          vm.currStep += 1;
          break;
        case -1:
          vm.currStep -= 1;
          break;
      }
    },
    save() {
      const vm = this;
      if (vm.formData.modelCardFiles.length === 0) {
        vm.$alert.warning('請上傳 Model Card 檔案');
        return;
      }
      // ARC
      if (vm.formData.qAReportFiles.length === 0
        && !vm.isPfaSi) {
        vm.$alert.warning('請上傳 QA Report Data 檔案');
        return;
      }
      if (vm.formData.measurementDataFiles.length === 0) {
        vm.$alert.warning('請上傳 Measurement Data 檔案');
        return;
      }
      // PLATFORM TFT
      if (vm.formData.goldenSampleFiles.length === 0
        && vm.isPfaSi) {
        vm.$alert.warning('請上傳 Golden Sample 檔案');
        return;
      }

      // console.log(0);
      // file desc -> 只能1筆
      vm.formData.modelCardFiles[0].fileDesc = vm.$refs.modelCardFiles
        .files[vm.$refs.modelCardFiles.files.length - 1].fDesc;
      if (vm.formData.qAReportFiles && vm.formData.qAReportFiles.length > 0) {
        vm.formData.qAReportFiles[0].fileDesc = vm.$refs.qAReportFiles
          .files[vm.$refs.qAReportFiles.files.length - 1].fDesc;
      }
      vm.formData.measurementDataFiles[0].fileDesc = vm.$refs.measurementDataFiles
        .files[vm.$refs.measurementDataFiles.files.length - 1].fDesc;
      if (vm.formData.goldenSampleFiles && vm.formData.goldenSampleFiles.length > 0) {
        vm.formData.goldenSampleFiles[0].fileDesc = vm.$refs.goldenSampleFiles
          .files[vm.$refs.goldenSampleFiles.files.length - 1].fDesc;
      }

      // console.log(1);
      vm.formData.mcId = vm.mcId; // required
      vm.formData.rdGenUnit = vm.rdGenUnit; // required
      vm.formData.deviceCategory = vm.deviceCategory; // required
      vm.formData.deviceActiveLayer = vm.deviceActiveLayer; // required
      const params = {
        userId: vm.userInfo.userId,
        data: vm.formData
      };

      ModelCardApiService.updateModelCard1(params).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.$alert.success('改版成功');
          vm.goBack();
        } else {
          vm.$alert.error(result.message);
        }
      });
    },
    goBack() {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardDetail',
        params: {
          mcId: vm.mcId,
          deviceCategory: vm.deviceCategory,
          deviceActiveLayer: vm.deviceActiveLayer,
          retUrl: 'ModelCardIndex'
        }
      });
      vm.$bus.$emit('setLoading', true);
    },
    onCancelUploadForModelCard() {},
    onUploadedForModelCard(file) {
      const vm = this;
      vm.formData.fileDataForModelCard = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.modelCardFiles = [];
          vm.formData.modelCardFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
        } else {
          vm.$refs.modelCardFiles.files.splice(vm.$refs.modelCardFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForModelCard(file, index) {
      const vm = this;
      vm.formData.modelCardFiles.splice(index, 1);
    },
    onCancelUploadForQAReport() {},
    onUploadedForQAReport(file) {
      const vm = this;
      vm.formData.fileDataForQAReport = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.qAReportFiles = [];
          vm.formData.qAReportFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
        } else {
          vm.$refs.qAReportFiles.files.splice(vm.$refs.qAReportFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForQAReport(file, index) {
      const vm = this;
      vm.formData.qAReportFiles.splice(index, 1);
    },
    onCancelUploadForMeasureParsing() {},
    onUploadedForMeasureParsing(file) {
      const vm = this;
      const formData = new FormData();
      let excelKind;

      if (vm.isLtps) { excelKind = 4; }
      if (vm.isLed) { excelKind = 5; }

      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', excelKind);
      formData.append('UserId', vm.userInfo.userId);

      ModelCardApiService.uploadMeasureData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
          vm.formData.measurementDataFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc);
        } else {
          vm.$alert.error(result.message);
          vm.$refs.measurementDataFiles.files.splice(
            vm.$refs.measurementDataFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForMeasureParsing(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onCancelUploadForMeasure() {},
    onUploadedForMeasure(file) {
      const vm = this;
      vm.formData.measurementDataFiles = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
        } else {
          vm.$refs.measurementDataFiles.files.splice(
            vm.$refs.measurementDataFiles.files.length - 1, 1
          );
        }
      });
    },
    onDeletedForMeasure(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onCancelUploadForFitting() {},
    onUploadedForFitting(file) {
      const vm = this;
      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', 1);
      formData.append('UserId', vm.userInfo.userId);
      // console.log(file);
      ModelCardApiService.uploadFittingData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
          vm.formData.measurementDataFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc); // 結果訊息
        } else {
          vm.$alert.error(result.message);
          vm.$refs.measurementDataFiles.files
            .splice(vm.$refs.measurementDataFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForFitting(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onUploadedForSample(file) {
      const vm = this;
      vm.formData.goldenSampleFiles = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', 2);
      formData.append('UserId', vm.userInfo.userId);

      ModelCardApiService.uploadFittingData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.goldenSampleFiles = [];
          vm.formData.goldenSampleFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
          vm.formData.goldenSampleFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc); // 結果訊息
        } else {
          vm.$alert.error(result.message);
          vm.$refs.goldenSampleFiles.files.splice(vm.$refs.goldenSampleFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForSample(file, index) {
      const vm = this;
      vm.formData.goldenSampleFiles.splice(index, 1);
    },
    onCancelUploadForSample() {}
  },
  mounted() {
    const vm = this;
    console.log(`update props: ${vm.mcId.toString()} ${vm.isPfaSi.toString()}`);
  }
};
</script>
