import CreateModelCard1 from '../views/Core/ModelCard/CreateModelCard1.vue';
import ModelCardIndex from '../views/Core/ModelCard/ModelCardIndex.vue';
import ModelCardDetail from '../views/Core/ModelCard/ModelCardDetail.vue';
import ModelCardQuery from '../views/Core/ModelCard/ModelCardQuery.vue';
import EditModelCard1 from '../views/Core/ModelCard/EditModelCard1.vue';
import ReversModelCard1 from '../views/Core/ModelCard/ReversModelCard1.vue';
import PanelCalcIndex from '../views/Core/PanelCalc/PanelCalcIndex.vue';
import PanelCalcData from '../views/Core/PanelCalc/CalcData.vue';
import MeasurementAndFitting from '../views/Core/IVComparison/MeasurementAndFitting/MeasurementAndFitting.vue';
import MeasurementAndFittingDetail from '../views/Core/IVComparison/MeasurementAndFitting/MeasurementAndFittingDetail.vue';
import GoldenSampleSelection from '../views/Core/IVComparison/GoldenSampleSelection/GoldenSampleSelection.vue';
import { propsValidator } from '../utils/index';

export default
[
  {
    name: 'ModelCardIndex',
    path: '/ModelCardIndex',
    component: ModelCardIndex,
    meta: {
      title: '上傳 Model Card'
    }
  },
  {
    name: 'CreateModelCard1',
    path: '/ModelCard/CreateModelCard1',
    component: CreateModelCard1,
    meta: {
      title: '新增 Model Card'
    }
  },
  {
    name: 'ModelCardQuery',
    path: '/ModelCard/ModelCardQuery',
    component: ModelCardQuery,
    meta: {
      title: '下載 Model Card'
    }
  },
  {
    name: 'ModelCardDetail',
    path: '/ModelCard/ModelCardDetail/:mcId',
    component: ModelCardDetail,
    meta: {
      title: 'Model Card Detail'
    },
    props(route) {
      return propsValidator(route, ModelCardDetail);
    }
  },
  {
    name: 'EditModelCard1',
    path: '/ModelCard/EditModelCard/:mcId',
    component: EditModelCard1,
    meta: {
      title: 'Model Card 修改'
    },
    props(route) {
      return propsValidator(route, EditModelCard1);
    }
  },
  {
    name: 'ReversModelCard1',
    path: '/ModelCard/ReversModelCard1/:mcId/:isPfaSi?/:isLed?/:isLtps?',
    component: ReversModelCard1,
    meta: {
      title: 'Model Card 改版'
    },
    props(route) {
      return propsValidator(route, ReversModelCard1);
    }
  },
  {
    name: 'PanelCalcIndex',
    path: '/PanelCalc/PanelCalcIndex',
    component: PanelCalcIndex,
    meta: {
      title: '面板規格計算器'
    }
  },
  {
    name: 'PanelCalcData',
    path: '/PanelCalc/CalcData',
    component: PanelCalcData,
    meta: {
      title: '面板規格計算'
    },
    props(route) {
      return propsValidator(route, PanelCalcData);
    }
  },
  {
    name: 'MeasurementAndFitting',
    path: '/IVComparison/MeasurementAndFitting',
    component: MeasurementAndFitting,
    meta: {
      title: 'Measurement & Fitting'
    }
  },
  {
    name: 'MeasurementAndFittingDetail',
    path: '/IVComparison/MeasurementAndFittingDetail/:mcId/:sheetType',
    component: MeasurementAndFittingDetail,
    meta: {
      title: 'Measurement & Fitting Detail'
    },
    props(route) {
      return propsValidator(route, MeasurementAndFittingDetail);
    }
  },
  {
    name: 'GoldenSampleSelection',
    path: '/IVComparison/GoldenSampleSelection',
    component: GoldenSampleSelection,
    meta: {
      title: 'Golden Sample Selection'
    }
  }
];
