import Swal from 'sweetalert2';

export const BE_SERVICE_URL = process.env.VUE_APP_BE_SERVICE_URL;
export default BE_SERVICE_URL;

export const RDAUTHORITY_SERVICE_URL = process.env.VUE_APP_RDAUTHORITY_SERVICE_URL;
export const RDAUTHORITY_SYSTEM_ID = process.env.VUE_APP_RDAUTHORITY_SYSTEM_ID;

export const GUIDE_SERVICE_URL = process.env.VUE_APP_GUIDE_SERVICE_URL;

// img path
const pathHost = `${window.location.protocol}//${window.location.hostname}`;
const pathDir = window.location.pathname;
let pathImg = '';
if (pathDir.indexOf('/', 1) > 0) {
  pathImg = `${pathHost + pathDir.substring(0, pathDir.indexOf('/', 1))}/img/`;
}
export const WEB_IMG_URL = pathImg;

// Alert
const Toast = Swal.mixin({
  position: 'center',
  showConfirmButton: false,
  timer: 1000
});
export const alert = {
  error(message) {
    return Toast.fire({
      icon: 'error',
      title: message,
      timer: 0
    });
  },
  warning(message) {
    return Toast.fire({
      icon: 'warning',
      title: message
    });
  },
  info(message) {
    return Toast.fire({
      icon: 'info',
      title: message
    });
  },
  question(message) {
    return Toast.fire({
      icon: 'question',
      title: message
    });
  },
  success(message) {
    return Toast.fire({
      icon: 'success',
      title: message
    });
  }
};

// Confirm
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});
export const confirm = (message) => swalWithBootstrapButtons.fire({
  text: message,
  icon: 'question',
  showCancelButton: true,
  confirmButtonText: '確定',
  cancelButtonText: '取消',
  reverseButtons: true
});
