import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import TW from 'vee-validate/dist/locale/zh_TW.json';
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VTooltip from 'v-tooltip';
import VueUploadComponent from 'vue-upload-component';
import DatePicker from 'vue2-datepicker';
import VueTreeList from 'vue-tree-list';
import Multiselect from 'vue-multiselect';
import vfmPlugin from 'vue-final-modal';
import eventbus from './event/eventBus';
import App from './App.vue';
import Utils from './utils';
import store from './store';
import router from './router';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/third-part/index';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css';
import 'vue2-datepicker/index.css';
import 'vue-step-progress/dist/main.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
// 自訂驗証規則
import './utils/validateRules';

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VTooltip);
Vue.use(VueTreeList);
Vue.use(vfmPlugin);

Utils.init(Vue);

Vue.component('FileUpload', VueUploadComponent);
Vue.config.productionTip = false;

// 載入所有驗證規則
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('zh_TW', TW);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('date-picker', DatePicker);
Vue.component('multiselect', Multiselect);

Vue.use(eventbus);
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
