<template>
  <div>
    <validation-observer ref="formFileInfo">
      <div class="row">
        <table class="table bg-light py-1" v-show="isReadonly">
          <tr class="text-center"><td class="h4">光學檔案</td></tr>
        </table>
        <div class="col-12 table-responsive p-4 mb-0">
          <form>
            <div class="form-group row">
              <label for="modelName" class="col-sm-4 col-form-label text-center"
                >LED Spectrum Data <span class="required">*</span></label
              >
              <div class="col-sm-8">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true }"
                >
                  <div class="form-check form-check-inline mx-2">
                    <input
                      type="radio" id="ledSy" name="inRadioOptledS"
                      value="Y" v-model="formData.ledSpectrumData"
                      :disabled="isReadonly"
                      :class="{ 'form-check-input': true, 'is-invalid': failed }"
                    />
                    <label for="ledSy" class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      type="radio" id="ledSn" name="inRadioOptledS"
                      value="N" v-model="formData.ledSpectrumData"
                      :disabled="isReadonly"
                      :class="{ 'form-check-input': true, 'is-invalid': failed }"
                    />
                    <label for="ledSn" class="form-check-label">No</label>
                  </div>
                  <!-- <select
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    v-model="formData.ledSpectrumData"
                    :disabled="isReadonly"
                  >
                    <option></option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select> -->
                </validation-provider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-center">
                White Light CIEx and CIEy <span class="required">*</span>
              </label>
              <div class="col-sm-8 form-inline">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="whiteLightCIEx"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 140px; max-width: 140px"
                    placeholder="CIEx"
                    v-model="formData.whiteLightCieX"
                    :disabled="isReadonly ||
                      formData.ledSpectrumData === 'N'"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="whiteLightCIEy"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 140px; max-width: 140px"
                    placeholder="CIEy"
                    v-model="formData.whiteLightCieY"
                    :disabled="isReadonly ||
                      formData.ledSpectrumData === 'N'"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-center"
                >Red LED File <span class="required">*</span>
              </label>
              <div class="col-sm-8">
                <div class="input-group">
                  <template>
                    <div class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-info mx-0"
                        @click.prevent="selectLedData('R')"
                        :disabled="isReadonly"
                      >
                        Select
                      </button>
                    </div>
                  </template>
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true }"
                  >
                    <input
                      type="text"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 400px"
                      v-model="formData.ledMcInfoR"
                      disabled
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="ledVoltageR"
                class="col-sm-4 col-form-label text-center"
                >Red LED Voltage <span class="required">*</span>
              </label>
              <div class="col-sm-8 form-inline">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="
                    formData.ledSpectrumData == 'N' ? null : { required: true }
                  "
                >
                  <select
                    id="ledVoltageR"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    v-model="formData.ledVoltageR"
                    :disabled="
                      isReadonly ||
                      !formData.ledMcInfoR ||
                      formData.ledSpectrumData === 'N'
                    "
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in ledVoltageListR"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-center"
                >Green LED File <span class="required">*</span>
              </label>
              <div class="col-sm-8">
                <div class="input-group">
                  <template>
                    <div class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-info mx-0"
                        @click.prevent="selectLedData('G')"
                        :disabled="isReadonly"
                      >
                        Select
                      </button>
                    </div>
                  </template>
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true }"
                  >
                    <input
                      type="text"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 400px"
                      v-model="formData.ledMcInfoG"
                      disabled
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="ledVoltageG"
                class="col-sm-4 col-form-label text-center"
                >Green LED Voltage <span class="required">*</span>
              </label>
              <div class="col-sm-8 form-inline">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="
                    formData.ledSpectrumData == 'N' ? null : { required: true }
                  "
                >
                  <select
                    id="ledVoltageG"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    v-model="formData.ledVoltageG"
                    :disabled="
                      isReadonly ||
                      !formData.ledMcInfoG ||
                      formData.ledSpectrumData === 'N'
                    "
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in ledVoltageListG"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label text-center"
                >Blue LED File <span class="required">*</span>
              </label>
              <div class="col-sm-8">
                <div class="input-group">
                  <template>
                    <div class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-info mx-0"
                        @click.prevent="selectLedData('B')"
                        :disabled="isReadonly"
                      >
                        Select
                      </button>
                    </div>
                  </template>
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true }"
                  >
                    <input
                      type="text"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 400px"
                      v-model="formData.ledMcInfoB"
                      disabled
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="ledVoltageB"
                class="col-sm-4 col-form-label text-center"
                >Blue LED Voltage <span class="required">*</span>
              </label>
              <div class="col-sm-8 form-inline">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="
                    formData.ledSpectrumData == 'N' ? null : { required: true }
                  "
                >
                  <select
                    id="ledVoltageB"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    v-model="formData.ledVoltageB"
                    :disabled="
                      isReadonly ||
                      !formData.ledMcInfoB ||
                      formData.ledSpectrumData === 'N'
                    "
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in ledVoltageListB"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row">
              <label for="modelName" class="col-sm-4 col-form-label text-center"
                >Color Filter Template <span class="required">*</span></label
              >
              <div class="col-sm-8">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true }"
                >
                  <div class="form-check form-check-inline mx-2">
                    <input
                      type="radio" id="Cft0" name="inRadioOptCft"
                      value="999" v-model="formData.colorFilterTemplate"
                      :disabled="isReadonly || formData.ledSpectrumData === 'N'"
                      :class="{ 'form-check-input': true, 'is-invalid': failed }"
                    />
                    <label for="Cft0" class="form-check-label">No</label>
                  </div>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      type="radio" id="Cft1" name="inRadioOptCft"
                      value="1" v-model="formData.colorFilterTemplate"
                      :disabled="isReadonly || formData.ledSpectrumData === 'N'"
                      checked
                      :class="{ 'form-check-input': true, 'is-invalid': failed }"
                    />
                    <label for="Cft1" class="form-check-label">Template1</label>
                  </div>
                  <!-- <select
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    v-model="formData.colorFilterTemplate"
                    :disabled="isReadonly"
                  >
                    <option value="0">No</option>
                    <option value="1">Template1</option>
                  </select> -->
                </validation-provider>
              </div>
            </div>
            <div class="row my-1">
              <div class="col-12 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-info"
                  @click.prevent="plotCurrentYield()"
                  :disabled="
                    !formData.ledMcInfoR ||
                    !formData.ledMcInfoG ||
                    !formData.ledMcInfoB
                  "
                >
                  <i class="fas fa-chart-line"></i> Plot Current vs Yield
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  @click.prevent="plotColorFilterSpectrum()"
                  :disabled="formData.colorFilterTemplate === 0"
                >
                  <i class="fas fa-chart-line"></i> Plot Color Filter Spectrum
                </button>
              </div>
            </div>
            <div class="row my-1">
              <div class="col-12 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-info"
                  @click.prevent="plotLedSpectrum()"
                  :disabled="
                    !formData.ledMcInfoR ||
                    !formData.ledMcInfoG ||
                    !formData.ledMcInfoB ||
                    formData.ledSpectrumData != 'Y'
                  "
                >
                  <i class="fas fa-chart-line"></i> Plot LED Spectrum
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  @click.prevent="plotLedWithColorFilterSpectrum()"
                  :disabled="
                    !formData.ledMcInfoR ||
                    !formData.ledMcInfoG ||
                    !formData.ledMcInfoB ||
                    formData.ledSpectrumData != 'Y' ||
                    formData.colorFilterTemplate === 0
                  "
                >
                  <i class="fas fa-chart-line"></i> Plot LED with Color Filter
                  Spectrum
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- Modal -->
        <vue-final-modal
          name="modal_select_led_data"
          v-model="showModal_selectLedData"
          :lock-scroll="false"
          :click-to-close="false"
          :esc-to-close="true"
          :z-index-auto="true"
          classes="modal-container"
          content-class="modal-content dark-mode"
        >
          <div class="modal-header">
            <h5>Select LED Data</h5>
            <button type="button" class="close" @click="cancelLedData()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="showModal_selectLedData" class="row">
              <div class="col-12">
                <select-led-data
                  :colorName="selectColor"
                  @selected="onSelectedLedData"
                ></select-led-data>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click="cancelLedData()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmLedData()"
            >
              Confirm
            </button>
          </div>
        </vue-final-modal>
        <!-- Modal -->
        <vue-final-modal
          name="modal_plot"
          v-model="showModal_plot"
          :lock-scroll="false"
          :click-to-close="false"
          :esc-to-close="true"
          :z-index-auto="true"
          classes="modal-container"
          content-class="modal-content dark-mode"
        >
          <div class="modal-header">
            <button type="button" class="close" @click="showModal_plot = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <template v-if="showModal_plot">
              <component
                :is="plotComponent"
                :chartType="chartType"
                v-model="chartData"
              ></component>
            </template>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click="showModal_plot = false"
            >
              Close
            </button>
          </div>
        </vue-final-modal>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import SelectLedData from '@/views/Core/PanelCalc/SelectLedData.vue';
import CurrentYieldChart from '@/views/Core/PanelCalc/Chart/CurrentYieldChart.vue';
import SpectrumChart from '@/views/Core/PanelCalc/Chart/SpectrumChart.vue';
import { PanelCalcApiService } from '@/utils/apiService';

export default {
  components: {
    SelectLedData,
    CurrentYieldChart,
    SpectrumChart
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    isReadonly: { type: Boolean, default: false }
  },
  data() {
    return {
      showModal_selectLedData: false,
      showModal_plot: false,
      plotComponent: '',
      selectColor: null,
      selectedLedData: {},
      chartType: '',
      chartData: {
        currentYieldR: [],
        currentYieldG: [],
        currentYieldB: [],
        colorFilterSpectrum: [],
        ledSpectrumR: [],
        ledSpectrumG: [],
        ledSpectrumB: [],
        ledWithColorFilterSpectrumR: [],
        ledWithColorFilterSpectrumG: [],
        ledWithColorFilterSpectrumB: []
      },
      ledVoltageListR: [],
      ledVoltageListG: [],
      ledVoltageListB: []
    };
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    'formData.ledSpectrumData': function (val) {
      const vm = this;
      if (val === 'N') {
        vm.formData.ledVoltageR = null;
        vm.formData.ledVoltageG = null;
        vm.formData.ledVoltageB = null;
      } else if (val === 'Y') {
        if (vm.formData.ledMcIdR) {
          vm.getLedVoltageList(vm.formData.ledMcIdR).then((result) => {
            vm.ledVoltageListR = result;
          });
        }
        if (vm.formData.ledMcIdG) {
          vm.getLedVoltageList(vm.formData.ledMcIdG).then((result) => {
            vm.ledVoltageListG = result;
          });
        }
        if (vm.formData.ledMcIdB) {
          vm.getLedVoltageList(vm.formData.ledMcIdB).then((result) => {
            vm.ledVoltageListB = result;
          });
        }
      }
    }
  },
  methods: {
    getLedWithColorFilterSpectrum(
      colorFilterTemplate,
      colorName,
      mcId,
      voltage
    ) {
      if (!colorFilterTemplate || !colorName || !mcId || !voltage) {
        return [];
      }

      const params = {
        colorFilterTemplate,
        colorName,
        mcId,
        voltage
      };
      return PanelCalcApiService.getLedWithColorFilterSpectrum(params).then(
        (response) => {
          const result = response.data;
          return result.data;
        }
      );
    },
    getLedSpectrum(mcId, voltage) {
      if (!mcId || !voltage) {
        return [];
      }

      const params = {
        mcId,
        voltage
      };
      return PanelCalcApiService.getLedSpectrum(params).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    getColorFilterSpectrum(colorFilterTemplate) {
      const params = {
        colorFilterTemplate
      };
      return PanelCalcApiService.getColorFilterSpectrum(params).then(
        (response) => {
          const result = response.data;
          return result.data;
        }
      );
    },
    getOpticalDataList(mcId) {
      const params = {
        mcId
      };
      return PanelCalcApiService.getOpticalDataList(params).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    selectLedData(color) {
      const vm = this;
      vm.selectColor = color;
      vm.showModal_selectLedData = true;
    },
    cancelLedData() {
      const vm = this;
      vm.showModal_selectLedData = false;
      vm.selectedLedData = [];
    },
    getLedVoltageList(mcId) {
      const params = {
        mcId
      };
      return PanelCalcApiService.getLedVoltageList(params).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    confirmLedData() {
      const vm = this;
      const showText = `${vm.selectedLedData.mcId}, ${vm.selectedLedData.modelId}`;

      switch (vm.selectColor) {
        case 'R':
          vm.formData.ledMcInfoR = showText;
          vm.formData.ledMcIdR = vm.selectedLedData.mcId;
          // 取得 Voltage 列表
          vm.getLedVoltageList(vm.selectedLedData.mcId).then((result) => {
            vm.ledVoltageListR = result;
          });
          break;
        case 'G':
          vm.formData.ledMcInfoG = showText;
          vm.formData.ledMcIdG = vm.selectedLedData.mcId;
          // 取得 Voltage 列表
          vm.getLedVoltageList(vm.selectedLedData.mcId).then((result) => {
            vm.ledVoltageListG = result;
          });
          break;
        case 'B':
          vm.formData.ledMcInfoB = showText;
          vm.formData.ledMcIdB = vm.selectedLedData.mcId;
          // 取得 Voltage 列表
          vm.getLedVoltageList(vm.selectedLedData.mcId).then((result) => {
            vm.ledVoltageListB = result;
          });
          break;
      }

      vm.showModal_selectLedData = false;
    },
    onSelectedLedData(val) {
      const vm = this;
      vm.selectedLedData = val;
    },
    plotCurrentYield() {
      const vm = this;

      vm.$bus.$emit('setLoading', true);

      Promise.all([
        vm.getOpticalDataList(vm.formData.ledMcIdR),
        vm.getOpticalDataList(vm.formData.ledMcIdG),
        vm.getOpticalDataList(vm.formData.ledMcIdB)
      ]).then((res) => {
        vm.chartData.currentYieldR = res[0];
        vm.chartData.currentYieldG = res[1];
        vm.chartData.currentYieldB = res[2];

        vm.plotComponent = 'CurrentYieldChart';
        vm.chartType = 'CurrentYield';
        vm.showModal_plot = true;
        vm.$bus.$emit('setLoading', false);
      });
    },
    plotColorFilterSpectrum() {
      const vm = this;

      vm.getColorFilterSpectrum(vm.formData.colorFilterTemplate).then((res) => {
        vm.chartData.colorFilterSpectrum = res;

        vm.plotComponent = 'SpectrumChart';
        vm.chartType = 'ColorFilterSpectrum';
        vm.showModal_plot = true;
      });
    },
    plotLedSpectrum() {
      const vm = this;

      vm.$bus.$emit('setLoading', true);

      Promise.all([
        vm.getLedSpectrum(vm.formData.ledMcIdR, vm.formData.ledVoltageR),
        vm.getLedSpectrum(vm.formData.ledMcIdG, vm.formData.ledVoltageG),
        vm.getLedSpectrum(vm.formData.ledMcIdB, vm.formData.ledVoltageB)
      ]).then((res) => {
        vm.chartData.ledSpectrumR = res[0];
        vm.chartData.ledSpectrumG = res[1];
        vm.chartData.ledSpectrumB = res[2];

        vm.plotComponent = 'SpectrumChart';
        vm.chartType = 'LedSpectrum';
        vm.showModal_plot = true;
        vm.$bus.$emit('setLoading', false);
      });
    },
    plotLedWithColorFilterSpectrum() {
      const vm = this;

      vm.$bus.$emit('setLoading', true);

      Promise.all([
        vm.getLedWithColorFilterSpectrum(
          vm.formData.colorFilterTemplate,
          'R',
          vm.formData.ledMcIdR,
          vm.formData.ledVoltageR
        ),
        vm.getLedWithColorFilterSpectrum(
          vm.formData.colorFilterTemplate,
          'G',
          vm.formData.ledMcIdG,
          vm.formData.ledVoltageG
        ),
        vm.getLedWithColorFilterSpectrum(
          vm.formData.colorFilterTemplate,
          'B',
          vm.formData.ledMcIdB,
          vm.formData.ledVoltageB
        )
      ]).then((res) => {
        vm.chartData.ledWithColorFilterSpectrumR = res[0];
        vm.chartData.ledWithColorFilterSpectrumG = res[1];
        vm.chartData.ledWithColorFilterSpectrumB = res[2];

        vm.plotComponent = 'SpectrumChart';
        vm.chartType = 'plotLedWithColorFilterSpectrum';
        vm.showModal_plot = true;
        vm.$bus.$emit('setLoading', false);
      });
    }
  },
  beforeDestroy() {}
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 72%;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
