<template>
  <div>
    <div class="card">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>ModelName/pcID</label>
              <input type="text" class="form-control"
                v-model="cond.modelName" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>更新日</label>
              <div>
                <date-picker
                  v-model="cond.lastDateStart"
                  :clearable="false"
                  style="width: 45%"
                  format="YYYY/MM/DD"
                ></date-picker>
                <date-picker
                  v-model="cond.lastDateEnd"
                  :clearable="false"
                  style="width: 45%"
                  format="YYYY/MM/DD"
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>修改者</label>
              <emp-picker v-model="lastEmp"></emp-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Released</label>
              <div>
                <select class="w-100 form-control" v-model="cond.isReleased">
                  <option></option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center pt-0">
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="clearCond()"
        >
          <i class="fas fa-times"></i> 清空
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click.prevent="search(true)"
        >
          <i class="fas fa-search"></i> 查詢
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-info"
          @click.prevent="createCalcData()"
        >
          <i class="fas fa-plus-circle"></i> 新增計算資料
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th>pcID</th>
                  <th>ModelName.V</th>
                  <th>Released</th>
                  <th>PPI</th>
                  <th>R/G/B Current</th>
                  <th>修改者</th>
                  <th style="width: 150px">內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataList" :key="item.panelCalcDataId">
                  <td class="align-middle">{{ item.panelCalcDataId }}</td>
                  <td class="align-middle font-weight-bold">
                    <span :title="item.versionDesc">
                      {{ item.modelName }} . {{ item.version }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="badge badge-success rounded-pill"
                      v-show="item.isReleased && item.isReleased=='Y'"
                      :title="item.releasedDate | formatDate">
                      {{ item.isReleased }}
                    </span>
                    <span class="badge badge-light rounded-pill"
                      v-show="!item.isReleased || item.isReleased!='Y'">N</span>
                  </td>
                  <td class="align-middle">{{ item.pixelPerInch }}</td>
                  <td class="align-middle">
                    {{ item.ledCurrentR }}/{{ item.ledCurrentG }}/{{ item.ledCurrentB }}
                  </td>
                  <td class="align-middle small">
                    {{ item.lastUser + " / " + item.lastUserName }}<br />
                    {{ item.lastTime | formatDateTime }}
                  </td>
                  <td class="align-middle" style="width: 350px; max-width: 350px">
                    <div>
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        @click.prevent="viewCalcData(item)"
                      >
                        <i class="fas fa-file"></i> View
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary ml-1"
                        @click.prevent="editCalcData(item)"
                        :disabled="!isOwner(item) || item.isReleased === 'Y'"
                      >
                        <i class="fas fa-pencil-alt"></i> Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary ml-1"
                        @click.prevent="deleteCalcData(index, item)"
                        :disabled="!isOwner(item)"
                      >
                        <i class="fas fa-trash"></i> Delete
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary ml-1"
                        @click.prevent="release(item)"
                        :disabled="!isOwner(item) || item.isReleased === 'Y'"
                      >
                        <i class="fas fa-rocket"></i> Release
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :totalRecords="totalRecords"
      @pageChangeEvent="pageChange"
      ref="pagin"
    >
    </pagination>

    <!-- Modal -->
    <vue-final-modal
      name="modal_release"
      v-model="showModal_release"
      :lock-scroll="false"
      :click-to-close="false"
      :esc-to-close="false"
      :z-index-auto="true"
      classes="modal-container"
      content-class="modal-content dark-mode"
    >
      <div class="modal-header">
        <h5>Release</h5>
        <button type="button" class="close" @click="showModal_release = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <validation-observer ref="formModalRelease">
          <form>
            <div class="form-group row">
              <label
                for="releaseModelName"
                class="col-sm-4 col-form-label text-center"
                >ModelName <span class="required">*</span></label
              >
              <div class="col-sm-8 form-inline">
                <validation-provider
                  name="Model Name"
                  v-slot="{ failed, failedRules }"
                  :rules="{ required: true, length: 9 }"
                >
                  <input
                    id="releaseModelName"
                    type="text"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    placeholder="Name"
                    v-model="releaseItem.modelName"
                  />
                  <span v-if="failedRules['length']" class="mx-2 text-danger">{{
                    failedRules["length"]
                  }}</span>
                </validation-provider>
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="releaseVersion"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 100px"
                    placeholder="Version"
                    v-model="releaseItem.version"
                  />
                </validation-provider>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showModal_release = false"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="confirmRelease()">
          Confirm
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import EmpPicker from '@/components/EmpPicker.vue';
import { PanelCalcApiService } from '@/utils/apiService';
import moment from 'moment';

export default {
  components: {
    Pagination,
    EmpPicker
  },
  data() {
    return {
      showModal_release: false,
      totalRecords: 0,
      cond: {
        pageSize: 10,
        pageNum: 1,
        panelCalcDataId: null,
        lastDateStart: null,
        lastTimeStart: null,
        lastTimeEnd: null,
        lastDateEnd: null,
        modelName: null,
        lastUser: null,
        isReleased: null
      },
      lastEmp: {},
      dataList: [],
      releaseItem: {
        panelCalcDataId: null,
        modelName: '',
        version: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  methods: {
    isOwner(item) {
      const vm = this;
      return item.lastUser === vm.userInfo.userId;
    },
    createCalcData() {
      const vm = this;
      vm.$router.push({
        name: 'PanelCalcData',
        params: {
          mode: 'create'
        }
      });
    },
    viewCalcData(item) {
      const vm = this;
      vm.$router.push({
        name: 'PanelCalcData',
        params: {
          mode: 'view',
          panelCalcDataId: item.panelCalcDataId
        }
      });
    },
    editCalcData(item) {
      const vm = this;
      vm.$router.push({
        name: 'PanelCalcData',
        params: {
          mode: 'edit',
          panelCalcDataId: item.panelCalcDataId
        }
      });
    },
    deleteCalcData(index, item) {
      const vm = this;
      vm.$confirm('是否確定要删除?').then((confirmResult) => {
        if (confirmResult.value) {
          const params = {
            panelCalcDataId: item.panelCalcDataId,
            userId: vm.userInfo.userId
          };

          PanelCalcApiService.deletePanelCalcData(params).then((response) => {
            const res = response.data;
            if (res.success) {
              vm.dataList.splice(index, 1);
            }
          });
        }
      });
    },
    release(item) {
      const vm = this;
      vm.showModal_release = true;
      vm.releaseItem.panelCalcDataId = item.panelCalcDataId;
      vm.releaseItem.modelName = item.modelName;
      vm.releaseItem.version = item.version;
    },
    confirmRelease() {
      const vm = this;

      vm.$refs.formModalRelease.validate().then((validateResult) => {
        if (!validateResult) {
          vm.$alert.warning('請檢查填寫資料是否完整');
        } else {
          const params = {
            userId: vm.userInfo.userId,
            data: vm.releaseItem
          };
          PanelCalcApiService.release(params).then((response) => {
            const result = response.data;
            if (result.success) {
              vm.$alert.success('Release 完成');
              vm.showModal_release = false;
              vm.search(false);
            } else {
              vm.$alert.error(result.message);
            }
          });
        }
      });
    },
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 10,
        pageNum: 1
      };

      vm.lastEmp = null;
    },
    search(isNewCondition) {
      const vm = this;

      if (vm.lastEmp && vm.lastEmp.empNo) {
        vm.cond.lastUser = vm.lastEmp.empNo;
      }

      if (vm.cond.lastDateStart) {
        vm.cond.lastTimeStart = moment(vm.cond.lastDateStart).format(
          'YYYY/MM/DD'
        );
      }
      if (vm.cond.lastDateEnd) {
        vm.cond.lastTimeEnd = moment(vm.cond.lastDateEnd).format('YYYY/MM/DD');
      }

      if (isNewCondition) {
        vm.cond.pageNum = 1;
        vm.$refs.pagin.resetPage();
      }

      vm.dataList = []; // clear
      PanelCalcApiService.getPanelCalcDataList(vm.cond).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.dataList = result.data.items;
          vm.totalRecords = result.data.totalRecords;
        } else {
          vm.$alert.error(result.message);
        }
      });
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    }
  },
  mounted() {
    const vm = this;

    vm.$bus.$emit('setLoading', true);

    Promise.all([vm.search()]).then(() => {
      vm.$bus.$emit('setLoading', false);
    });
  },
  beforeDestroy() {}
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 40%;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
