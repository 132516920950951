<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-6">
            <step-progress
              :steps="stepList"
              :current-step="currStep"
              icon-class="fas fa-check"
              :line-thickness="6"
              activeColor="lightskyblue"
              passiveColor="lightgray"
            >
            </step-progress>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-show="currStep === 0">
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <div class="card-body">
          <div class="row" v-if="uploadPermission === 'Y'">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="class1"
                  >Gen Unit / 元件種類<span class="required">*</span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button
                      class="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      {{ rdGenUnit === 10 ? "ARC" : "Platform" }}
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        href="#"
                        v-if="isRDArc === 'Y'"
                        v-on:click.prevent="changeRdUnit(10)">ARC
                      </a>
                      <a
                        class="dropdown-item"
                        href="#"
                        v-if="isRDPlatform === 'Y'"
                        v-on:click.prevent="changeRdUnit(20)">Platform
                      </a>
                    </div>
                  </div>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-50">
                    <select
                      id="class1"
                      v-model="formData.deviceCategory"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <!-- <option :value="60" v-if="rdGenUnit === 20">TFT</option> -->
                      <option
                        v-for="(item, index) in optClass1"
                        v-bind:value="item.categoryId"
                        :key="index"
                      >
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div
              v-if="formData.deviceCategory === 30 || formData.deviceCategory === 60"
              class="col-md-3 col-sm-6 col-12"
            >
              <div class="form-group">
                <label for="deviceActiveLayer"
                  >元件主動層
                  <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select
                    id="deviceActiveLayer"
                    v-model="formData.deviceActiveLayer"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                  >
                    <option value=""></option>
                    <!-- <option :value="170" v-if="formData.deviceCategory===60">a-Si</option> -->
                    <option
                      v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId"
                      :key="index"
                    >
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <div v-if="isLed" class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="ledColor"
                  >LED Color <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select
                    id="ledColor"
                    v-model="formData.ledColor"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId"
                      :key="index"
                    >
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <div v-if="isOled" class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="oledProcess"
                  >OLED Process <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select
                    id="oledProcess"
                    v-model="formData.oledProcess"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId"
                      :key="index"
                    >
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <div class="col">
              <span class="float-right">
                <a :href="urlWebImg+'DG_Template-202205.pdf'"
                  class="badge bg-secondary mx-1"
                  target="_self" title="DG Template">DG Template
                </a>
              </span>
            </div>
          </div>

          <div class="row">
            <template v-if="isPfaSi" id="platform_ftf_asi">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName"
                    >Fab Name <span class="required">*</span></label
                  >
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="fabName"
                      v-model="formData.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="pep">PEP <span class="required">*</span></label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="pep"
                      v-model="formData.pep"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optPep"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="cfStracture"
                    >CF Structure <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="cfStracture"
                      v-model="formData.cfStracture"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optCfStracture"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="m1M2"
                    >M1/M2 <span class="required">*</span></label
                  >
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="m1M2"
                      v-model="formData.m1M2"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optM1M2"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftQualityRecipe"
                    >元件膜質 <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required|max:100"
                      class="w-100"
                    >
                      <input
                        id="tftQualityRecipe"
                        type="text"
                        v-model="formData.tftQualityRecipe"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelMetalLen"
                    >TFT Channel Metal Length
                    <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input
                        id="tftChannelMetalLen"
                        type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftChannelMetalLen"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="tftChannelMetalLen"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftAsRemain"
                    >TFT AS Remain <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required"
                      class="w-100"
                    >
                      <input
                        id="tftAsRemain"
                        type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftAsRemain"
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelNPlusLen"
                    >TFT Channel N-plus Length
                  </label>
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules=""
                      class="w-100"
                    >
                      <input
                        id="tftChannelNPlusLen"
                        type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftChannelNPlusLen"
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
            </template>

            <template id="arc_platform_tft"
              v-if="
                formData.deviceCategory === 30 ||
                formData.deviceActiveLayer === 180 ||
                formData.deviceActiveLayer === 190
              "
            >
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="modelId"
                    >TFT Model ID <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required|max:20"
                      class="w-100"
                    >
                      <input
                        id="modelId"
                        type="text"
                        v-model="formData.modelId"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <template
                id="arc_platform_tft_ltps"
                v-if="
                  formData.deviceActiveLayer === 90 ||
                  formData.deviceActiveLayer === 180
                "
              >
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="polarSmc">
                      Polar Semiconductor <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="polarSmc"
                          v-model="formData.polarSmc"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optPolarSmc"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="gateNumber">
                      Gate Number <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateNumber"
                          v-model="formData.gateNumber"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optGateNumber"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.gateNumber == 1"
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="gateSymmetry1s">
                      Gate Symmetry <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateSymmetry1s"
                          v-model="formData.gateSymmetry1s"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateSymmetry1s"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.gateNumber == 2"
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="gateArrangement">
                      Gate Arrangement <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateArrangement"
                          v-model="formData.gateArrangement"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateArrangement"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    formData.gateNumber == 2 && formData.gateArrangement == '1'
                  "
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="gateSymmetry2dh">
                      Gate Symmetry <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateSymmetry2dh"
                          v-model="formData.gateSymmetry2dh"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateSymmetry2dh"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    formData.gateNumber == 2 && formData.gateArrangement == 1
                  "
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="m15">
                      M1.5 <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="m15"
                          v-model="formData.m15"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    formData.gateNumber == 2 && formData.gateArrangement == 2
                  "
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="optGateSymmetry2dv">
                      Gate Symmetry <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="optGateSymmetry2dv"
                          v-model="formData.gateSymmetry2dv"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateSymmetry2dv"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    formData.gateNumber == 2 && formData.gateArrangement == 2
                  "
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="bottomGateInsulator">
                      Bottom Gate Insulator <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="bottomGateInsulator"
                          v-model="formData.bottomGateInsulator"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.gateNumber == 3"
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="gateSymmetry3t">
                      Gate Symmetry <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateSymmetry3t"
                          v-model="formData.gateSymmetry3t"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateSymmetry3t"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.gateNumber == 3"
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="bottomGateInsulator">
                      Bottom Gate Insulator <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="bottomGateInsulator"
                          v-model="formData.bottomGateInsulator"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="channelDoping">
                      Channel Doping <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="channelDoping"
                          v-model="formData.channelDoping"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="lightlyDopedDrain">
                      Lightly Doped Drain <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="lightlyDopedDrain"
                          v-model="formData.lightlyDopedDrain"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optLightlyDopedDrain"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </template>

              <template
                id="arc_tft_asi"
                v-if="formData.deviceActiveLayer === 100"
              >
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="gateSymmetry1s">
                      Gate Symmetry <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="gateSymmetry1s"
                          v-model="formData.gateSymmetry1s"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optGateSymmetry1s"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </template>

              <template
                id="arc_platform_tft_igzo"
                v-if="
                  formData.deviceActiveLayer === 110 ||
                  formData.deviceActiveLayer === 190
                "
              >
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="igzoGateStructure">
                      IGZO Gate Structure <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="igzoGateStructure"
                          v-model="formData.igzoGateStructure"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optIgzoGateStructure"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="hybridOxideTftIgzo">
                      Hybrid Oxide TFT IGZO <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="hybridOxideTftIgzo"
                          v-model="formData.hybridOxideTftIgzo"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.hybridOxideTftIgzo == 1"
                  class="col-md-3 col-sm-6 col-12"
                >
                  <div class="form-group">
                    <label for="hybridOxideTftIgzoSdUm">
                      Hybrid Oxide TFT IGZO in SD region
                      <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="hybridOxideTftIgzoSdUm"
                          v-model="formData.hybridOxideTftIgzoSdUm"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                          :disabled="formData.hybridOxideTftIgzo !== '1'"
                        >
                          <option value=""></option>
                          <option
                            v-for="(
                              item, index
                            ) in selectOptions.optHybridOxideTftIgzoSdUm"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="pl1Design">
                      PL1 Design <span class="required">*</span>
                    </label>
                    <div class="input-group">
                      <validation-provider
                        v-slot="{ failed }"
                        rules="required"
                        class="w-100"
                      >
                        <select
                          id="pl1Design"
                          v-model="formData.pl1Design"
                          :class="{
                            'form-control': true,
                            'is-invalid': failed,
                          }"
                        >
                          <option value=""></option>
                          <option
                            v-for="(item, index) in selectOptions.optYesNo"
                            v-bind:value="item.value"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </template>

              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName"
                    >Fab Name <span class="required">*</span></label
                  >
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="fabName"
                      v-model="formData.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="substrate"
                    >Substrate <span class="required">*</span></label
                  >
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="substrate"
                      v-model="formData.substrate"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optSubstrate"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tegChannelWidth">
                    TEG Channel Width <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input
                        id="tegChannelWidth"
                        type="number"
                        v-model="formData.tegChannelWidth"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="tegChannelWidth"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tegChannelLen1">
                    TEG Channel Length 1/2 <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input
                        id="tegChannelLen1"
                        type="number"
                        v-model="formData.tegChannelLen1"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <input
                        id="tegChannelLen2"
                        type="number"
                        v-model="formData.tegChannelLen2"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="tegChannelLen1"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="deviceStressTest">
                    Device Stress Test <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="deviceStressTest"
                      v-model="formData.deviceStressTest"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="cvMeasurement">
                    CV Measurement <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="cvMeasurement"
                      v-model="formData.cvMeasurement"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ivMeasuringInstrument">
                    IV Measuring Instrument Model Name
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:20"
                    class="w-100"
                  >
                    <input
                      id="ivMeasuringInstrument"
                      type="text"
                      v-model="formData.ivMeasuringInstrument"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="formData.cvMeasurement == 1"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="cvMeasuringInstrument">
                    CV Measuring Instrument Model Name
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:20"
                    class="w-100"
                  >
                    <input
                      id="cvMeasuringInstrument"
                      type="text"
                      v-model="formData.cvMeasuringInstrument"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="gateInsulatorMaterialsThickness">
                    Gate Insulator Materials and Thickness
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:30"
                    class="w-100"
                  >
                    <input
                      id="gateInsulatorMaterialsThickness"
                      type="text"
                      v-model="formData.gateInsulatorMaterialsThickness"
                      placeholder="例:SiOx/SiNx=900A/200A"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="gateMaterials">
                    Gate Materials <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <select
                      id="gateMaterials"
                      v-model="formData.gateMaterials"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optMetalMaterials2"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="sourceDrainMaterials">
                    Source/Drain Materials <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <select
                      id="sourceDrainMaterials"
                      v-model="formData.sourceDrainMaterials"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optMetalMaterials2"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="powerLineMaterials">
                    Power Line Materials <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:20"
                    class="w-100"
                  >
                    <input
                      id="powerLineMaterials"
                      type="text"
                      v-model="formData.powerLineMaterials"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="drainVoltageIdvg">
                    Drain Voltage of IDVG <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:50"
                    class="w-100"
                  >
                    <input
                      id="drainVoltageIdvg"
                      type="text"
                      v-model="formData.drainVoltageIdvg"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
            </template>

            <template v-if="isLed" id="arc_platform_led">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="modelId"
                    >LED Model ID <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required|max:20"
                      class="w-100"
                    >
                      <input
                        id="modelId"
                        type="text"
                        v-model="formData.modelId"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledVendor"
                    >LED Vendor <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="ledVendor"
                      v-model="formData.ledVendor"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optLedVendor"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledChipStructure">
                    LED Chip Structure <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="ledChipStructure"
                      v-model="formData.ledChipStructure"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optLedChipStructure"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtMaxLuminEfficiency">
                    LED_MT Maximum Luminance Efficiency
                    <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledMtMaxLuminEfficiency"
                        type="number"
                        v-model="formData.ledMtMaxLuminEfficiency"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label
                      class="input-group-text"
                      for="ledMtMaxLuminEfficiency"
                      >cd/A</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtCurrentAtMaxLumin">
                    LED_MT Current at Maximum Luminance Efficiency
                    <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledMtCurrentAtMaxLumin"
                        type="number"
                        v-model="formData.ledMtCurrentAtMaxLumin"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledMtCurrentAtMaxLumin"
                      >uA</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledLength" title="LED 長邊">
                    LED Length <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input
                        id="ledLength"
                        type="number"
                        v-model="formData.ledLength"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledLength">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledWidth" title="LED 短邊">
                    LED Width <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input
                        id="ledWidth"
                        type="number"
                        v-model="formData.ledWidth"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledWidth">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledThickness">
                    LED Thickness <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledThickness"
                        type="number"
                        v-model="formData.ledThickness"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledThickness"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="deviceStressTest">
                    Device Stress Test <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="deviceStressTest"
                      v-model="formData.deviceStressTest"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="cvMeasurement">
                    CV Measurement <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="cvMeasurement"
                      v-model="formData.cvMeasurement"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ivMeasuringInstrument">
                    IV Measuring Instrument Model Name
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:20"
                    class="w-100"
                  >
                    <input
                      id="ivMeasuringInstrument"
                      type="text"
                      v-model="formData.ivMeasuringInstrument"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="formData.cvMeasurement == 1"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="cvMeasuringInstrument">
                    CV Measuring Instrument Model Name
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:20"
                    class="w-100"
                  >
                    <input
                      id="cvMeasuringInstrument"
                      type="text"
                      v-model="formData.cvMeasuringInstrument"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtParallelNumber">
                    LED_MT Parallel Number <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <input
                      id="ledMtParallelNumber"
                      type="number"
                      v-model="formData.ledMtParallelNumber"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtPpi">
                    LED_MT Chip Pixel Per Inch <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <input
                      id="ledMtPpi"
                      type="number"
                      v-model="formData.ledMtPpi"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledLeadAlloy">
                    LED Lead Alloy <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:20"
                    class="w-100"
                  >
                    <input
                      id="ledLeadAlloy"
                      type="text"
                      v-model="formData.ledLeadAlloy"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="massTransferTechniques">
                    Mass Transfer Techniques <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:30"
                    class="w-100"
                  >
                    <input
                      id="massTransferTechniques"
                      type="text"
                      v-model="formData.massTransferTechniques"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="colorConversion">
                    Color Conversion <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <select
                      id="colorConversion"
                      v-model="formData.colorConversion"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="colorConversionMaterials">
                    Color Conversion Materials <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:50"
                    class="w-100"
                  >
                    <input
                      id="colorConversionMaterials"
                      type="text"
                      v-model="formData.colorConversionMaterials"
                      :disabled="
                        !formData.colorConversion ||
                        formData.colorConversion !== '1'
                      "
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="colorConversionMaterialsVendor">
                    Color Conversion Materials Vendor
                    <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:50"
                    class="w-100"
                  >
                    <input
                      id="colorConversionMaterialsVendor"
                      type="text"
                      v-model="formData.colorConversionMaterialsVendor"
                      :disabled="
                        !formData.colorConversion ||
                        formData.colorConversion !== '1'
                      "
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledManufactureYear">
                    LED Manufacture Year <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <input
                      id="ledManufactureYear"
                      type="number"
                      v-model="formData.ledManufactureYear"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtDominantWaveLength">
                    LED_MT Dominant Wave Length <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <input
                      id="ledMtDominantWaveLength"
                      type="number"
                      v-model="formData.ledMtDominantWaveLength"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtPeakWaveLength">
                    LED_MT Peak Wave Length <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <input
                      id="ledMtPeakWaveLength"
                      type="number"
                      v-model="formData.ledMtPeakWaveLength"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledMtCie1931ColorCoordinatesX">
                    LED_MT CIE 1931 Color Coordinates
                    <span class="required"></span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules=""
                    class="w-100"
                  >
                    <div class="input-group">
                      <input
                        id="ledMtCie1931ColorCoordinatesX"
                        type="number"
                        v-model="formData.ledMtCie1931ColorCoordinatesX"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <input
                        id="ledMtCie1931ColorCoordinatesY"
                        type="number"
                        v-model="formData.ledMtCie1931ColorCoordinatesY"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </div>
                  </validation-provider>
                </div>
              </div>
            </template>

            <template v-if="isOled" id="arc_oled">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="modelId"
                    >OLED Model ID <span class="required">*</span></label
                  >
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required|max:20"
                      class="w-100"
                    >
                      <input
                        id="modelId"
                        type="text"
                        v-model="formData.modelId"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                      <span class="text-danger">{{ failed?'空白/超長?':'' }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div
                v-if="formData.oledProcess == 150"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="oledColor"
                    >OLED Color <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledColor"
                      v-model="formData.oledColor"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optOledColor1"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="formData.oledProcess == 160"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="oledColor"
                    >OLED Color <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledColor"
                      v-model="formData.oledColor"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optOledColor2"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledEmittingType">
                    OLED Emitting Type <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledEmittingType"
                      v-model="formData.oledEmittingType"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optOledEmittingType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName"
                    >Fab Name <span class="required">*</span></label
                  >
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="fabName"
                      v-model="formData.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="deviceStressTest">
                    Device Stress Test <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="deviceStressTest"
                      v-model="formData.deviceStressTest"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optYesNo"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="formData.deviceStressTest == 1"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="deviceStressTestDuration">
                    Device Stress Test Duration <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required"
                      class="w-75"
                    >
                      <input
                        id="deviceStressTestDuration"
                        type="number"
                        v-model="formData.deviceStressTestDuration"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label
                      class="input-group-text"
                      for="deviceStressTestDuration"
                      >hr</label
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="formData.deviceStressTest == 1"
                class="col-md-3 col-sm-6 col-12"
              >
                <div class="form-group">
                  <label for="deviceStressTestTemper">
                    Device Stress Test Temperature
                    <span class="required">*</span>
                  </label>
                  <div class="input-group">
                    <validation-provider
                      v-slot="{ failed }"
                      rules="required"
                      class="w-75"
                    >
                      <input
                        id="deviceStressTestTemper"
                        type="number"
                        v-model="formData.deviceStressTestTemper"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="deviceStressTestTemper"
                      >℃</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledCurrentResistance">
                    OLED Current/Resistance Data Type
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledCurrentResistance"
                      v-model="formData.oledCurrentResistance"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optOledCurrentResistance"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledCapacitanceDataType">
                    OLED Capacitance Data Type <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledCapacitanceDataType"
                      v-model="formData.oledCapacitanceDataType"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optOledCapacitanceDataType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledModelCardCapacitanceType">
                    OLED Model Card Capacitance Type
                    <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-100"
                  >
                    <select
                      id="oledModelCardCapacitanceType"
                      v-model="formData.oledModelCardCapacitanceType"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(
                          item, index
                        ) in selectOptions.optOledModelCardCapacitanceType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledModelTool">
                    OLED Model Tool <span class="required">*</span>
                  </label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required|max:150"
                    class="w-100"
                  >
                    <select
                      id="oledModelTool"
                      v-model="formData.oledModelToolList"
                      multiple
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.optOledModelTool"
                        v-bind:value="item.name"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledProcessYear">OLED Process Year</label>
                  <input
                    id="oledProcessYear"
                    type="number"
                    v-model="formData.oledProcessYear"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledEmittingArea">OLED Emitting Area</label>
                  <div class="input-group">
                    <input
                      id="oledEmittingArea"
                      type="number"
                      v-model="formData.oledEmittingArea"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label class="input-group-text" for="oledEmittingArea"
                      >um2</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledAnodeMaterial">OLED Anode Material</label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:20"
                    class="w-100"
                  >
                    <input
                      id="oledAnodeMaterial"
                      type="text"
                      v-model="formData.oledAnodeMaterial"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledCathodeMaterial">OLED Cathode Material</label>
                  <validation-provider
                    v-slot="{ failed }"
                    rules="max:20"
                    class="w-100"
                  >
                    <input
                      id="oledCathodeMaterial"
                      type="text"
                      v-model="formData.oledCathodeMaterial"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <span class="text-danger">{{ failed?'超長?':'' }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledStructure"> OLED Structure </label>
                  <select
                    id="oledStructure"
                    v-model="formData.oledStructure"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                  >
                    <option value=""></option>
                    <option
                      v-for="(item, index) in selectOptions.optOledStructure"
                      v-bind:value="item.value"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledWorkingBrightness"
                    >OLED Working Brightness</label
                  >
                  <div class="input-group">
                    <input
                      id="oledWorkingBrightness"
                      type="number"
                      v-model="formData.oledWorkingBrightness"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label class="input-group-text" for="oledWorkingBrightness"
                      >nits</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledWorkingCurrent">OLED Working Current</label>
                  <div class="input-group">
                    <input
                      id="oledWorkingCurrent"
                      type="number"
                      v-model="formData.oledWorkingCurrent"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label class="input-group-text" for="oledWorkingCurrent"
                      >uA</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledWorkingVoltage">OLED Working Voltage</label>
                  <div class="input-group">
                    <input
                      id="oledWorkingVoltage"
                      type="number"
                      v-model="formData.oledWorkingVoltage"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label class="input-group-text" for="oledWorkingVoltage"
                      >V</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledWorkingLuminanceEfficiency">
                    OLED Working Luminance Efficiency
                  </label>
                  <div class="input-group">
                    <input
                      id="oledWorkingLuminanceEfficiency"
                      type="number"
                      v-model="formData.oledWorkingLuminanceEfficiency"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label
                      class="input-group-text"
                      for="oledWorkingLuminanceEfficiency"
                    >
                      cd/A
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledPeakWaveLength">OLED Peak Wave Length</label>
                  <div class="input-group">
                    <input
                      id="oledPeakWaveLength"
                      type="number"
                      v-model="formData.oledPeakWaveLength"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <label class="input-group-text" for="oledPeakWaveLength"
                      >nm</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="oledCie1931ColorCoordinatesX">
                    OLED CIE 1931 Color Coordinates
                  </label>
                  <div class="input-group">
                    <input
                      id="oledCie1931ColorCoordinatesX"
                      type="number"
                      v-model="formData.oledCie1931ColorCoordinatesX"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                    <input
                      id="oledCie1931ColorCoordinatesY"
                      type="number"
                      v-model="formData.oledCie1931ColorCoordinatesY"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </div>
                </div>
              </div>
            </template>

            <div v-if="!isPfaSi" class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="temperDuringMeasurement">
                  Temperature During Measurement <span class="required">*</span>
                </label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    rules="required"
                    class="w-75"
                  >
                    <input
                      id="temperDuringMeasurement"
                      type="number"
                      v-model="formData.temperDuringMeasurement"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                    />
                  </validation-provider>
                  <label class="input-group-text" for="temperDuringMeasurement"
                    >℃</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="remark">其他備註資訊</label>
                <textarea
                  id="mcNote"
                  class="form-control"
                  rows="5"
                  v-model="formData.mcNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer text-center">
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click.prevent="goBack()"
          >
            <i class="fas fa-times-circle"></i> 取消
          </button>
          <button
            type="button"
            class="btn btn-success"
            v-if="currStep === 0"
            v-on:click.prevent="handleSubmit(checkStep(0))"
          >
            <i class="fas fa-arrow-circle-right"></i> 下一步
          </button>
        </div>
      </validation-observer>
    </div>

    <div class="card" v-show="currStep === 1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <label>Model Card Files <span class="required">*</span></label>
              <ex-file-upload
                ref="modelCardFiles"
                @onUploaded="onUploadedForModelCard"
                @onCancelUpload="onCancelUploadForModelCard"
                @onDeleted="onDeletedForModelCard"
                :fileType="isPfaSi ? 'zip' : 'mod'"
                :isMultiple="false"
              >
              </ex-file-upload>
            </div>
          </div>
          <div v-if="!isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <label>QA Report Files <span class="required">*</span></label>
              <ex-file-upload
                ref="qAReportFiles"
                @onUploaded="onUploadedForQAReport"
                @onCancelUpload="onCancelUploadForQAReport"
                @onDeleted="onDeletedForQAReport"
                fileType="ppt"
                :isMultiple="false"
              >
              </ex-file-upload>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-default"
          v-if="currStep > 0"
          v-on:click.prevent="checkStep(-1)"
        >
          <i class="fas fa-arrow-circle-left"></i> 上一步
        </button>
        <button
          type="button"
          class="btn btn-success"
          v-on:click.prevent="checkStep(currStep)"
        >
          <i class="fas fa-arrow-circle-right"></i> 下一步
        </button>
      </div>
    </div>

    <div class="card" v-show="currStep === 2">
      <div class="card-body">
        <div class="row">
          <div v-if="!isPfaSi" class="col-md-6 col-sm-6 col-12">
            <template v-if="isLed || isLtps">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Measurement Data Files <span class="required">*</span>
                      <a v-if="isLed"
                        :href="urlWebImg+'template_led_measurement-202205.xlsx'"
                        class="badge bg-secondary mx-1"
                        target="_self"
                        title="download template"
                        >template
                      </a>
                      <a v-if="isLtps"
                        :href="urlWebImg+'template_ltps_measurement-202205.xls'"
                        class="badge bg-secondary mx-1"
                        target="_self"
                        title="download template"
                        >template
                      </a>
                    </label>
                    <ex-file-upload
                      ref="measurementDataFiles"
                      @onUploaded="onUploadedForMeasureParsing"
                      @onCancelUpload="onCancelUploadForMeasureParsing"
                      @onDeleted="onDeletedForMeasureParsing"
                      fileType="xls"
                      :isMultiple="false"
                    >
                    </ex-file-upload>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <pre
                      v-if="
                        formData.measurementDataFiles &&
                        formData.measurementDataFiles.length > 0
                      "
                      class="alert alert-primary h4"
                    >{{ formData.measurementDataFiles[0].retMsg.trim() }}</pre
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12">
                  <label>Measurement Data Files <span class="required">*</span>
                  </label>
                  <ex-file-upload
                    ref="measurementDataFiles"
                    @onUploaded="onUploadedForMeasure"
                    @onCancelUpload="onCancelUploadForMeasure"
                    @onDeleted="onDeletedForMeasure"
                    fileType="xls"
                    :isMultiple="false"
                  >
                  </ex-file-upload>
                </div>
              </div>
            </template>
          </div>
          <div v-if="isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label
                    >Measurement Data Files <span class="required">*</span>
                    <a
                      :href="urlWebImg+'template_fitting.xlsx'"
                      class="badge bg-secondary mx-1"
                      target="_self"
                      title="download template"
                      >template</a
                    >
                  </label>
                  <ex-file-upload
                    ref="measurementDataFiles"
                    @onUploaded="onUploadedForFitting"
                    @onCancelUpload="onCancelUploadForFitting"
                    @onDeleted="onDeletedForFitting"
                    fileType="xls"
                    :isMultiple="false"
                  >
                  </ex-file-upload>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <pre
                    v-if="
                      formData.measurementDataFiles &&
                      formData.measurementDataFiles.length > 0
                    "
                    class="alert alert-primary h4"
                  >{{ formData.measurementDataFiles[0].retMsg.trim() }}</pre>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isPfaSi" class="col-md-6 col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label
                    >Golden Sample Selection Files
                    <span class="required">*</span>
                    <a
                      :href="urlWebImg+'template_sample.xlsx'"
                      class="badge bg-secondary mx-1"
                      target="_self"
                      title="download template"
                      >template</a
                    >
                  </label>
                  <ex-file-upload
                    ref="goldenSampleFiles"
                    @onUploaded="onUploadedForSample"
                    @onCancelUpload="onCancelUploadForSample"
                    @onDeleted="onDeletedForSample"
                    fileType="xls"
                    :isMultiple="false"
                  >
                  </ex-file-upload>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <pre
                    v-if="
                      formData.goldenSampleFiles &&
                      formData.goldenSampleFiles.length > 0
                    "
                    class="alert alert-primary h4"
                  >{{ formData.goldenSampleFiles[0].retMsg.trim() }}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-default"
          v-if="currStep > 0"
          v-on:click.prevent="checkStep(-1)"
        >
          <i class="fas fa-arrow-circle-left"></i> 上一步
        </button>
        <button
          type="button"
          class="btn btn-success"
          v-on:click.prevent="save()"
        >
          <i class="fas fa-arrow-circle-right"></i> 確認儲存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import { WEB_IMG_URL } from '@/utils/config';
import StepProgress from 'vue-step-progress';
import ExFileUpload from '@/components/ExFileUpload.vue';
import {
  FileService,
  RawDataApiService,
  ModelCardApiService,
  CodeMappingApiService
} from '@/utils/apiService';

export default {
  components: {
    ExFileUpload,
    'step-progress': StepProgress
  },
  data() {
    return {
      urlWebImg: WEB_IMG_URL,
      stepList: ['Step1.元件資訊', 'Step2.ModelCard檔案', 'Step3.量測檔案'],
      currStep: 0,
      optClass1: [],
      optClass2: [],
      optClass3: [],
      formData: {
        rdGenUnit: null,
        deviceCategory: null,
        deviceActiveLayer: null,
        fabName: null,
        modelCardFiles: [],
        qAReportFiles: [],
        measurementDataFiles: [],
        goldenSampleFiles: [],
        oledModelToolList: [] // 50
      },
      selectOptions: {
        optFabName: [],
        optYesNo: [],
        optPep: [], // 60
        optCfStracture: [], // 60
        optM1M2: [], // 60
        optPolarSmc: [], // 90 | 180
        optGateNumber: [], // 90 | 180
        optGateSymmetry1s: [], // 90 | 180
        optGateSymmetry2dh: [], // 90 | 180
        optGateSymmetry2dv: [], // 90 | 180
        optGateSymmetry3t: [], // 90 | 180
        optGateArrangement: [], // 90 | 180
        optLightlyDopedDrain: [], // 90 | 180
        optIgzoGateStructure: [], // 110|190
        optHybridOxideTftIgzoSdUm: [], // 110|190
        optSubstrate: [], // 30 | 180 | 190
        optMetalMaterials2: [], // 30 | 180 | 190
        optLedColor: [], // 40|70
        optLedVendor: [], // 40|70
        optLedChipStructure: [], // 40|70
        optOledColor1: [], // 50
        optOledColor2: [], // 50
        optOledEmittingType: [], // 50
        optOledCurrentResistance: [], // 50
        optOledCapacitanceDataType: [], // 50
        optOledModelCardCapacitanceType: [], // 50
        optOledStructure: [] // 50
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      uploadPermission: 'getUploadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    }),
    isPfaSi() {
      const vm = this;
      if (!vm.formData) {
        return false;
      }
      return (
        vm.formData.deviceCategory === 60
        && vm.formData.deviceActiveLayer === 170
      );
    },
    isLed() {
      const vm = this;
      if (!vm.formData) {
        return false;
      }
      return (
        vm.formData.deviceCategory === 40 || vm.formData.deviceCategory === 70
      );
    },
    isLtps() {
      const vm = this;
      if (!vm.formData) {
        return false;
      }
      return (
        vm.formData.deviceActiveLayer === 90
        || vm.formData.deviceActiveLayer === 180
      );
    },
    isOled() {
      const vm = this;
      if (!vm.formData) {
        return false;
      }
      return vm.formData.deviceCategory === 50;
    }
  },
  watch: {
    'formData.deviceCategory': function (val) {
      const vm = this;
      vm.optClass2 = [];
      vm.formData.deviceActiveLayer = null;
      if (val) {
        vm.$bus.$emit('setLoading', true);
        vm.getCategoryList(val, 3);
        switch (val) {
          case 30:
            Promise.all([
              vm.getCodeMapping('polar_smc', 1),
              vm.getCodeMapping('gate_number', 1),
              vm.getCodeMapping('gate_symmetry1s', 1),
              vm.getCodeMapping('gate_symmetry2dh', 1),
              vm.getCodeMapping('gate_symmetry2dv', 1),
              vm.getCodeMapping('gate_symmetry3t', 1),
              vm.getCodeMapping('gate_arrangement', 1),
              vm.getCodeMapping('has_ldd', 1),
              vm.getCodeMapping('igzo_gate_structure', 1),
              vm.getCodeMapping('hybrid_oxide_tft_igzo_sd_um', 1),
              vm.getCodeMapping('substrate', 1),
              vm.getCodeMapping('metal_material2', 1)
            ]).then((res) => {
              vm.selectOptions.optPolarSmc = res[0];
              vm.selectOptions.optGateNumber = res[1];
              vm.selectOptions.optGateSymmetry1s = res[2];
              vm.selectOptions.optGateSymmetry2dh = res[3];
              vm.selectOptions.optGateSymmetry2dv = res[4];
              vm.selectOptions.optGateSymmetry3t = res[5];
              vm.selectOptions.optGateArrangement = res[6];
              vm.selectOptions.optLightlyDopedDrain = res[7];
              vm.selectOptions.optIgzoGateStructure = res[8];
              vm.selectOptions.optHybridOxideTftIgzoSdUm = res[9];
              vm.selectOptions.optSubstrate = res[10];
              vm.selectOptions.optMetalMaterials2 = res[11];
            });
            break;

          case 40:
          case 70:
            Promise.all([
              vm.getCodeMapping('led_vendor', 1),
              vm.getCodeMapping('led_chip_structure', 1)
            ]).then((res) => {
              vm.selectOptions.optLedVendor = res[0];
              vm.selectOptions.optLedChipStructure = res[1];
            });
            break;

          case 50:
            Promise.all([
              vm.getCodeMapping('oled_color1', 1),
              vm.getCodeMapping('oled_color2', 1),
              vm.getCodeMapping('oled_emitting_type', 1),
              vm.getCodeMapping('oled_current_resistance', 1),
              vm.getCodeMapping('oled_capacitance_data_type', 1),
              vm.getCodeMapping('oled_model_card_capacitance_type', 1),
              vm.getCodeMapping('oled_model_tool', 1),
              vm.getCodeMapping('oled_structure', 1)
            ]).then((res) => {
              vm.selectOptions.optOledColor1 = res[0];
              vm.selectOptions.optOledColor2 = res[1];
              vm.selectOptions.optOledEmittingType = res[2];
              vm.selectOptions.optOledCurrentResistance = res[3];
              vm.selectOptions.optOledCapacitanceDataType = res[4];
              vm.selectOptions.optOledModelCardCapacitanceType = res[5];
              vm.selectOptions.optOledModelTool = res[6];
              vm.selectOptions.optOledStructure = res[7];
            });
            break;
        }
        vm.$bus.$emit('setLoading', false);
      }
    },
    'formData.deviceActiveLayer': function (val) {
      const vm = this;
      if (val) {
        vm.$bus.$emit('setLoading', true);
        switch (val) {
          case 170: // platform_tft_asi
            Promise.all([
              vm.getCodeMapping('cf_stracture', 1),
              vm.getCodeMapping('pep', 1),
              vm.getCodeMapping('m1_m2', 1)
            ]).then((res) => {
              vm.selectOptions.optCfStracture = res[0];
              vm.selectOptions.optPep = res[1];
              vm.selectOptions.optM1M2 = res[2];
            });
            break;

          case 180: // platform_tft_ltps
          case 190: // platform_tft_igzo
            Promise.all([
              vm.getCodeMapping('polar_smc', 1),
              vm.getCodeMapping('gate_number', 1),
              vm.getCodeMapping('gate_symmetry1s', 1),
              vm.getCodeMapping('gate_symmetry2dh', 1),
              vm.getCodeMapping('gate_symmetry2dv', 1),
              vm.getCodeMapping('gate_symmetry3t', 1),
              vm.getCodeMapping('gate_arrangement', 1),
              vm.getCodeMapping('has_ldd', 1),
              vm.getCodeMapping('igzo_gate_structure', 1),
              vm.getCodeMapping('hybrid_oxide_tft_igzo_sd_um', 1),
              vm.getCodeMapping('substrate', 1),
              vm.getCodeMapping('metal_material2', 1)
            ]).then((res) => {
              vm.selectOptions.optPolarSmc = res[0];
              vm.selectOptions.optGateNumber = res[1];
              vm.selectOptions.optGateSymmetry1s = res[2];
              vm.selectOptions.optGateSymmetry2dh = res[3];
              vm.selectOptions.optGateSymmetry2dv = res[4];
              vm.selectOptions.optGateSymmetry3t = res[5];
              vm.selectOptions.optGateArrangement = res[6];
              vm.selectOptions.optLightlyDopedDrain = res[7];
              vm.selectOptions.optIgzoGateStructure = res[8];
              vm.selectOptions.optHybridOxideTftIgzoSdUm = res[9];
              vm.selectOptions.optSubstrate = res[10];
              vm.selectOptions.optMetalMaterials2 = res[11];
            });
            break;
        }
        vm.$bus.$emit('setLoading', false);
      }
    }
  },
  methods: {
    changeRdUnit(unitId) {
      const vm = this;
      store.dispatch('setRdUnit', unitId); // vuex
      console.log('assign to', vm.rdGenUnit);
      vm.getCategoryList(vm.rdGenUnit, 2);
    },
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList,
        ifCheckAuth: 1 // upload
      };

      RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          switch (categoryLevel) {
            case 2:
              vm.optClass1 = result.data;
              break;
            case 3:
              vm.optClass2 = result.data;
              break;
          }
        }
      });
    },
    getCodeMapping(typeName, ifValue2Int) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        if (!ifValue2Int) {
          return result.data;
        }
        // 轉數字
        let r2int = [];
        if (result.data && result.data.length > 0) {
          r2int = result.data.map((item) => {
            const a = item;
            a.value = Number(a.value);
            return a;
          });
        }
        return r2int;
      });
    },
    checkStep(stepNo) {
      const vm = this;
      switch (stepNo) {
        case 0:
          console.log(vm.formData);
          this.$refs.form.validate().then((success) => {
            if (success) {
              vm.currStep += 1;
            } else {
              vm.$alert.warning('資料填寫不完整/超過長度<br/>請填入必要欄位');
            }
          });
          break;
        case 1:
          if (vm.formData.modelCardFiles.length === 0) {
            vm.$alert.warning('請上傳 Model Card 檔案');
            return;
          }
          // ARC / PLATFORM
          if (
            vm.formData.qAReportFiles.length === 0
            && vm.formData.deviceCategory !== 60
            && vm.formData.deviceActiveLayer !== 170
          ) {
            vm.$alert.warning('請上傳 QA Report Data 檔案');
            return;
          }
          vm.currStep += 1;
          break;
        case 2:
          if (vm.formData.measurementDataFiles.length === 0) {
            vm.$alert.warning('請上傳 Measurement Data 檔案');
            return;
          }
          // PLATFORM TFT
          if (
            vm.formData.goldenSampleFiles.length === 0
            && vm.formData.deviceCategory === 60
            && vm.formData.deviceActiveLayer === 170
          ) {
            vm.$alert.warning('請上傳 Golden Sample 檔案');
            return;
          }
          vm.currStep += 1;
          break;
        case -1:
          vm.currStep -= 1;
          break;
      }
    },
    save() {
      const vm = this;
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (vm.formData.modelCardFiles.length === 0) {
            vm.$alert.warning('請上傳 Model Card 檔案');
            return;
          }
          // ARC
          if (
            vm.formData.qAReportFiles.length === 0
            && vm.formData.deviceCategory !== 60
            && vm.formData.deviceActiveLayer !== 170
          ) {
            vm.$alert.warning('請上傳 QA Report Data 檔案');
            return;
          }
          if (vm.formData.measurementDataFiles.length === 0) {
            vm.$alert.warning('請上傳 Measurement Data 檔案');
            return;
          }
          // PLATFORM TFT
          if (
            vm.formData.goldenSampleFiles.length === 0
            && vm.formData.deviceCategory === 60
            && vm.formData.deviceActiveLayer === 170
          ) {
            vm.$alert.warning('請上傳 Golden Sample 檔案');
            return;
          }

          // console.log(0);
          // file desc -> 只能1筆
          vm.formData.modelCardFiles[0].fileDesc = vm.$refs.modelCardFiles.files[
            vm.$refs.modelCardFiles.files.length - 1
          ].fDesc;
          if (
            vm.formData.qAReportFiles
            && vm.formData.qAReportFiles.length > 0
          ) {
            vm.formData.qAReportFiles[0].fileDesc = vm.$refs.qAReportFiles.files[
              vm.$refs.qAReportFiles.files.length - 1
            ].fDesc;
          }
          vm.formData.measurementDataFiles[0].fileDesc = vm.$refs.measurementDataFiles.files[
            vm.$refs.measurementDataFiles.files.length - 1
          ].fDesc;
          if (
            vm.formData.goldenSampleFiles
            && vm.formData.goldenSampleFiles.length > 0
          ) {
            vm.formData.goldenSampleFiles[0].fileDesc = vm.$refs.goldenSampleFiles.files[
              vm.$refs.goldenSampleFiles.files.length - 1
            ].fDesc;
          }

          // console.log(1);
          vm.formData.rdGenUnit = vm.rdGenUnit; // required
          if (vm.formData.rdGenUnit === 10) {
            vm.formData.mcTag = '測試版'; // ARC default
          }
          const params = {
            userId: vm.userInfo.userId,
            data: vm.formData
          };
          ModelCardApiService.createModelCard1(params).then((response) => {
            const result = response.data;
            if (result.success) {
              vm.$alert.success('儲存成功');

              if (result.data && result.data > 0) {
                setTimeout(() => {
                  vm.$router.push({
                    name: 'ModelCardDetail',
                    params: {
                      mcId: result.data,
                      deviceCategory: vm.formData.deviceCategory,
                      deviceActiveLayer: vm.formData.deviceActiveLayer,
                      retUrl: 'ModelCardIndex'
                    }
                  });
                }, 1000);
              } else {
                setTimeout(() => {
                  vm.$router.push({
                    name: 'ModelCardIndex'
                  });
                }, 1000);
              }
            } else {
              vm.$alert.error(result.message);
            }
          });
        } else {
          vm.$alert.warning('資料不完整<br/>請檢查');
        }
      });
    },
    goBack() {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardIndex'
      });
    },
    onCancelUploadForModelCard() {},
    onUploadedForModelCard(file) {
      const vm = this;
      vm.formData.fileDataForModelCard = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.modelCardFiles = [];
          vm.formData.modelCardFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
        } else {
          vm.$refs.modelCardFiles.files.splice(
            vm.$refs.modelCardFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForModelCard(file, index) {
      const vm = this;
      vm.formData.modelCardFiles.splice(index, 1);
    },
    onCancelUploadForQAReport() {},
    onUploadedForQAReport(file) {
      const vm = this;
      vm.formData.fileDataForQAReport = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.qAReportFiles = [];
          vm.formData.qAReportFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
        } else {
          vm.$refs.qAReportFiles.files.splice(
            vm.$refs.qAReportFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForQAReport(file, index) {
      const vm = this;
      vm.formData.qAReportFiles.splice(index, 1);
    },
    onCancelUploadForMeasureParsing() {},
    onUploadedForMeasureParsing(file) {
      const vm = this;
      const formData = new FormData();
      let excelKind;

      if (vm.isLtps) { excelKind = 4; }
      if (vm.isLed) { excelKind = 5; }

      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', excelKind);
      formData.append('UserId', vm.userInfo.userId);

      ModelCardApiService.uploadMeasureData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
          vm.formData.measurementDataFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc);
        } else {
          vm.$alert.error(result.message);
          vm.$refs.measurementDataFiles.files.splice(
            vm.$refs.measurementDataFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForMeasureParsing(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onCancelUploadForMeasure() {},
    onUploadedForMeasure(file) {
      const vm = this;
      vm.formData.measurementDataFiles = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
        } else {
          vm.$refs.measurementDataFiles.files.splice(
            vm.$refs.measurementDataFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForMeasure(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onCancelUploadForFitting() {},
    onUploadedForFitting(file) {
      const vm = this;
      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', 1);
      formData.append('UserId', vm.userInfo.userId);
      // console.log(file);
      ModelCardApiService.uploadFittingData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.measurementDataFiles = [];
          vm.formData.measurementDataFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
          vm.formData.measurementDataFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc); // 結果訊息
        } else {
          vm.$alert.error(result.message);
          vm.$refs.measurementDataFiles.files.splice(
            vm.$refs.measurementDataFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForFitting(file, index) {
      const vm = this;
      vm.formData.measurementDataFiles.splice(index, 1);
    },
    onUploadedForSample(file) {
      const vm = this;
      vm.formData.goldenSampleFiles = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', 2);
      formData.append('UserId', vm.userInfo.userId);

      ModelCardApiService.uploadFittingData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.goldenSampleFiles = [];
          vm.formData.goldenSampleFiles.push({
            fileId: result.data.fileId,
            fileName: result.data.fileName,
            fileSize: result.data.fileSize
          });
          vm.formData.goldenSampleFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc); // 結果訊息
        } else {
          vm.$alert.error(result.message);
          vm.$refs.goldenSampleFiles.files.splice(
            vm.$refs.goldenSampleFiles.files.length - 1,
            1
          );
        }
      });
    },
    onDeletedForSample(file, index) {
      const vm = this;
      vm.formData.goldenSampleFiles.splice(index, 1);
    },
    onCancelUploadForSample() {}
  },
  mounted() {
    const vm = this;

    // 上傳權限: 重取 ARC/Platform
    store.dispatch('setRdUnit', null);
    console.log('create/isRDArc:', vm.isRDArc);
    console.log('create/isRDPlatform:', vm.isRDPlatform);
    if (vm.isRDArc === 'Y') {
      store.dispatch('setRdUnit', 10);
    }
    if (vm.isRDPlatform === 'Y') {
      store.dispatch('setRdUnit', 20);
    }

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCategoryList(vm.rdGenUnit, 2),
      vm.getCodeMapping('fab'),
      vm.getCodeMapping('yes_no')
    ]).then((res) => {
      vm.selectOptions.optFabName = res[1];
      vm.selectOptions.optYesNo = res[2];
      vm.$bus.$emit('setLoading', false);
    });
  }
};
</script>
