<template>
  <div>
    <div class="card border">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col w-100">
            <div class="form-group w-100">
              <label>Gen Unit</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ledId===40 ? 'ARC LED' : 'Platform LED'}}</button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#"
                      v-on:click.prevent="changeLed(40)">ARC LED</a>
                    <a class="dropdown-item" href="#"
                      v-on:click.prevent="changeLed(70)">Platform LED</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>mcID</label>
              <input type="text" class="form-control" v-model="cond.mcId" />
            </div>
          </div>
          <!-- <div class="col">
            <div class="form-group">
              <label>Color</label>
              <select class="form-control" v-model="cond.ledColor" disabled>
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.ledColor"
                  v-bind:value="item.categoryId"
                  :key="index"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
          </div> -->
          <div class="col">
            <div class="form-group">
              <label>W</label>
              <input type="text" class="form-control" v-model="cond.ledWidth" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>L</label>
              <input
                type="text"
                class="form-control"
                v-model="cond.ledLength"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Vender</label>
              <select class="form-control" v-model="cond.ledVendor">
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.ledVendor"
                  v-bind:value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center mb-1">
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="clearCond()"
        >
          <i class="fas fa-times"></i> 清空
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click.prevent="search(true)"
        >
          <i class="fas fa-search"></i> 查詢
        </button>
      </div>
    </div>
    <div class="card border mt-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th style="width: 50px">Select</th>
                  <th>mcID</th>
                  <th>LED Model ID</th>
                  <th>Color</th>
                  <th>W</th>
                  <th>L</th>
                  <th>Vender</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dataList"
                  :key="item.mcId"
                  @click="onSelectChange(item)"
                  style="cursor: pointer"
                >
                  <td>
                    <input
                      name="mcId"
                      :id="'mcId_' + item.mcId"
                      type="checkbox"
                      class="checkbox"
                      :value="item.mcId"
                      v-model="selectedIds"
                      @change="onSelectChange(item)"
                    />
                  </td>
                  <td>{{ item.mcId }}</td>
                  <td>{{ item.modelId }}</td>
                  <td>{{ item.ledColorName }}</td>
                  <td>{{ item.ledWidth }}</td>
                  <td>{{ item.ledLength }}</td>
                  <td>{{ item.ledVendorName }}</td>
                </tr>
              </tbody>
            </table>
            <pagination
              :totalRecords="totalRecords"
              :defaultPageSize="cond.pageSize"
              @pageChangeEvent="pageChange"
              ref="pagin"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import {
  RawDataApiService,
  CodeMappingApiService,
  PanelCalcApiService
} from '@/utils/apiService';
import _ from 'lodash';

export default {
  components: {
    Pagination
  },
  props: {
    colorName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ledId: 40,
      cond: {
        pageSize: 5,
        pageNum: 1,
        ledColor: null
      },
      totalRecords: 0,
      selectedIds: [],
      dataList: [],
      selectOptions: {
        ledColor: [],
        ledVendor: []
      },
      defaultColor: null
    };
  },
  methods: {
    changeLed(ledId) {
      const vm = this;
      vm.ledId = ledId;

      vm.$bus.$emit('setLoading', true);
      Promise.all([
        vm.getCategoryList(ledId, 3)
      ]).then((res) => {
        vm.selectOptions.ledColor = res[0];
        console.log(vm.selectOptions.ledColor);

        // 更新 LED 指定 color
        vm.cond.ledColor = _.find(vm.selectOptions.ledColor, {
          categoryName: vm.colorName
        }).categoryId;
        vm.defaultColor = vm.cond.ledColor;
        console.log(vm.cond.ledColor);
        vm.$bus.$emit('setLoading', false);
      });
    },
    onSelectChange(val) {
      const vm = this;
      vm.selectedIds = [];
      vm.selectedIds.push(val.mcId);
      this.$emit('selected', val);
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    },
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 5,
        pageNum: 1,
        ledColor: vm.defaultColor
      };
    },
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList
      };

      return RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    getCodeMapping(typeName) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    search(isNewCondition) {
      const vm = this;

      if (isNewCondition) {
        vm.cond.pageNum = 1;
        vm.$refs.pagin.resetPage();
      }

      vm.dataList = [];
      PanelCalcApiService.getLedDataList(vm.cond).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.dataList = res.data.items;
          vm.totalRecords = res.data.totalRecords;
        }
      });
    }
  },
  mounted() {
    const vm = this;

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCategoryList(vm.ledId, 3),
      vm.getCodeMapping('led_vendor')
    ]).then((res) => {
      vm.selectOptions.ledColor = res[0];
      vm.cond.ledColor = _.find(vm.selectOptions.ledColor, {
        categoryName: vm.colorName
      }).categoryId;
      vm.defaultColor = vm.cond.ledColor;
      vm.selectOptions.ledVendor = res[1];
      vm.search();
      vm.$bus.$emit('setLoading', false);
    });
  }
};
</script>

<style scoped>
.checkbox {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  width: 20px;
  height: 20px;
  margin-top: 0.2em;
  margin-right: 0.3em;
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
}

.checkbox::after {
  content: "";
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  position: absolute;
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: 0px;
  top: 0;
  font-size: 10px;
}
.checkbox:checked::after {
  content: "\2714";
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
</style>
