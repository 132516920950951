<template>
  <div class="wrapper">
    <nav class="main-header navbar navbar-expand navbar-light border-bottom" v-if="false">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button">
            <i class="fa fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="fas fa-user-tie"></i>
            {{ userInfo.cname }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" v-on:click.prevent="logout()">
            <i class="fas fa-sign-out-alt"></i> 登出
          </a>
        </li>
      </ul>
    </nav>

    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <router-link to="/" class="brand-link">
        <i
          class="fab fa-angular fa-2x text-primary brand-image elevation-3"
          style="margin-left: 0.5rem"
        ></i>
        <span class="brand-text font-weight-light">AUO Guide</span>
      </router-link>
      <div class="sidebar">
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li class="nav-item">
              <router-link
                to="/GuideRoom"
                class="nav-link"
                exact-active-class="active"
              >
                <i class="nav-icon fas fa-male"></i>
                <p>Guide Room</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>

    <div class="content-wrapper">
      <section class="content-header py-1">
        <div class="container-fluid">
          <div class="row my-0">
            <div class="col-sm-6">
              <h5 class="mb-1">
                <i class="nav-icon fas fa-list-ul"></i>
                {{ pageTitle }}
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section id="vueapp" class="content">
        <div class="container-fluid" ref="container">
          <loading :active="loading" :is-full-page="false">
            <i class="fas fa-3x fa-spinner fa-spin text-warning"></i>
          </loading>
          <router-view></router-view>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import { CAPCleanAuthToken } from '@/capAuth/index';
import { mapGetters, mapActions } from 'vuex';
import { WEB_IMG_URL } from '@/utils/config';
import Loading from 'vue-loading-overlay';

$(() => {
  function navEvent(event) {
    const sliderbarDom = $('div.sidebar ul.sidenav-second-level');
    const nvaDom = $('div.sidebar a.nav-link.active');
    const isSidebarOpen = !$('body').hasClass('sidebar-collapse');
    switch (event.type) {
      case 'mouseenter':
        if (isSidebarOpen) return;
        sliderbarDom.has(nvaDom).addClass('show');
        break;
      case 'shown':
        sliderbarDom.has(nvaDom).addClass('show');
        break;
      case 'mouseleave':
      case 'collapsed':
        if (isSidebarOpen) return;
        sliderbarDom.removeClass('show');
        break;
    }
  }

  $('.main-sidebar').on('mouseenter mouseleave', navEvent);
  $('body').on('shown.lte.pushmenu collapsed.lte.pushmenu', navEvent);
});

export default {
  name: 'Layout',
  components: {
    Loading
  },
  data() {
    return {
      pageTitle: 'AUO Guide',
      loading: false,
      urlWebImg: WEB_IMG_URL
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.pageTitle = to.meta.title || 'AUO Guide';
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      uploadPermission: 'getUploadPermission',
      downloadPermission: 'getDownloadPermission',
      isITAdmin: 'isITAdmin',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      rdGenUnit: 'getRdUnit'
    })
  },
  methods: {
    ...mapActions(['setLogout']),
    logout() {
      const vm = this;

      vm.$confirm('確定要登出嗎?').then((result) => {
        if (result.value) {
          // CAPCleanAuthToken();
          // this.setLogout();
          vm.$router.go(0);
        }
      });
    }
  },
  created() {
    const vm = this;
    this.$bus.$on('setLoading', (value) => {
      vm.loading = value;
    });
    // console.log(this.urlWebImg);
  },
  beforeDestroy() {
    this.$bus.$off('setLoading');
  }
};
</script>

<style>
.sidebar .nav-sidebar > .nav-item .sidenav-second-level > li {
  line-height: 0.8;
  list-style: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover {
  width: 350px !important;
}

.vld-overlay {
  position: fixed;
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.vld-icon {
  display: flex;
  justify-content: center;
}
</style>
