<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="modelName">ModelID/mcID</label>
                <input type="text" class="form-control"
                  v-model="cond.mixModelName" />
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="class1">Gen Unit / 元件種類</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-outline-secondary dropdown-toggle"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{rdGenUnit===10 ? 'ARC' : 'Platform'}}</button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#"
                        v-on:click.prevent="changeRdUnit(10)">ARC</a>
                      <a class="dropdown-item" href="#"
                        v-on:click.prevent="changeRdUnit(20)">Platform</a>
                    </div>
                  </div>
                  <select id="class1" class="form-control" v-model="cond.deviceCategory">
                    <option value=""></option>
                    <option v-for="(item, index) in optClass1"
                      v-bind:value="item.categoryId" :key="index">
                      {{ item.categoryName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="class1">建立者</label>
                <!-- <input type="text" class="form-control" v-model="cond.createdUser"/> -->
                <emp-picker v-model="multiEmp"></emp-picker>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="class1">建立時間</label>
                <div>
                  <date-picker
                    v-model="cond.createdTimeStart"
                    :clearable="false"
                    style="width: 45%"
                    format="YYYY/MM/DD"
                  ></date-picker>
                  <!-- <span class="ml-1 mr-1">~</span> -->
                  <date-picker
                    v-model="cond.createdTimeEnd"
                    :clearable="false"
                    style="width: 45%"
                    format="YYYY/MM/DD"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div v-if="cond.deviceCategory === 60"
              class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="deviceActiveLayer">元件主動層 <span class="required">*</span></label>
                <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                  <select id="deviceActiveLayer" v-model="cond.deviceActiveLayer"
                    :class="{ 'form-control': true, 'is-invalid': failed }">
                    <option value=""></option>
                    <option v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId" :key="index">
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <template v-if="isPfaSi">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName">Fab Name <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="fabName" v-model="cond.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      @change="getPfCondOptions()">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="pep">PEP <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="pep" v-model="cond.pep"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      @change="getPfCondOptions()">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optPep"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="cfStracture">CF Structure <span class="required">*</span>
                  </label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="cfStracture" v-model="cond.cfStracture"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      @change="getPfCondOptions()">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optCfStracture"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="m1M2">M1/M2 <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="m1M2" v-model="cond.m1M2"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      @change="getPfCondOptions()">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optM1M2"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftQualityRecipe">元件膜質 <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="tftQualityRecipe" v-model="cond.tftQualityRecipe"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      :disabled="disPfCol.tftRecipe"
                      @change="getPfCondOptions('chlen')">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.pfTftRecipeList"
                        v-bind:value="item" :key="index">
                        {{ item }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelMetalLen">TFT Channel Metal Length
                    <span class="required">*</span>
                  </label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="tftChannelMetalLen" v-model="cond.tftChannelMetalLen"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      :disabled="disPfCol.chlen"
                      @change="getPfCondOptions('asRemain')">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.pfChannelLengthList"
                        v-bind:value="item" :key="index">
                        {{ item }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftAsRemain">TFT AS Remain <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="tftAsRemain" v-model="cond.tftAsRemain"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      :disabled="disPfCol.asRemain"
                      @change="getPfCondOptions('nPlusLength')">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.pfAsRemainList"
                        v-bind:value="item" :key="index">
                        {{ item }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelNPlusLen">TFT Channel N-plus Length
                  </label>
                  <select id="tftChannelNPlusLen" v-model="cond.tftChannelNPlusLen"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    :disabled="disPfCol.nPlusLength">
                    <option value=""></option>
                    <option v-for="(item, index) in selectOptions.pfNPlusLengthList"
                      v-bind:value="item" :key="index">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </template>

            <template v-if="cond.deviceCategory === 30 || (cond.deviceCategory === 60 && !isPfaSi)">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName">Fab Name <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="" class="w-100">
                    <select id="fabName" v-model="cond.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }">
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tegChannelWidth">
                    TEG Channel Width <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="tegChannelWidth"
                        type="number"
                        v-model="cond.tegChannelWidth"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="tegChannelWidth"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tegChannelLen1">
                    TEG Channel Length <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="tegChannelLen1"
                        type="number"
                        v-model="cond.tegChannelLength"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="tegChannelLen1"
                      >um</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="remarks_mix">備註/溫度 <span class="required"></span></label>
                  <input id="remarks_mix" type="text" class="form-control"
                    v-model="cond.mcNote" placeholder="模糊搜尋" />
                </div>
              </div>
            </template>

            <template v-if="isLed">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledWidth" title="LED 短邊">
                    LED Width <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledWidth"
                        type="number"
                        v-model="cond.ledWidth"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledWidth">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledLength" title="LED 長邊">
                    LED Length <span class="required"></span>
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledLength"
                        type="number"
                        v-model="cond.ledLength"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledLength">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="remarks_mix">備註/溫度 <span class="required"></span></label>
                  <input id="remarks_mix" type="text" class="form-control"
                    v-model="cond.mcNote" placeholder="模糊搜尋" />
                </div>
              </div>
            </template>

            <template v-if="cond.class1 === 2">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="class2">元件主動層 <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="">
                    <select
                      id="class2"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="cond.class2"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in class2List"
                        v-bind:value="item.categoryId"
                        :key="index"
                      >
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="class3">第三階分類 <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="">
                    <select
                      id="class3"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="cond.class3"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in class3List"
                        v-bind:value="item.categoryId"
                        :key="index"
                      >
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledWidth">LED 短邊 <span class="required"></span></label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledWidth"
                        type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="cond.ledWidth"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledWidth">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="ledLength">LED 長邊 <span class="required"></span></label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="">
                      <input
                        id="ledLength"
                        type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="cond.ledLength"
                      />
                    </validation-provider>
                    <label class="input-group-text" for="ledLength">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="remarks_mix">備註/溫度 <span class="required"></span></label>
                  <input id="remarks_mix" type="text" class="form-control"
                    v-model="cond.remark" placeholder="模糊搜尋" />
                </div>
              </div>
            </template>

            <template v-if="cond.class1 === 3">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="class2">元件主動層 <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="">
                    <select
                      id="class2"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="cond.class2"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in class2List"
                        v-bind:value="item.categoryId"
                        :key="index"
                      >
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="class3">第三階分類 <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="">
                    <select
                      id="class3"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="cond.class3"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in class3List"
                        v-bind:value="item.categoryId"
                        :key="index"
                      >
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fab">Fab <span class="required"></span></label>
                  <validation-provider v-slot="{ failed }" rules="">
                    <select
                      id="fab"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="cond.fab"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.fabList"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="remarks_mix">備註/溫度 <span class="required"></span></label>
                  <input id="remarks_mix" type="text" class="form-control"
                    v-model="cond.remarks" placeholder="模糊搜尋" />
                </div>
              </div>
            </template>

          </div>
        </div>

        <div class="card-footer text-center">
          <button
            type="button"
            class="btn btn-danger"
            v-on:click.prevent="clearCond()"
          >
            <i class="fas fa-times"></i> 清空
          </button>
          <button
            class="btn btn-primary"
            v-on:click.prevent="handleSubmit(search(true))"
            type="button"
          >
            <i class="fas fa-search"></i> 查詢
          </button>
        </div>
      </div>
    </validation-observer>

    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>mcID</th>
                  <th>Gen Unit</th>
                  <th>元件資訊</th>
                  <th>Fab Name</th>
                  <th>W / L</th>
                  <th>其他資訊</th>
                  <th>修改者</th>
                  <th style="width: 150px">內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in dataList" :key="item.mcId">
                  <td class="align-middle">
                    {{ item.mcId }}
                  </td>
                  <td class="align-middle">{{ item.rdGenUnitTitle }}</td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 30 || item.deviceCategory === 60">
                      {{ item.deviceCategoryTitle}} > {{ item.deviceActiveLayerTitle }}
                    </span>
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      {{ item.deviceCategoryTitle}} > {{ item.ledColorTitle }}
                    </span>
                    <span v-if="item.deviceCategory === 50">
                      {{ item.deviceCategoryTitle}} > {{ item.oledProcessTitle }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      -
                    </span>
                    <span v-else>
                      {{ item.fabName }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-if="item.deviceCategory === 30 || item.deviceCategory === 60">
                      {{ item.tegChannelWidth}}
                      / {{item.tegChannelLength}}{{ item.tftChannelMetalLen}}
                    </span>
                    <span v-if="item.deviceCategory === 40 || item.deviceCategory === 70">
                      {{ item.ledWidth}} / {{ item.ledLength}}
                    </span>
                    <span v-if="item.deviceCategory === 50">
                      -
                    </span>
                  </td>
                  <td class="align-middle">
                    <template v-if="item.deviceCategory === 60 && item.deviceActiveLayer === 170">
                      <span class="badge badge-light mx-1" title="PEP">
                        {{item.pepTitle}}
                      </span>
                      <span class="badge badge-light mx-1" title="M1/M2">
                        {{item.m1M2Title}}
                      </span>
                    </template>
                    <template v-else>
                      <span class="badge badge-light mx-1" title="ModelID">
                        {{item.modelId}}
                      </span>
                    </template>
                  </td>
                  <td class="align-middle small">
                    {{ item.lastUser + " / " + item.lastUserName }}<br />
                    {{ item.lastTime | formatDateTime }}
                  </td>
                  <td class="align-middle">
                    <template v-if="downloadPermission === 'Y'">
                      <button class="btn btn-sm btn-secondary"
                        @click.prevent="viewModelCard(item.mcId,
                          item.deviceCategory, item.deviceActiveLayer)">
                        <i class="fas fa-upload"></i> 檢視
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pagination :totalRecords="totalRecords"
      @pageChangeEvent="pageChange" ref="pagin">
    </pagination>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import EmpPicker from '@/components/EmpPicker.vue';
import {
  ModelCardApiService,
  RawDataApiService,
  CodeMappingApiService
} from '@/utils/apiService';

export default {
  components: {
    Pagination,
    EmpPicker
  },
  data() {
    return {
      cond: {
        pageSize: 10,
        pageNum: 1,
        createdUser: null,
        rdGenUnit: null,
        deviceCategory: null,
        deviceActiveLayer: null
      },
      totalRecords: 0,
      dataList: [],
      optClass1: [],
      optClass2: [],
      optClass3: [],
      selectOptions: {
        optFabName: [],
        optPep: [], // 60
        optCfStracture: [], // 60
        optM1M2: [], // 60
        pfChannelLengthList: [], // 60
        pfTftRecipeList: [], // 60
        pfNPlusLengthList: [], // 60
        pfAsRemainList: [] // 60
      },
      disPfCol: { // 60
        chlen: true,
        tftRecipe: true,
        nPlusLength: true,
        asRemain: true
      },
      multiEmp: {}
    };
  },
  computed: {
    ...mapGetters({
      roleIdList: 'getRoleIdList',
      downloadPermission: 'getDownloadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    }),
    isPfaSi() {
      const vm = this;
      if (!vm.cond || !vm.cond.deviceCategory || !vm.cond.deviceActiveLayer) {
        return false;
      }
      return (
        vm.cond.deviceCategory === 60
        && vm.cond.deviceActiveLayer === 170
      );
    },
    isLed() {
      const vm = this;
      if (!vm.cond || !vm.cond.deviceCategory) {
        return false;
      }
      return (
        vm.cond.deviceCategory === 40 || vm.cond.deviceCategory === 70
      );
    },
    isLtps() {
      const vm = this;
      if (!vm.cond || !vm.cond.deviceCategory || !vm.cond.deviceActiveLayer) {
        return false;
      }
      return (
        vm.cond.deviceActiveLayer === 90
        || vm.cond.deviceActiveLayer === 180
      );
    },
    isOled() {
      const vm = this;
      if (!vm.cond || !vm.cond.deviceCategory) {
        return false;
      }
      return vm.cond.deviceCategory === 50;
    }
  },
  watch: {
    'cond.deviceCategory': function (val) {
      const vm = this;
      vm.optClass2 = [];
      vm.cond.deviceActiveLayer = null;
      if (val) {
        vm.getCategoryList(val, 3);
      }
    },
    'cond.deviceActiveLayer': function (val) {
      const vm = this;
      if (val) {
        vm.$bus.$emit('setLoading', true);
        switch (val) {
          case 170:
            Promise.all([
              vm.getCodeMapping('cf_stracture'),
              vm.getCodeMapping('pep'),
              vm.getCodeMapping('m1_m2')
            ]).then((res) => {
              vm.selectOptions.optCfStracture = res[0];
              vm.selectOptions.optPep = res[1];
              vm.selectOptions.optM1M2 = res[2];
            });
            break;
        }
        vm.$bus.$emit('setLoading', false);
      }
    }
  },
  methods: {
    changeRdUnit(unitId) {
      const vm = this;
      store.dispatch('setRdUnit', unitId); // vuex
      console.log('assign to', vm.rdGenUnit);
      vm.clearCond();
      vm.cond.rdGenUnit = vm.rdGenUnit; // required
      vm.getCategoryList(vm.rdGenUnit, 2);
    },
    getCodeMapping(typeName, ifValue2Int) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        if (!ifValue2Int) { return result.data; }
        // 轉數字
        let r2int = [];
        if (result.data && result.data.length > 0) {
          r2int = result.data.map((item) => {
            const a = item;
            a.value = Number(a.value);
            return a;
          });
        }
        return r2int;
      });
    },
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList
      };

      RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          switch (categoryLevel) {
            case 2:
              vm.optClass1 = result.data;
              break;
            case 3:
              vm.optClass2 = result.data;
              break;
          }
        }
      });
    },
    getPfCondOptions(colName) {
      const vm = this;
      // PF condition options: Platform a-Si
      vm.cond.rdGenUnit = vm.rdGenUnit; // required
      if (vm.cond && vm.cond.deviceActiveLayer && vm.cond.deviceActiveLayer === 170) {
        if (vm.cond.fabName && vm.cond.pep && vm.cond.m1M2
          && vm.cond.cfStracture) {
          vm.$bus.$emit('setLoading', true);
          vm.cond.roleIdList = vm.roleIdList;
          vm.cond.optCol = '';

          switch (colName) {
            case 'chlen':
              vm.cond.channelLength = null;
              vm.cond.asRemain = null;
              vm.cond.nPlusLength = null;
              vm.selectOptions.pfChannelLengthList = [];
              vm.cond.optCol = 'chlen';
              vm.disPfCol.chlen = false;
              vm.disPfCol.asRemain = true;
              vm.disPfCol.nPlusLength = true;
              break;
            case 'asRemain':
              vm.cond.asRemain = null;
              vm.cond.nPlusLength = null;
              vm.selectOptions.pfAsRemainList = [];
              vm.cond.optCol = 'asRemain';
              vm.disPfCol.asRemain = false;
              vm.disPfCol.nPlusLength = false;
              break;
            case 'nPlusLength':
              vm.cond.nPlusLength = null;
              vm.selectOptions.pfNPlusLengthList = [];
              vm.cond.optCol = 'nPlusLength';
              vm.disPfCol.nPlusLength = false;
              break;
            default:
              vm.cond.channelLength = null;
              vm.cond.tftQualityRecipe = null;
              vm.cond.asRemain = null;
              vm.cond.nPlusLength = null;
              vm.cond.optCol = 'tftRecipe';
              vm.disPfCol.tftRecipe = false;
              vm.disPfCol.chlen = true;
              vm.disPfCol.nPlusLength = true;
              vm.disPfCol.asRemain = true;
              break;
          }

          ModelCardApiService.searchPfColOpts(vm.cond).then((response) => {
            const result = response.data;
            // console.log(result);
            if (result.success) {
              if (vm.cond.optCol === 'tftRecipe') {
                vm.selectOptions.pfTftRecipeList = result.data.optTftRecipeList
                  .filter((item) => (item !== null && item !== undefined && item !== ''));
              }
              if (vm.cond.optCol === 'chlen') {
                vm.selectOptions.pfChannelLengthList = result.data.optChLengList
                  .filter((item) => (item !== null && item !== undefined && item !== ''));
              }
              if (vm.cond.optCol === 'asRemain') {
                vm.selectOptions.pfAsRemainList = result.data.optAsRemainList
                  .filter((item) => (item !== null && item !== undefined && item !== ''));
              }
              if (vm.cond.optCol === 'nPlusLength') {
                vm.selectOptions.pfNPlusLengthList = result.data.optNPlusLengthList
                  .filter((item) => (item !== null && item !== undefined && item !== ''));
              }
            }
            vm.$bus.$emit('setLoading', false);
          });
        }
      }
    },
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 10,
        pageNum: 1,
        createdUser: null,
        deviceCategory: null,
        deviceActiveLayer: null
      };
      vm.multiEmp = {};
    },
    search(isNewCondition) {
      const vm = this;

      this.$refs.form.validate().then((success) => {
        if (!success) {
          vm.$alert.warning('資料填寫不完整<br/>請填入必要欄位');
        } else {
          if (isNewCondition) {
            vm.cond.pageNum = 1;
            vm.$refs.pagin.resetPage();
          }

          vm.cond.rdGenUnit = vm.rdGenUnit; // required
          vm.cond.roleIdList = vm.roleIdList;
          vm.cond.createdUser = null; // emppicker
          if (vm.multiEmp && vm.multiEmp.empNo) {
            vm.cond.createdUser = vm.multiEmp.empNo;
          }
          ModelCardApiService.getModelCardList1(vm.cond).then((response) => {
            const res = response.data;
            if (res.success) {
              vm.dataList = res.data.items;
              vm.totalRecords = res.data.totalRecords;
              // console.log(vm.dataList);
            }
          });
        }
      });
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    },
    viewModelCard(id, dCategory, dActiveLayer) {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardDetail',
        params: {
          mcId: id,
          deviceCategory: dCategory,
          deviceActiveLayer: dActiveLayer,
          retUrl: 'ModelCardQuery'
        }
      });
      vm.$bus.$emit('setLoading', true);
    }
  },
  mounted() {
    const vm = this;
    // 下載不區分權限(上傳要)
    // if (!vm.rdGenUnit) {
    //   vm.$router.push({
    //     name: 'Home'
    //   });
    // }

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCategoryList(vm.rdGenUnit, 2),
      vm.getCodeMapping('fab')
    ]).then((res) => {
      vm.selectOptions.optFabName = res[1];
      vm.$bus.$emit('setLoading', false);
    });

    vm.search();
  }
};
</script>
