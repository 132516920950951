import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

// Cap
import { CAPReturnUrl } from '@/capAuth';
// import { CAPReturnUrl, CAPGetAuthToken } from '@/capAuth';
// import { CAPAuthorizateApi, RDAuthorityApi } from '@/utils/apiService';
import CoreModule from './core';
import AdminModule from './admin';
import ConfigModule from './config';
import GuideModule from './guide';

import Home from '../views/Home.vue';
import NoAuth from '../views/NoAuth.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      redirect: '/GuideRoom'
    },
    {
      path: '/',
      redirect: '/GuideRoom'
    },
    {
      name: 'Logout',
      path: '/Logout'
    },
    {
      name: 'Home',
      path: '/Home',
      component: Home,
      meta: {
        title: 'AUO Guide Tool'
      }
    },
    {
      name: 'NoAuth',
      path: '/NoAuth',
      component: NoAuth
    },
    ...CoreModule,
    ...AdminModule,
    ...ConfigModule,
    ...GuideModule
  ]
});

const isLocal = window.location.origin.indexOf('localhost') !== -1;

router.beforeEach(async (to, from, next) => {
  let loginSuccess = false;
  let roleList = [];

  if (isLocal) {
    loginSuccess = true;
    roleList = store.getters.getUserRole;
  } else {
    loginSuccess = true;
    roleList = store.getters.getUserRole;
    // // CAP 登入
    // const capToken = CAPGetAuthToken();
    // if (capToken && capToken !== '') {
    //   await CAPAuthorizateApi.get(capToken).then((response) => {
    //     store.dispatch('setUserInfo', response.data);
    //   });
    //   // await CAPAuthorizateApi.getRoleList(capToken).then((response) => {
    //   //   const roleList = response.data;
    //   //   store.dispatch('setUserRole', roleList);
    //   // });
    //   const user = store.getters.getUserInfo;

    //   if (user) {
    //     await RDAuthorityApi.getRoleList(user.userId).then((response) => {
    //       roleList = response.data.data;
    //       store.dispatch('setUserRole', roleList);
    //       loginSuccess = true;
    //     });
    //   }
    // }
  }

  // 登入成功
  if (loginSuccess) {
    // 判斷頁面權限
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //   const toName = to.name.toLowerCase();
    //   const roles = store.getters.getUserRole;

    //   const hasPermission = roles.some((role) =>
    //     role.componentName.toLowerCase() === toName
    //   );

    //   if (!hasPermission) {
    //     router.app.$alert.error('很抱歉，您沒有權限');
    //     next(false);
    //     return;
    //   }

    //   const isAdmin = roles.some((role) => role.roleId === 2);
    //   if (!isAdmin) {
    //     router.app.$alert.error('很抱歉，您沒有權限');
    //     next(false);
    //     return;
    //   }
    // }

    if (to.path !== '/NoAuth') {
      if (roleList.length === 0) {
        // router.app.$alert.error('很抱歉，您沒有權限');
        // next(false);
        next('/NoAuth');
        return;
      }
    }

    next();
    return;
  }
  // 登入失敗 導入CAP 登入頁面
  const url = CAPReturnUrl();
  window.location.href = url;
});

export default router;
