<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
                <label for="modelName">ModelID/mcID</label>
                <input type="text" class="form-control"
                  v-model="cond.mixModelName" />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="uploader">下載者</label>
              <input
                id="downloader"
                type="text"
                class="form-control"
                v-model="cond.downloader"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="clearCond()"
        >
          <i class="fas fa-times"></i> 清空
        </button>
        <button
          class="btn btn-primary"
          v-on:click.prevent="search(true)"
          type="submit"
        >
          <i class="fas fa-search"></i> 查詢
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>mcID</th>
                  <th>Gen Unit</th>
                  <th>元件資訊</th>
                  <th>ModelID</th>
                  <th>檔案類型</th>
                  <th>檔案名稱</th>
                  <th>下載者</th>
                  <th style="width: 100px;">內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataList" :key="index">
                  <td class="align-middle">{{ item.mcId }}</td>
                  <td class="align-middle">{{ item.rdGenUnitTitle }}</td>
                  <td class="align-middle">
                    {{ item.deviceCategoryTitle  }}
                  </td>
                  <td class="align-middle">{{ item.modelId }}</td>
                  <td class="align-middle">{{ item.fileTypeName }}</td>
                  <td class="align-middle">{{ item.fileName }}</td>
                  <td class="align-middle small">
                    {{ item.downloader + " / " + item.downloaderEmpName }}<br />
                    {{ item.downloadTime | formatDateTime }}
                  </td>
                  <td class="align-middle">
                    <button class="btn btn-sm btn-secondary"
                      @click.prevent="showDetail(item)">
                      <i class="fa fa-file-alt"></i> 內容
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pagination :totalRecords="totalRecords"
      @pageChangeEvent="pageChange" ref="pagin">
    </pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import { AccessReportApiService } from '@/utils/apiService';

export default {
  components: {
    Pagination
  },
  data() {
    return {
      cond: {
        pageSize: 10,
        pageNum: 1
      },
      dataList: [],
      projectList: [
        { projectId: 1, projectName: 'Test Project' }
      ],
      totalRecords: 1
    };
  },
  computed: {
    ...mapGetters({
      roleIdList: 'getRoleIdList'
    })
  },
  methods: {
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 10,
        pageNum: 1
      };
    },
    search(isNewCondition) {
      const vm = this;

      if (isNewCondition) {
        vm.cond.pageNum = 1;
        vm.$refs.pagin.resetPage();
      }

      vm.cond.roleIdList = vm.roleIdList;

      AccessReportApiService.getAccessInfo(vm.cond).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.dataList = res.data.items;
          vm.totalRecords = res.data.totalRecords;
        }
      });
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    },
    showDetail(item) {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardDetail',
        params: {
          mcId: item.mcId,
          retUrl: 'AccessReport'
        }
      });
      vm.$bus.$emit('setLoading', true);
    }
  },
  mounted() {
    const vm = this;
    vm.$bus.$emit('setLoading', true);
    vm.search();
  }
};
</script>
