<template>
  <div>
    <multiselect
      :id="'emp-' + _uid.toString()"
      v-model="myEmp"
      track-by="empNo"
      label="empInfo"
      placeholder="請輸入員工編號或姓名"
      :options="empList"
      :show-no-results="false"
      :customLabel="setCustomLabel"
      @search-change="search"
      :disabled="disabled"
    ></multiselect>
  </div>
</template>

<script>
import { EmpDataAllApiService } from '@/utils/apiService';

export default {
  data() {
    return {
      myEmp: this.value,
      empList: [],
      searchText: '',
      timeout: 0
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {
    value(val) {
      this.myEmp = val;
    },
    myEmp(val) {
      this.$emit('input', val);
    }
  },
  methods: {
    setCustomLabel(emp) {
      if (emp && emp.empNo && emp.empName) {
        return `${emp.empNo} / ${emp.empName}`;
      }
      return '';
    },
    getEmpOptionList(searchWord) {
      const vm = this;
      const params = { searchWord };
      EmpDataAllApiService.searchList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          vm.empList = result.data;
        } else {
          vm.$alert.error(result.message);
        }
      });
    },
    search(query) {
      const vm = this;

      if (query.length < 3) {
        return;
      }
      vm.myEmp = null;

      clearTimeout(vm.timeout);
      vm.timeout = setTimeout(() => {
        vm.getEmpOptionList(query);
      }, 500);
    }
  },
  mounted() {
  }
};
</script>
