import AccessReport from '../views/Admin/AccessReport/AccessReport.vue';

export default
[
  {
    name: 'AccessReport',
    path: '/Admin/AccessReport',
    component: AccessReport,
    meta: {
      title: '下載紀錄',
      requiresAuth: true
    }
  }
];
