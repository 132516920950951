<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <div class="card">
        <div class="card-body" v-if="uploadPermission==='Y'">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="class1">Gen Unit / 元件種類<span class="required">*</span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary dropdown-toggle"
                      type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false"
                      disabled>
                      {{formData.rdGenUnit === 10 ? 'ARC' : 'Platform'}}</button>
                  </div>
                  <validation-provider v-slot="{ failed }" rules="required"
                    class="w-50">
                    <select id="class1" v-model="formData.deviceCategory"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      disabled>
                      <option value=""></option>
                      <option v-for="(item, index) in optClass1"
                        v-bind:value="item.categoryId" :key="index">
                        {{ item.categoryName }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div v-if="formData.deviceCategory === 30 || formData.deviceCategory === 60"
              class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="deviceActiveLayer">元件主動層
                  <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select id="deviceActiveLayer" v-model="formData.deviceActiveLayer"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    disabled>
                    <option value=""></option>
                    <option v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId" :key="index">
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <div v-if="isLed" class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="ledColor">LED Color
                  <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select id="ledColor" v-model="formData.ledColor"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    disabled>
                    <option value=""></option>
                    <option v-for="(item, index) in optClass2"
                      v-bind:value="item.categoryId" :key="index">
                      {{ item.categoryName }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

            <div v-if="formData.rdGenUnit == 10" class="col-md-3 col-sm-6 col-12">
              <div class="form-group">
                <label for="mcTag">版本註記
                  <span class="required">*</span>
                </label>
                <validation-provider v-slot="{ failed }" rules="required">
                  <select id="mcTag" v-model="formData.mcTag"
                    :class="{ 'form-control': true, 'is-invalid': failed }">
                    <option v-for="(item, index) in selectOptions.optMcTag1"
                      v-bind:value="item.value" :key="index">
                      {{ item.name }}
                    </option>
                  </select>
                </validation-provider>
              </div>
            </div>

          </div>

          <div class="row">
            <template v-if="isPfaSi">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fabName">Fab Name <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="fabName" v-model="formData.fabName"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      disabled>
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optFabName"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="pep">PEP <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="pep" v-model="formData.pep"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      disabled>
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optPep"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="cfStracture">CF Structure <span class="required">*</span>
                  </label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="cfStracture" v-model="formData.cfStracture"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      disabled>
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optCfStracture"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="m1M2">M1/M2 <span class="required">*</span></label>
                  <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                    <select id="m1M2" v-model="formData.m1M2"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      disabled>
                      <option value=""></option>
                      <option v-for="(item, index) in selectOptions.optM1M2"
                        v-bind:value="item.value" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </validation-provider>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftQualityRecipe">元件膜質 <span class="required">*</span></label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                      <input id="tftQualityRecipe" type="text" v-model="formData.tftQualityRecipe"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        disabled/>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelMetalLen">TFT Channel Metal Length
                    <span class="required">*</span></label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required">
                      <input id="tftChannelMetalLen" type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftChannelMetalLen"
                        disabled/>
                    </validation-provider>
                    <label class="input-group-text" for="tftChannelMetalLen">um</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftAsRemain">TFT AS Remain <span class="required">*</span></label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="required" class="w-100">
                      <input id="tftAsRemain" type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftAsRemain"/>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <div class="form-group">
                  <label for="tftChannelNPlusLen">TFT Channel N-plus Length
                  </label>
                  <div class="input-group">
                    <validation-provider v-slot="{ failed }" rules="" class="w-100">
                      <input id="tftChannelNPlusLen" type="number"
                        :class="{ 'form-control': true, 'is-invalid': failed }"
                        v-model="formData.tftChannelNPlusLen"/>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </template>

            <div class="col-12">
              <div class="form-group">
                <label for="remark">其他備註資訊</label>
                <textarea id="mcNote" class="form-control"
                  rows="5" v-model="formData.mcNote"></textarea>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div v-if="isPfaSi" class="col-md-6 col-sm-6 col-12">
              <div class="form-group">
                <label>QA Report Files</label>
                <ex-file-upload ref="qAReportFiles"
                  @onUploaded="onUploadedForQAReport"
                  @onCancelUpload="onCancelUploadForQAReport"
                  @onDeleted="onDeletedForQAReport"
                  fileType="ppt" :isMultiple="false">
                </ex-file-upload>
              </div>
            </div>

            <template v-if="isLed">
              <div class="col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>LED Optical Files <span class="required"></span>
                    <a :href="urlWebImg+'template_led_optical-202205.xlsx'"
                      class="badge bg-secondary mx-1"
                      target="_self" title="download template">template</a>
                  </label>
                  <ex-file-upload ref="ledOpticalFiles"
                    @onUploaded="onUploadedForOptical"
                    @onCancelUpload="onCancelUploadForOptical"
                    @onDeleted="onDeletedForOptical"
                    fileType="xls" :isMultiple="false">
                  </ex-file-upload>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <pre v-if="formData.ledOpticalFiles
                    && formData.ledOpticalFiles.length > 0"
                    class="alert alert-primary h4"
                  >{{formData.ledOpticalFiles[0].retMsg.trim()}}</pre>
                </div>
              </div>
            </template>
          </div>

        </div>

        <div class="card-footer text-center">
          <button type="button" class="btn btn-secondary"
            v-on:click.prevent="goBack()">
            <i class="fas fa-arrow-circle-left"></i> 返回
          </button>
          <button type="button" class="btn btn-primary"
            v-on:click.prevent="handleSubmit(update())">
            <i class="fas fa-save"></i> 儲存
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WEB_IMG_URL } from '@/utils/config';
import ExFileUpload from '@/components/ExFileUpload.vue';
import {
  FileService,
  RawDataApiService,
  ModelCardApiService,
  CodeMappingApiService
} from '@/utils/apiService';

export default {
  components: {
    ExFileUpload
  },
  props: {
    mcId: { type: Number, default: 0 },
    deviceCategory: { type: Number, default: 0 },
    deviceActiveLayer: { type: Number, default: 0 }
  },
  data() {
    return {
      urlWebImg: WEB_IMG_URL,
      is1onload: true,
      optClass1: [],
      optClass2: [],
      optClass3: [],
      formData: {
        qAReportFiles: [],
        ledOpticalFiles: []
      },
      selectOptions: {
        optFabName: [],
        optPep: [], // 60
        optCfStracture: [], // 60
        optM1M2: [], // 60
        optMcTag1: [] // ARC
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      uploadPermission: 'getUploadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    }),
    isPfaSi() {
      const vm = this;
      if (!vm.formData) { return false; }
      return (vm.formData.deviceCategory === 60
        && vm.formData.deviceActiveLayer === 170);
    },
    isLed() {
      const vm = this;
      if (!vm.formData) { return false; }
      return (vm.formData.deviceCategory === 40
        || vm.formData.deviceCategory === 70);
    },
    isOled() {
      const vm = this;
      if (!vm.formData) { return false; }
      return (vm.formData.deviceCategory === 50);
    }
  },
  methods: {
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList,
        ifCheckAuth: 1 // upload
      };

      RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          switch (categoryLevel) {
            case 2:
              vm.optClass1 = result.data;
              // console.log(vm.optClass1);
              break;
            case 3:
              vm.optClass2 = result.data;
              // console.log(vm.optClass2);
              break;
          }
        }
      });
    },
    getCodeMapping(typeName) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    getMCInfo() {
      const vm = this;
      if (vm.mcId === 0) {
        return null;
      }

      const params = {
        mcId: vm.mcId,
        deviceCategory: vm.deviceCategory,
        deviceActiveLayer: vm.deviceActiveLayer,
        roleIdList: vm.roleIdList
      };

      return ModelCardApiService.getModelCardDetail1(params).then(
        (response) => {
          const result = response.data;
          if (result.success) {
            vm.formData = result.data;
          } else {
            vm.$alert.error(response.message);
          }
          // console.log(result.data);
          return result.data;
        }
      );
    },
    onCancelUploadForQAReport() {},
    onUploadedForQAReport(file) {
      const vm = this;
      vm.formData.fileDataForQAReport = [];

      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('UserId', vm.userInfo.userId);

      FileService.upload(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.qAReportFiles = [];
          vm.formData.qAReportFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
        } else {
          vm.$refs.qAReportFiles.files.splice(vm.$refs.qAReportFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForQAReport(file, index) {
      const vm = this;
      vm.formData.qAReportFiles.splice(index, 1);
    },
    onCancelUploadForOptical() {},
    onUploadedForOptical(file) {
      const vm = this;
      const formData = new FormData();
      formData.append('File', file.file);
      formData.append('FileType', file.type);
      formData.append('ExcelKind', 3);
      formData.append('UserId', vm.userInfo.userId);
      // console.log(file);
      ModelCardApiService.uploadOpticalData(formData).then((response) => {
        const result = response.data;
        if (result.success) {
          // reset
          vm.formData.ledOpticalFiles = [];
          vm.formData.ledOpticalFiles.push(
            {
              fileId: result.data.fileId,
              fileName: result.data.fileName,
              fileSize: result.data.fileSize
            }
          );
          vm.formData.ledOpticalFiles[0].retMsg = result.data.fileDesc;
          vm.$alert.success(result.data.fileDesc); // 結果訊息
        } else {
          vm.$alert.error(result.message);
          vm.$refs.ledOpticalFiles.files
            .splice(vm.$refs.ledOpticalFiles.files.length - 1, 1);
        }
      });
    },
    onDeletedForOptical(file, index) {
      const vm = this;
      vm.formData.ledOpticalFiles.splice(index, 1);
    },
    update() {
      const vm = this;
      this.$refs.form.validate().then((success) => {
        if (success) {
          // console.log(vm.formData.qAReportFiles);
          if (vm.formData.qAReportFiles && vm.formData.qAReportFiles.length > 0) {
            vm.formData.qAReportFiles[0].fileDesc = vm.$refs.qAReportFiles
              .files[vm.$refs.qAReportFiles.files.length - 1].fDesc;
          } else {
            vm.formData.qAReportFiles = [];
          }
          if (vm.formData.ledOpticalFiles && vm.formData.ledOpticalFiles.length > 0) {
            vm.formData.ledOpticalFiles[0].fileDesc = vm.$refs.ledOpticalFiles
              .files[vm.$refs.ledOpticalFiles.files.length - 1].fDesc;
          } else {
            vm.formData.ledOpticalFiles = [];
          }

          const params = {
            userId: vm.userInfo.userId,
            data: vm.formData
          };

          ModelCardApiService.updateModelCard1(params).then((response) => {
            const result = response.data;
            if (result.success) {
              vm.$alert.success('儲存成功');
              vm.goBack();
            } else {
              vm.$alert.error(result.message);
            }
          });
        } else {
          vm.$alert.warning('資料填寫不完整<br/>請填入必要欄位');
        }
      });
    },
    goBack() {
      const vm = this;
      vm.$router.push({
        name: 'ModelCardDetail',
        params: {
          mcId: vm.mcId,
          deviceCategory: vm.deviceCategory,
          deviceActiveLayer: vm.deviceActiveLayer,
          retUrl: 'ModelCardIndex'
        }
      });
      vm.$bus.$emit('setLoading', true);
    }
  },
  mounted() {
    const vm = this;
    console.log(`edit props: ${vm.mcId.toString()}`);

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCodeMapping('fab'),
      vm.getCodeMapping('cf_stracture'),
      vm.getCodeMapping('pep'),
      vm.getCodeMapping('m1_m2'),
      vm.getCodeMapping('mc_tag1')
    ]).then((res) => {
      vm.selectOptions.optFabName = res[0];
      vm.selectOptions.optCfStracture = res[1];
      vm.selectOptions.optPep = res[2];
      vm.selectOptions.optM1M2 = res[3];
      vm.selectOptions.optMcTag1 = res[4];
    });

    const p1 = new Promise((resolve) => {
      resolve(vm.getMCInfo()); // load data
    });
    p1.then((value) => {
      vm.formData = value;
      vm.getCategoryList(vm.formData.rdGenUnit, 2);
      vm.getCategoryList(vm.formData.deviceCategory, 3);
      vm.$bus.$emit('setLoading', false);
    });

    // console.log(vm.formData);
  }
};
</script>
