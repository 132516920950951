<template>
  <div>
    <validation-observer ref="formCalcResult">
      <div class="row">
        <table class="table bg-light py-1" v-show="isReadonly">
          <tbody>
            <tr class="text-center"><td class="h4">計算結果</td></tr>
          </tbody>
        </table>
        <div class="col-12 p-4 mb-0">
          <table class="table table-bordered">
            <tbody>
              <tr class="text-center bg-title">
                <td style="width: 200px">&nbsp;</td>
                <td colspan="2" class="text-danger h4">R</td>
                <td colspan="2" class="text-success h4">G</td>
                <td colspan="2" class="text-primary h4">B</td>
              </tr>
              <tr class="text-center">
                <td class="bg-title">Panel Nit</td>
                <td>
                  <template v-if="formData.ledSpectrumData === 'Y'">
                    <label>{{ formData.panelNitR }}</label>
                  </template>
                  <template v-else>
                    <validation-provider
                      v-slot="{ failed }"
                      :rules="{ required: true, number: true }"
                    >
                      <input
                        type="text"
                        :class="{
                          'form-control': true,
                          'is-invalid': failed || !panelNitCheckResult,
                        }"
                        v-model="formData.panelNitR"
                        :disabled="isReadonly"
                      />
                    </validation-provider>
                  </template>
                </td>
                <td class="text-info unit-col">cd/m<sup>2</sup></td>
                <td>
                  <template v-if="formData.ledSpectrumData === 'Y'">
                    <label>{{ formData.panelNitG }}</label>
                  </template>
                  <template v-else>
                    <validation-provider
                      v-slot="{ failed }"
                      :rules="{ required: true, number: true }"
                    >
                      <input
                        type="text"
                        :class="{
                          'form-control': true,
                          'is-invalid': failed || !panelNitCheckResult,
                        }"
                        v-model="formData.panelNitG"
                        :disabled="isReadonly"
                      />
                    </validation-provider>
                  </template>
                </td>
                <td class="text-info unit-col">cd/m<sup>2</sup></td>
                <td>
                  <template v-if="formData.ledSpectrumData === 'Y'">
                    <label>{{ formData.panelNitB }}</label>
                  </template>
                  <template v-else>
                    <validation-provider
                      v-slot="{ failed }"
                      :rules="{ required: true, number: true }"
                    >
                      <input
                        type="text"
                        :class="{
                          'form-control': true,
                          'is-invalid': failed || !panelNitCheckResult,
                        }"
                        v-model="formData.panelNitB"
                        :disabled="isReadonly"
                      />
                    </validation-provider>
                  </template>
                </td>
                <td class="text-info unit-col">cd/m<sup>2</sup></td>
              </tr>
              <tr class="text-center">
                <td class="bg-title">Single LED Current</td>
                <td>
                  <label>{{ formData.ledCurrentR }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
                <td>
                  <label>{{ formData.ledCurrentG }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
                <td>
                  <label>{{ formData.ledCurrentB }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
              </tr>
              <tr class="text-center">
                <td class="bg-title">Sub Pixel Current</td>
                <td>
                  <label>{{ formData.subPixelCurrR }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
                <td>
                  <label>{{ formData.subPixelCurrG }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
                <td>
                  <label>{{ formData.subPixelCurrB }}</label>
                </td>
                <td class="text-info unit-col">uA</td>
              </tr>
              <tr class="text-center">
                <td class="bg-title">Single LED Yield</td>
                <td>
                  <label>{{ formData.ledYieldR }}</label>
                </td>
                <td class="text-info unit-col">cd/A</td>
                <td>
                  <label>{{ formData.ledYieldG }}</label>
                </td>
                <td class="text-info unit-col">cd/A</td>
                <td>
                  <label>{{ formData.ledYieldB }}</label>
                </td>
                <td class="text-info unit-col">cd/A</td>
              </tr>
              <tr class="text-center">
                <td class="bg-title">Panel Current</td>
                <td colspan="5">
                  <label>{{ formData.panelCurrent }}</label>
                </td>
                <td class="text-info unit-col">A</td>
              </tr>
              <tr class="text-center">
                <td colspan="7">
                  <button
                    type="button"
                    class="btn btn-info mx-1"
                    style="width: 200px"
                    :disabled="isReadonly"
                    @click.prevent="calculate()"
                  >
                    <i class="fa fa-calculator"></i> Calculate
                  </button>
                  <button
                    type="button"
                    class="btn btn-info mx-1"
                    @click.prevent="plotCurrentYieldResult()"
                    :disabled="
                      !formData.ledMcInfoR ||
                      !formData.ledMcInfoG ||
                      !formData.ledMcInfoB
                    "
                  >
                    <i class="fas fa-chart-line"></i> Plot Current vs Yield
                  </button>
                  <button
                    type="button"
                    class="btn btn-info mx-1"
                    style="width: 200px"
                    @click.prevent="plotColorSpace()"
                    :disabled="
                      !colorSpaceData || formData.ledSpectrumData !== 'Y'
                    "
                  >
                    <i class="fa fa-chart-area"></i> Plot Color Space
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </validation-observer>

    <!-- Modal -->
    <vue-final-modal
      name="modal_color_space"
      v-model="showModal_color_space"
      :lock-scroll="false"
      :click-to-close="false"
      :esc-to-close="true"
      :z-index-auto="true"
      classes="modal-container"
      content-class="modal-content dark-mode"
    >
      <div class="modal-header">
        <h5>Color Space</h5>
        <button
          type="button"
          class="close"
          @click="showModal_color_space = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <template v-if="showModal_color_space">
          <color-space v-model="colorSpaceData"></color-space>
        </template>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showModal_color_space = false"
          :disabled="colorSpaceData == null"
        >
          Close
        </button>
      </div>
    </vue-final-modal>
    <!-- Modal -->
    <vue-final-modal
      name="modal_plot"
      v-model="showModal_plot"
      :lock-scroll="false"
      :click-to-close="false"
      :esc-to-close="true"
      :z-index-auto="true"
      classes="modal-container"
      content-class="modal-content dark-mode"
    >
      <div class="modal-header">
        <button type="button" class="close" @click="showModal_plot = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <template v-if="showModal_plot">
          <component
            :is="plotComponent"
            :chartType="chartType"
            v-model="chartData"
          ></component>
        </template>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showModal_plot = false"
        >
          Close
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ColorSpace from '@/views/Core/PanelCalc/Chart/ColorSpace.vue';
import CurrentYieldChart from '@/views/Core/PanelCalc/Chart/CurrentYieldChart.vue';
import { PanelCalcApiService } from '@/utils/apiService';

export default {
  components: {
    ColorSpace,
    CurrentYieldChart
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    isReadonly: { type: Boolean, default: false }
  },
  data() {
    return {
      showModal_color_space: false,
      colorSpaceData: null,
      panelNitCheckResult: true,
      showModal_plot: false,
      plotComponent: '',
      chartType: '',
      chartData: {
        currentYieldR: [],
        currentYieldG: [],
        currentYieldB: []
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    formData() {
      const vm = this;
      if (vm.formData) {
        // sub pixel current
        if (vm.formData.ledCurrentR) {
          vm.formData.subPixelCurrR = Number(vm.formData.ledCurrentR);
          if (vm.formData.ledCircuitTypeR === '3') {
            vm.formData.subPixelCurrR = Number(vm.formData.ledCurrentR)
              * Number(vm.formData.ledCircuitNumberR);
          }
          vm.formData.subPixelCurrR = vm.formData.subPixelCurrR.toFixed(2);
        }
        if (vm.formData.ledCurrentG) {
          vm.formData.subPixelCurrG = vm.formData.ledCurrentG;
          if (vm.formData.ledCircuitTypeG === '3') {
            vm.formData.subPixelCurrG = Number(vm.formData.ledCurrentG)
              * Number(vm.formData.ledCircuitNumberG);
          }
          vm.formData.subPixelCurrG = vm.formData.subPixelCurrG.toFixed(2);
        }
        if (vm.formData.ledCurrentB) {
          vm.formData.subPixelCurrB = vm.formData.ledCurrentB;
          if (vm.formData.ledCircuitTypeB === '3') {
            vm.formData.subPixelCurrB = Number(vm.formData.ledCurrentB)
              * Number(vm.formData.ledCircuitNumberB);
          }
          vm.formData.subPixelCurrB = vm.formData.subPixelCurrB.toFixed(2);
        }

        if (vm.formData.panelCurrent) {
          vm.formData.panelCurrent = vm.formData.panelCurrent.toFixed(4);
        }
      }
      vm.setColorSpaceData();
    }
  },
  methods: {
    plotColorSpace() {
      const vm = this;
      vm.showModal_color_space = true;
    },
    checkPanelNit() {
      const vm = this;
      vm.panelNitCheckResult = true;

      if (vm.formData.ledSpectrumData === 'N') {
        const totalNit = Number(Number(vm.formData.panelNitR)
          + Number(vm.formData.panelNitG)
          + Number(vm.formData.panelNitB)).toFixed(0);

        console.log(`totalNit: ${String(totalNit)} vs ${String(vm.formData.panelBrightness)}`);
        if (Number(totalNit).toFixed(0) !== Number(vm.formData.panelBrightness).toFixed(0)) {
          const gap = Number(totalNit).toFixed(0) - Number(vm.formData.panelBrightness).toFixed(0);
          vm.$alert.warning(`Panel Nit 的 RGB 三項和必須等於 Panel Brightness ${
            vm.formData.panelBrightness} / ${gap.toFixed(2)}`);
          vm.panelNitCheckResult = false;
        }
      }

      return vm.panelNitCheckResult;
    },
    calculate() {
      const vm = this;
      vm.$refs.formCalcResult.validate().then((validateResult) => {
        if (!validateResult) {
          vm.$alert.warning('請檢查填寫資料是否完整');
        } else {
          if (!vm.checkPanelNit()) {
            return;
          }

          const params = {
            userId: vm.userInfo.userId,
            data: vm.formData
          };
          PanelCalcApiService.calculate(params).then((response) => {
            const result = response.data;
            if (result.success) {
              vm.formData = result.data;
              vm.$alert.success('計算完成');
              console.log(`new id: ${result.data.panelCalcDataId}`);
              console.log(result.data); // show data
            } else {
              vm.$alert.error(result.message);
            }
          });
        }
      });
    },
    setColorSpaceData() {
      const vm = this;
      vm.colorSpaceData = {
        rPoint: {
          x: vm.formData.colorSpaceCiexR.toFixed(4),
          y: vm.formData.colorSpaceCieyR.toFixed(4)
        },
        gPoint: {
          x: vm.formData.colorSpaceCiexG.toFixed(4),
          y: vm.formData.colorSpaceCieyG.toFixed(4)
        },
        bPoint: {
          x: vm.formData.colorSpaceCiexB.toFixed(4),
          y: vm.formData.colorSpaceCieyB.toFixed(4)
        },
        area: vm.formData.colorSpaceArea
      };
    },
    plotCurrentYieldResult() {
      const vm = this;

      vm.$bus.$emit('setLoading', true);

      Promise.all([
        vm.getOpticalDataList(vm.formData.ledMcIdR),
        vm.getOpticalDataList(vm.formData.ledMcIdG),
        vm.getOpticalDataList(vm.formData.ledMcIdB)
      ]).then((res) => {
        vm.chartData.currentYieldR = res[0];
        vm.chartData.currentYieldG = res[1];
        vm.chartData.currentYieldB = res[2];

        vm.chartData.currentYieldR1 = [{
          current: vm.formData.ledCurrentR,
          yield: vm.formData.ledYieldR
        }];
        vm.chartData.currentYieldG1 = [{
          current: vm.formData.ledCurrentG,
          yield: vm.formData.ledYieldG
        }];
        vm.chartData.currentYieldB1 = [{
          current: vm.formData.ledCurrentB,
          yield: vm.formData.ledYieldB
        }];

        vm.plotComponent = 'CurrentYieldChart';
        vm.chartType = 'CurrentYield';
        vm.showModal_plot = true;
        vm.$bus.$emit('setLoading', false);
      });
    },
    getOpticalDataList(mcId) {
      const params = {
        mcId
      };
      return PanelCalcApiService.getOpticalDataList(params).then((response) => {
        const result = response.data;
        return result.data;
      });
    }
  },
  mounted() { },
  beforeDestroy() {}
};
</script>

<style scoped>
.bg-title {
  background-color: #dce6f2;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #c3c3c3;
}
.unit-col {
  width: 5rem;
  min-width: 5rem;
}
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 72%;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
