<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <template v-if="mcId != null && mcIndex">
              <model-card-view :mcId="mcId" :mcModel="mcIndex">
              </model-card-view>
            </template>
          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <button
          type="button"
          class="btn btn-secondary"
          v-on:click.prevent="goBack()">
          <i class="fas fa-arrow-circle-left"></i> 返回
        </button>
        <template v-if="uploadPermission === 'Y'">
          <button
            type="button"
            class="btn btn-success"
            v-if="
              mcIndex &&
              mcIndex.lastUser === userInfo.userId"
            v-on:click.prevent="goEdit()">
            <i class="fas fa-edit"></i> 編輯更新
          </button>
          <button
            type="button"
            class="btn btn-warning"
            v-on:click.prevent="goRevers()">
            <i class="fas fa-upload"></i> ModelCard 改版
          </button>
        </template>
        <template v-if="mcId != null && mcIndex">
          <span class="text-right text-secondary small float-right">
            {{ mcIndex.lastUserName }}<br />{{
              mcIndex.lastTime | formatDateTime
            }}
          </span>
        </template>
      </div>
    </div>

    <file-view :mcId="mcId" :isPfaSi="isPfaSi" :isLed="isLed"> </file-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModelCardView from '@/components/ModelCardView.vue';
import FileView from '@/components/MCFileView.vue';
import { ModelCardApiService } from '@/utils/apiService';

export default {
  components: {
    ModelCardView,
    FileView
  },
  data() {
    return {
      mcIndex: null
    };
  },
  props: {
    mcId: { type: Number, default: 0 },
    deviceCategory: { type: Number, default: 0 },
    deviceActiveLayer: { type: Number, default: 0 },
    retUrl: { type: String, default: '' }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      uploadPermission: 'getUploadPermission',
      downloadPermission: 'getDownloadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    }),
    isPfaSi() {
      const vm = this;
      if (vm.mcIndex == null) {
        return false;
      }
      return (
        vm.mcIndex.deviceCategory === 60 && vm.mcIndex.deviceActiveLayer === 170
      );
    },
    isLed() {
      const vm = this;
      if (!vm.mcIndex) {
        return false;
      }
      return (
        vm.mcIndex.deviceCategory === 40 || vm.mcIndex.deviceCategory === 70
      );
    },
    isLtps() {
      const vm = this;
      if (!vm.mcIndex) {
        return false;
      }
      return (
        vm.mcIndex.deviceActiveLayer === 90
        || vm.mcIndex.deviceActiveLayer === 180
      );
    },
    isOled() {
      const vm = this;
      if (!vm.mcIndex) {
        return false;
      }
      return vm.mcIndex.deviceCategory === 50;
    }
  },
  methods: {
    getMCInfo() {
      const vm = this;
      if (vm.mcId === 0) {
        return null;
      }

      const params = {
        mcId: vm.mcId,
        deviceCategory: vm.deviceCategory,
        deviceActiveLayer: vm.deviceActiveLayer,
        roleIdList: vm.roleIdList
      };

      return ModelCardApiService.getModelCardDetail1(params).then(
        (response) => {
          const result = response.data;
          if (!result.success) {
            vm.$alert.error(result.message);
          }
          // console.log(result.data);
          vm.mcIndex = result.data;
          return result.data;
        }
      );
    },
    goEdit() {
      const vm = this;
      vm.$router.push({
        name: 'EditModelCard1',
        params: {
          mcId: vm.mcId,
          deviceCategory: vm.deviceCategory,
          deviceActiveLayer: vm.deviceActiveLayer
        }
      });
      vm.$bus.$emit('setLoading', true);
    },
    goRevers() {
      const vm = this;
      vm.$router.push({
        name: 'ReversModelCard1',
        params: {
          mcId: vm.mcId,
          deviceCategory: vm.deviceCategory,
          deviceActiveLayer: vm.deviceActiveLayer,
          isPfaSi: vm.isPfaSi,
          isLed: vm.isLed,
          isLtps: vm.isLtps
        }
      });
      vm.$bus.$emit('setLoading', true);
    },
    goBack() {
      const vm = this;
      vm.$router.push({
        name: vm.retUrl,
        params: {
          mcId: vm.mcId
        }
      });
      vm.$bus.$emit('setLoading', true);
    }
  },
  created() {
    const vm = this;
    console.log(`detail props: ${vm.mcId.toString()}, ${vm.deviceCategory.toString()}, ${vm.deviceActiveLayer.toString()}`);
  },
  mounted() {
    const vm = this;
    vm.$bus.$emit('setLoading', true);
    vm.getMCInfo();
  }
};
</script>
