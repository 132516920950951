<template>
  <div>
    <div class="card">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="deviceActiveLayer">元件主動層</label>
              <select
                id="deviceActiveLayer"
                v-model="cond.deviceActiveLayer"
                class="form-control"
                :disabled="true"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in optClass2"
                  v-bind:value="item.categoryId"
                  :key="index"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="fabName">Fab Name</label>

              <select id="fabName" v-model="cond.fabName" class="form-control">
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.optFabName"
                  v-bind:value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="pep">PEP</label>

              <select id="pep" v-model="cond.pep" class="form-control">
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.optPep"
                  v-bind:value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="cfStracture">CF Structure</label>

              <select
                id="cfStracture"
                v-model="cond.cfStracture"
                class="form-control"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.optCfStracture"
                  v-bind:value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="m1M2">M1/M2</label>

              <select id="m1M2" v-model="cond.m1M2" class="form-control">
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.optM1M2"
                  v-bind:value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="tftQualityRecipe">元件膜質</label>
              <select
                id="tftQualityRecipe"
                v-model="cond.tftQualityRecipe"
                class="form-control"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.tftQualityRecipeList"
                  v-bind:value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="tftChannelMetalLen">TFT Channel Metal Length</label>
              <select
                id="tftChannelMetalLen"
                v-model="cond.tftChannelMetalLen"
                class="form-control"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in selectOptions.tftChannelMetalLenList"
                  v-bind:value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center pt-1">
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="clearCond()"
        >
          <i class="fas fa-times"></i> 清空
        </button>
        <button
          class="btn btn-primary"
          v-on:click.prevent="search(true)"
          type="button"
        >
          <i class="fas fa-search"></i> 查詢
        </button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>mcID</th>
                  <th>Gen Unit</th>
                  <th>元件資訊</th>
                  <th>Fab Name</th>
                  <th>W / L</th>
                  <th>其他資訊</th>
                  <th>修改者</th>
                  <th style="mix-width: 250px">Pixel / GOA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dataList" :key="item.mcId">
                  <td class="align-middle">
                    {{ item.mcId }}
                  </td>
                  <td class="align-middle">{{ item.rdGenUnitTitle }}</td>
                  <td class="align-middle">
                    <span>
                      {{ item.deviceCategoryTitle }} >
                      {{ item.deviceActiveLayerTitle }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span>
                      {{ item.fabName }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span>
                      {{ item.tegChannelWidth }}
                      / {{ item.tegChannelLength }}{{ item.tftChannelMetalLen }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="badge badge-light mx-1" title="PEP">
                      {{ item.pepTitle }}
                    </span>
                    <span class="badge badge-light mx-1" title="M1/M2">
                      {{ item.m1M2Title }}
                    </span>
                  </td>
                  <td class="align-middle small">
                    {{ item.lastUser + " / " + item.lastUserName }}<br />
                    {{ item.lastTime | formatDateTime }}
                  </td>
                  <td class="align-middle">
                    <button
                      class="btn btn-sm btn-secondary mr-1"
                      @click.prevent="ViewDetail(item, 'Pixel')"
                    >
                      <i class="fas fa-chart-line"></i> Pixel
                    </button>
                    <button
                      class="btn btn-sm btn-secondary"
                      @click.prevent="ViewDetail(item, 'GOA')"
                    >
                      <i class="fas fa-chart-line"></i> GOA
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :totalRecords="totalRecords"
      @pageChangeEvent="pageChange"
      ref="pagin"
    >
    </pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import {
  RawDataApiService,
  CodeMappingApiService,
  ModelCardApiService
} from '@/utils/apiService';

export default {
  components: {
    Pagination
  },
  data() {
    return {
      cond: {
        pageSize: 10,
        pageNum: 1,
        rdGenUnit: 20,
        deviceCategory: 60,
        deviceActiveLayer: 170
      },
      totalRecords: 0,
      dataList: [],
      optClass1: [],
      optClass2: [],
      selectOptions: {
        optFabName: [],
        optPep: [],
        optCfStracture: [],
        optM1M2: [],
        tftQualityRecipeList: [],
        tftChannelMetalLenList: []
      }
    };
  },
  methods: {
    clearCond() {
      const vm = this;
      vm.cond = {
        pageSize: 10,
        pageNum: 1,
        rdGenUnit: 20,
        deviceCategory: 60,
        deviceActiveLayer: 170
      };
    },
    search(isNewCondition) {
      const vm = this;

      if (isNewCondition) {
        vm.cond.pageNum = 1;
        vm.$refs.pagin.resetPage();
      }

      vm.cond.roleIdList = vm.roleIdList;
      vm.cond.createdUser = null;
      if (vm.multiEmp && vm.multiEmp.empNo) {
        vm.cond.createdUser = vm.multiEmp.empNo;
      }
      ModelCardApiService.getModelCardList1(vm.cond).then((response) => {
        const res = response.data;
        if (res.success) {
          vm.dataList = res.data.items;
          vm.totalRecords = res.data.totalRecords;
        }
      });
    },
    pageChange(page, pageSize) {
      const vm = this;
      vm.cond.pageNum = page;
      vm.cond.pageSize = pageSize;
      vm.search();
    },
    getCategoryList(parentCategoryId, categoryLevel) {
      const vm = this;
      const params = {
        parentCategoryId,
        categoryLevel,
        roleIdList: vm.roleIdList,
        ifCheckAuth: 1
      };

      RawDataApiService.getCategoryList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          switch (categoryLevel) {
            case 2:
              vm.optClass1 = result.data;
              break;
            case 3:
              vm.optClass2 = result.data;
              break;
          }
        }
      });
    },
    getCodeMapping(typeName) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    getPfCondOptions(colName) {
      const vm = this;

      vm.cond.roleIdList = vm.roleIdList;
      vm.cond.optCol = colName;

      const param = {
        rdGenUnit: vm.cond.rdGenUnit,
        deviceCategory: vm.cond.deviceCategory,
        deviceActiveLayer: vm.cond.deviceActiveLayer,
        optCol: colName
      };

      return ModelCardApiService.searchPfColOpts(param).then((response) => {
        const result = response.data;
        return result.data;
      });
    },
    ViewDetail(item, sheetPrefix) {
      const vm = this;
      vm.$router.push({
        name: 'MeasurementAndFittingDetail',
        params: {
          mcId: item.mcId,
          sheetPrefix
        }
      });
    }
  },
  mounted() {
    const vm = this;

    vm.$bus.$emit('setLoading', true);
    Promise.all([
      vm.getCategoryList(vm.cond.deviceCategory, 3),
      vm.getCodeMapping('fab'),
      vm.getCodeMapping('pep', 1),
      vm.getCodeMapping('cf_stracture', 1),
      vm.getCodeMapping('m1_m2', 1),
      vm.getPfCondOptions('tftRecipe'),
      vm.getPfCondOptions('chlen'),
      vm.search(true)
    ]).then((res) => {
      vm.selectOptions.optFabName = res[1];
      vm.selectOptions.optPep = res[2];
      vm.selectOptions.optCfStracture = res[3];
      vm.selectOptions.optM1M2 = res[4];
      vm.selectOptions.tftQualityRecipeList = res[5].optTftRecipeList.filter(
        (item) => item !== null && item !== undefined && item !== ''
      );
      vm.selectOptions.tftChannelMetalLenList = res[6].optChLengList.filter(
        (item) => item !== null && item !== undefined && item !== ''
      );
      vm.$bus.$emit('setLoading', false);
    });
  }
};
</script>
