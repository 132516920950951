import Vue from 'vue';
import './filter';
import './config';
import Swal from 'sweetalert2';
import ApiService from './apiService';

String.prototype.trim = function () {
  return this.replace(/(^\s*)|(\s*$)/g, '');
};
String.prototype.ltrim = function () {
  return this.replace(/(^\s*)/g, '');
};
String.prototype.rtrim = function () {
  return this.replace(/(\s*$)/g, '');
};

// Alert
const Toast = Swal.mixin({
  // toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 1500
});

Vue.prototype.$alert = {
  error(message) {
    return Toast.fire({
      icon: 'error',
      title: message,
      timer: 2500
    });
  },
  warning(message) {
    return Toast.fire({
      icon: 'warning',
      title: message,
      timer: 1800
    });
  },
  info(message) {
    return Toast.fire({
      icon: 'info',
      title: message
    });
  },
  question(message) {
    return Toast.fire({
      icon: 'question',
      title: message
    });
  },
  success(message) {
    return Toast.fire({
      icon: 'success',
      title: message
    });
  }
};

// Confirm
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});
Vue.prototype.$confirm = (message) => swalWithBootstrapButtons.fire({
  text: message,
  icon: 'question',
  showCancelButton: true,
  confirmButtonText: '確定',
  cancelButtonText: '取消',
  reverseButtons: true
});

const colorHex = [
  '#619ED6',
  '#6BA547',
  '#F7D027',
  '#B77EA3',
  '#6A808A',
  '#011f4b',
  '#DA1B24',
  '#8b4513',
  '#FF6F50',
  '#323B81',
  '#00A88F',
  '#740030',
  '#002B24',
  '#97A1D9',
  '#E48F1B',
  '#FFC9ED',
  '#60CEED',
  '#9CF168',
  '#F7EA4A',
  '#f000ff',
  '#242A2D',
  '#4d5d53'
];

export const genColor = (index) => {
  let color;

  if (index < colorHex.length) {
    color = colorHex[index];
  } else {
    const letters = 'ABCDEF'.split('');
    color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
  }

  return color;
};

export const hexToRgba = (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
      result[3],
      16
    )}, ${opacity})`
    : null;
};

export function propsValidator(route, component) {
  const props = {
    ...route.params
  };
  let comProps = {};
  // check props
  if (Object.prototype.hasOwnProperty.call(component, 'props')) {
    comProps = { ...component.props, ...comProps };
  }
  // check mixin props
  if (Object.prototype.hasOwnProperty.call(component, 'mixins')) {
    component.mixins.forEach((v) => {
      const mixin = v;
      if (Object.prototype.hasOwnProperty.call(mixin, 'props')) {
        comProps = { ...mixin.props, ...comProps };
      }
    });
  }

  Object.keys(props).forEach((key) => {
    const prop = props[key];
    if (!(prop instanceof comProps[key].type)) {
      props[key] = comProps[key].type(prop);
    }
  });

  return props;
}

export default {
  init() {
    ApiService.init(Vue);
  }
};
