<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="chart-block my-2 p-2">
          <template v-if="dataList != null && dataList.length > 0">
            <scatter-chart
              :chartData="getChartData()"
              :chartOptions="getChartOptions()"
              css-classes="chart-block"
            ></scatter-chart>
          </template>
        </div>
      </div>
    </div>
    <div class="table-scroll mt-2">
      <table
        class="
          table table-bordered table-condensed table-responsive-sm
          freeze-table
        "
      >
        <thead>
          <tr>
            <th>汲極電壓(伏特)<br />Vd(V)</th>
            <th>閘極電壓(伏特)<br />Vg(V)</th>
            <th>量測電流(安培)<br />Id Measure</th>
            <th>擬合電流(安培)<br />Id Fitting</th>
            <th>溫度℃<br />temp</th>
            <th>長度(um)<br />{{ sheetPrefix }} Length</th>
            <th>寬度(um)<br />{{ sheetPrefix }} Width</th>
            <th v-if="isPixel">量測方式<br />Pixel Measurement Method</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataList" :key="index">
            <td>{{ item.vd }}</td>
            <td>{{ item.vg }}</td>
            <td>{{ item.idMeasure }}</td>
            <td>{{ item.idFitting }}</td>
            <td>{{ item.temp }}</td>
            <td>{{ item.length }}</td>
            <td>{{ item.width }}</td>
            <td v-if="isPixel">{{ item.pixelMeasurementMethod }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ScatterChart from '@/components/Chart/ScatterChart.vue';
import { genColor } from '@/utils/index';
import _ from 'lodash';

export default {
  components: {
    ScatterChart
  },
  data() {
    return {};
  },
  props: {
    sheetPrefix: { type: String, default: '' },
    sheetSuffix: { type: String, default: '' },
    dataList: { type: Array, default: null }
  },
  computed: {
    isPixel() {
      const vm = this;
      return vm.sheetPrefix === 'Pixel';
    },
    xAxesLabel() {
      const vm = this;
      let label = '';
      if (vm.sheetSuffix === 'IdVg') {
        label = 'Vg';
      }
      if (vm.sheetSuffix === 'IdVd') {
        label = 'Vd';
      }
      return label;
    }
  },
  methods: {
    getChartData() {
      const vm = this;
      const datasets = [];

      switch (vm.sheetSuffix) {
        case 'IdVg': {
          const vdList = _.orderBy(_.uniq(_.map(vm.dataList, 'vd')));

          vdList.forEach((vd, vdIndex) => {
            const seriesData = _.orderBy(_.filter(vm.dataList, { vd }), 'vg');

            const measureDataList = _.map(seriesData, (item) => ({
              x: item.vg,
              y: item.idMeasure
            }));
            datasets.push({
              label: `Vd=${vd}V_Measurement`,
              data: measureDataList,
              fill: false,
              tension: 0.4,
              backgroundColor: genColor(vdIndex),
              borderColor: genColor(vdIndex),
              borderWidth: 3,
              borderDash: [7, 7],
              showLine: true,
              pointStyle: 'dash'
            });

            const fittingDataList = _.map(seriesData, (item) => ({
              x: item.vg,
              y: item.idFitting
            }));
            datasets.push({
              label: `Vd=${vd}V_Fitting`,
              data: fittingDataList,
              fill: false,
              tension: 0.4,
              backgroundColor: genColor(vdIndex),
              borderColor: genColor(vdIndex),
              borderWidth: 3,
              showLine: true,
              pointStyle: 'line'
            });
          });
          break;
        }
        case 'IdVd': {
          const vgList = _.orderBy(_.uniq(_.map(vm.dataList, 'vg')));

          vgList.forEach((vg, vgIndex) => {
            const seriesData = _.orderBy(_.filter(vm.dataList, { vg }), 'vd');

            const measureDataList = _.map(seriesData, (item) => ({
              x: item.vd,
              y: item.idMeasure
            }));
            datasets.push({
              label: `Vg=${vg}V_Measurement`,
              data: measureDataList,
              fill: false,
              tension: 0.4,
              backgroundColor: genColor(vgIndex),
              borderColor: genColor(vgIndex),
              borderWidth: 3,
              borderDash: [7, 7],
              showLine: true,
              pointStyle: 'dash'
            });

            const fittingDataList = _.map(seriesData, (item) => ({
              x: item.vd,
              y: item.idFitting
            }));
            datasets.push({
              label: `Vg=${vg}V_Fitting`,
              data: fittingDataList,
              fill: false,
              tension: 0.4,
              backgroundColor: genColor(vgIndex),
              borderColor: genColor(vgIndex),
              borderWidth: 3,
              showLine: true,
              pointStyle: 'line'
            });
          });
          break;
        }
        default: {
          break;
        }
      }

      const chartData = {
        datasets
      };

      return chartData;
    },
    getChartOptions() {
      const vm = this;

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        elements: {
          line: {
            fill: false
          },
          point: {
            radius: 0
          }
        },
        title: {
          display: true,
          text: 'Measurement & Fitting',
          fontSize: 20,
          fontStyle: 'bold',
          lineHeight: 1.2,
          padding: 20
        },
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            fontSize: 15
          },
          display: true
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: vm.xAxesLabel,
                fontColor: '#666666',
                fontSize: 15,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Id',
                fontColor: '#666666',
                fontSize: 15,
                fontStyle: 'bold',
                lineHeight: 1.2,
                padding: 10
              }
            }
          ]
        }
      };

      if (vm.sheetSuffix === 'IdVg') {
        chartOptions.scales.xAxes[0].ticks = {
          stepSize: 5
        };
        chartOptions.scales.yAxes[0].type = 'logarithmic';
        chartOptions.scales.yAxes[0].ticks = {
          max: 0.1
        };
      }

      return chartOptions;
    }
  },
  mounted() {}
};
</script>

<style scoped>
.table-scroll {
  height: 530px;
  overflow: auto;
  white-space: nowrap;
}
.freeze-table thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 3;
}

.freeze-table thead tr th {
  background-color: #6c757d;
  color: white;
}

.freeze-table thead tr th,
.freeze-table tbody tr td {
  min-width: 100px;
}

.freeze-table tbody {
  z-index: 1;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid gray;
}
</style>
