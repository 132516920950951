import Cookies from 'js-cookie';

function IsProduction() {
  return process.env.NODE_ENV === 'production';
}

// API
const capDevelopmentApiUrl = 'http://captest2.corpnet.auo.com/CAP_EX_API/api/AuthFuncs';
const capProductionApiUrl = 'http://auhqpl01.corpnet.auo.com/CAP_API/api/AuthFuncs';
export const capApiUrl = IsProduction()
  ? capProductionApiUrl
  : capDevelopmentApiUrl;

// Company
export const capCompany = 'AUO';

// SysId
const capDevelopmentSysId = '11260';
const capProductionSysId = '11260';
export const capSysId = IsProduction()
  ? capProductionSysId
  : capDevelopmentSysId;

// Login Page
const capDevelopmentUrl = 'http://captest2.corpnet.auo.com/CAP_EX/Login/Index';
const capProductionUrl = 'http://logon.corpnet.auo.com/login/index';
const capLoginUrl = IsProduction() ? capProductionUrl : capDevelopmentUrl;

// CookieName
const capDevelopmentCookieName = '.AUOCAPFORMSAUTHTEST_TOKEN';
const capProductionCookieName = '.AUOCAPFORMSAUTH_TOKEN';
export const capCookieName = IsProduction()
  ? capProductionCookieName
  : capDevelopmentCookieName;

// 获取CAP登入页面
export function CAPReturnUrl() {
  // 【测试区】
  // TestSiteAuthToken_URL=Y，因测试区无需注册，所以不知道哪些系统需要URL传递Token
  // ，加上这个参数，可在回调的URL中加入Token【只针对测试环境】
  // return cap_Login_url + "?ReturnUrl=" + encodeURIComponent(window.location.href)
  // + "&AppPath=" + encodeURIComponent(CAP_GetRootPath())
  // + "&TestSiteAuthToken_URL=Y";  //登入页面地址;
  // 【正式区】
  // return capLoginUrl + "?ReturnUrl=" + encodeURIComponent(window.location.href)
  // + "&AppPath=" + encodeURIComponent(CAPGetRootPath()); // 登入页面地址;

  const returnUrl = encodeURIComponent(window.location.href);
  const appPath = IsProduction()
    ? encodeURIComponent(`${window.location.origin}/`)
    : `${encodeURIComponent(
      `${window.location.origin}/`
    )}&TestSiteAuthToken_URL=Y`;
  return `${capLoginUrl}?ReturnUrl=${returnUrl}&AppPath=${appPath}`;
}

// 获取网站根路径(站点及虚拟目录)
export function CAPGetRootPath() {
  const pathName = window.location.pathname.substring(1);
  const webName = pathName === '' ? '' : pathName.substring(0, pathName.indexOf('/'));
  // 【注：有的系统没有webName，只需要截取到host即可，同样，注册时候也只需要注册到Host即可】
  // return window.location.protocol + '//' + window.location.host;
  return `${window.location.protocol}//${window.location.host}/${webName}`;
}

// 获取浏览器Cookie中存储的CAP AuthToken
export function CAPGetAuthToken() {
  const token = Cookies.get(capCookieName, { path: '/', domain: '.auo.com' });
  if (token) return token;
  return null;
}
// 登出
export function CAPCleanAuthToken() {
  Cookies.remove(capCookieName, { path: '/', domain: '.auo.com' });
}
