<template>
  <div>
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>mcID</label>
          <input type="text" class="form-control"
            v-model="formData.mcId" disabled/>
        </div>
      </div>
      <div v-if="formData.rdGenUnit == 10" class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>版本註記</label>
          <div class="h4">
            <span class="badge badge-primary w-100">{{formData.mcTag}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>Genernation Unit</label>
          <input type="text" class="form-control"
            v-model="formData.rdGenUnitTitle" disabled/>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>元件種類</label>
          <input type="text" class="form-control"
            v-model="formData.deviceCategoryTitle" disabled/>
        </div>
      </div>

      <div v-if="formData.deviceCategory === 30 || formData.deviceCategory === 60"
        class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>元件主動層</label>
          <input type="text" class="form-control"
            v-model="formData.deviceActiveLayerTitle" disabled/>
        </div>
      </div>

      <div v-if="formData.deviceCategory === 40 || formData.deviceCategory === 70"
        class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label for="ledColor">LED Color</label>
          <input type="text" class="form-control"
            v-model="formData.ledColorTitle" disabled/>
        </div>
      </div>

      <div v-if="formData.deviceCategory === 50"
        class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label for="oledProcess">OLED Process</label>
          <input type="text" class="form-control"
            v-model="formData.oledProcessTitle" disabled/>
        </div>
      </div>

      <template v-if="isPfaSi" id="platform_ftf_asi">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>Fab Name</label>
            <input type="text" class="form-control"
              v-model="formData.fabName" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>PEP</label>
            <input type="text" class="form-control"
              v-model="formData.pepTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>CF Structure</label>
            <input type="text" class="form-control"
              v-model="formData.cfStractureTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>M1/M2</label>
            <input type="text" class="form-control"
              v-model="formData.m1M2Title" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>元件膜質</label>
            <input type="text" class="form-control"
              v-model="formData.tftQualityRecipe" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>TFT Channel Metal Length</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.tftChannelMetalLen" disabled/>
              <label class="input-group-text">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>TFT AS Remain</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.tftAsRemain" disabled/>
              <label class="input-group-text">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>TFT Channel N-plus Length</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.tftChannelNPlusLen" disabled/>
              <label class="input-group-text">um</label>
            </div>
          </div>
        </div>
      </template>

      <template id="arc_platform_tft" v-if="formData.deviceCategory === 30 ||
        (formData.deviceActiveLayer === 180 || formData.deviceActiveLayer === 190)">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="modelId">TFT Model ID</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.modelId" disabled/>
            </div>
          </div>
        </div>

        <template id="arc_platform_tft_ltps" v-if="formData.deviceActiveLayer === 90
          || formData.deviceActiveLayer === 180">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="polarSmc">
                Polar Semiconductor
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.polarSmcTitle" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateNumber">
                Gate Number
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateNumberTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 1" class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateSymmetry1s">
                Gate Symmetry
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateSymmetry1sTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 2" class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateArrangement">
                Gate Arrangement
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateArrangementTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 2 && formData.gateArrangement == '1'"
            class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateSymmetry2dh">
                Gate Symmetry
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateSymmetry2dhTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 2 && formData.gateArrangement === 1"
            class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="m15">
                M1.5
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.m15Title" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 2 && formData.gateArrangement === 2"
            class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="optGateSymmetry2dv">
                Gate Symmetry
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateSymmetry2dvTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 2 && formData.gateArrangement === 2"
            class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="bottomGateInsulator">
                Bottom Gate Insulator
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.bottomGateInsulatorTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 3" class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateSymmetry3t">
                Gate Symmetry
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateSymmetry3tTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.gateNumber === 3" class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="bottomGateInsulator">
                Bottom Gate Insulator
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.bottomGateInsulatorTitle" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="channelDoping">
                Channel Doping
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.channelDopingTitle" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="lightlyDopedDrain">
                Lightly Doped Drain
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.lightlyDopedDrainTitle" disabled/>
              </div>
            </div>
          </div>
        </template>

        <template id="arc_tft_asi" v-if="formData.deviceActiveLayer === 100">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="gateSymmetry1s">
                Gate Symmetry
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.gateSymmetry1sTitle" disabled/>
              </div>
            </div>
          </div>
        </template>

        <template id="arc_platform_tft_igzo" v-if="formData.deviceActiveLayer === 110
          || formData.deviceActiveLayer === 190">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="igzoGateStructure">
                IGZO Gate Structure
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.igzoGateStructureTitle" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="hybridOxideTftIgzo">
                Hybrid Oxide TFT IGZO
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.hybridOxideTftIgzoTitle" disabled/>
              </div>
            </div>
          </div>
          <div v-if="formData.hybridOxideTftIgzo === 1" class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="hybridOxideTftIgzoSdUm">
                Hybrid Oxide TFT IGZO in SD region
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.hybridOxideTftIgzoSdUmTitle" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="pl1Design">
                PL1 Design
              </label>
              <div class="input-group">
                <input type="text" class="form-control"
                  v-model="formData.pl1DesignTitle" disabled/>
              </div>
            </div>
          </div>
        </template>

        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="fabName">Fab Name</label>
            <input type="text" class="form-control"
              v-model="formData.fabName" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="substrate">Substrate</label>
            <input type="text" class="form-control"
              v-model="formData.substrateTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="tegChannelWidth">
              TEG Channel Width
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.tegChannelWidth" disabled/>
              <label class="input-group-text" for="tegChannelWidth">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="tegChannelLen1">
              TEG Channel Length 1/2
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.tegChannelLen1" disabled/>
              <input type="text" class="form-control"
                v-model="formData.tegChannelLen2" disabled/>
              <label class="input-group-text" for="tegChannelLen1">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="deviceStressTest">
              Device Stress Test
            </label>
            <input type="text" class="form-control"
              v-model="formData.deviceStressTestTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="cvMeasurement">
              CV Measurement
            </label>
            <input type="text" class="form-control"
              v-model="formData.cvMeasurementTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ivMeasuringInstrument">
              IV Measuring Instrument Model Name
            </label>
            <input type="text" class="form-control"
              v-model="formData.ivMeasuringInstrument" disabled/>
          </div>
        </div>
        <div v-if="formData.cvMeasurement === 1" class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="cvMeasuringInstrument">
              CV Measuring Instrument Model Name
            </label>
            <input type="text" class="form-control"
              v-model="formData.cvMeasuringInstrument" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="gateInsulatorMaterialsThickness">
              Gate Insulator Materials and Thickness
            </label>
            <input type="text" class="form-control"
              v-model="formData.gateInsulatorMaterialsThickness" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="gateMaterials">
              Gate Materials
            </label>
            <input type="text" class="form-control"
              v-model="formData.gateMaterialsTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="sourceDrainMaterials">
              Source/Drain Materials
            </label>
            <input type="text" class="form-control"
              v-model="formData.sourceDrainMaterialsTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="powerLineMaterials">
              Power Line Materials
            </label>
            <input type="text" class="form-control"
              v-model="formData.powerLineMaterials" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="drainVoltageIdvg">
              Drain Voltage of IDVG
            </label>
            <input type="text" class="form-control"
              v-model="formData.drainVoltageIdvg" disabled/>
          </div>
        </div>
      </template>

      <template v-if="formData.deviceCategory === 40 || formData.deviceCategory === 70"
        id="arc_platform_led">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="modelId">LED Model ID</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.modelId" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledVendor">LED Vendor
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledVendorTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledChipStructure">
              LED Chip Structure
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledChipStructureTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtMaxLuminEfficiency">
              LED_MT Maximum Luminance Efficiency
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.ledMtMaxLuminEfficiency" disabled/>
              <label class="input-group-text" for="ledMtMaxLuminEfficiency">cd/A</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtCurrentAtMaxLumin">
              LED_MT Current at Maximum Luminance Efficiency
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.ledMtCurrentAtMaxLumin" disabled/>
              <label class="input-group-text" for="ledMtCurrentAtMaxLumin">uA</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledLength" title="LED 長邊">
              LED Length
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.ledLength" disabled/>
              <label class="input-group-text" for="ledLength">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledWidth" title="LED 短邊">
              LED Width
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.ledWidth" disabled/>
              <label class="input-group-text" for="ledWidth">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledThickness">
              LED Thickness
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.ledThickness" disabled/>
              <label class="input-group-text" for="ledThickness">um</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="deviceStressTest">
              Device Stress Test
            </label>
            <input type="text" class="form-control"
              v-model="formData.deviceStressTestTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="cvMeasurement">
              CV Measurement
            </label>
            <input type="text" class="form-control"
              v-model="formData.cvMeasurementTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ivMeasuringInstrument">
              IV Measuring Instrument Model Name
            </label>
            <input type="text" class="form-control"
              v-model="formData.ivMeasuringInstrument" disabled/>
          </div>
        </div>
        <div v-if="formData.cvMeasurement === 1" class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="cvMeasuringInstrument">
              CV Measuring Instrument Model Name
            </label>
            <input type="text" class="form-control"
              v-model="formData.cvMeasuringInstrument" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtParallelNumber">
              LED_MT Parallel Number
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledMtParallelNumber" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtPpi">
              LED_MT Chip Pixel Per Inch
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledMtPpi" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledLeadAlloy">
              LED Lead Alloy
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledLeadAlloy" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="massTransferTechniques">
              Mass Transfer Techniques
            </label>
            <input type="text" class="form-control"
              v-model="formData.massTransferTechniques" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="colorConversion">
              Color Conversion
            </label>
            <input type="text" class="form-control"
              v-model="formData.colorConversionTitle" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="colorConversionMaterials">
              Color Conversion Materials
            </label>
            <input type="text" class="form-control"
              v-model="formData.colorConversionMaterials" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="colorConversionMaterialsVendor">
              Color Conversion Materials Vendor
            </label>
            <input type="text" class="form-control"
              v-model="formData.colorConversionMaterialsVendor" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledManufactureYear">
              LED Manufacture Year
            </label>
            <input type="text" class="form-control"
              v-model="formData.ledManufactureYear" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtDominantWaveLength">
              LED_MT Dominant Wave Length
            </label>
            <input type="number" class="form-control"
              v-model="formData.ledMtDominantWaveLength" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtPeakWaveLength">
              LED_MT Peak Wave Length
            </label>
            <input type="number" class="form-control"
              v-model="formData.ledMtPeakWaveLength" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="ledMtCie1931ColorCoordinatesX">
              LED_MT CIE 1931 Color Coordinates
            </label>
            <div class="input-group">
              <input type="number" class="form-control"
                v-model="formData.ledMtCie1931ColorCoordinatesX" disabled/>
              <input type="number" class="form-control"
                v-model="formData.ledMtCie1931ColorCoordinatesY" disabled/>
            </div>
          </div>
        </div>
      </template>

      <template v-if="formData.deviceCategory === 50"
        id="arc_oled">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="modelId">OLED Model ID</label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.modelId" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledColor">OLED Color
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledColorTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledEmittingType">
              OLED Emitting Type
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledEmittingTypeTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label>Fab Name</label>
            <input type="text" class="form-control"
              v-model="formData.fabName" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="deviceStressTest">
              Device Stress Test
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.deviceStressTestTitle" disabled/>
            </div>
          </div>
        </div>
        <div v-if="formData.deviceStressTest===1" class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="deviceStressTestDuration">
              Device Stress Test Duration
            </label>
            <div class="input-group">
              <input id="deviceStressTestDuration" type="text"
                v-model="formData.deviceStressTestDuration"
                class="form-control" disabled/>
              <label class="input-group-text" for="deviceStressTestDuration">hr</label>
            </div>
          </div>
        </div>
        <div v-if="formData.deviceStressTest===1" class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="deviceStressTestTemper">
              Device Stress Test Temperature
            </label>
            <div class="input-group">
              <input id="deviceStressTestTemper" type="text"
                v-model="formData.deviceStressTestTemper"
                class="form-control" disabled/>
              <label class="input-group-text" for="deviceStressTestTemper">℃</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledCurrentResistance">
              OLED Current/Resistance Data Type
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledCurrentResistanceTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledCapacitanceDataType">
              OLED Capacitance Data Type
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledCapacitanceDataTypeTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledModelCardCapacitanceType">
              OLED Model Card Capacitance Type
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledModelCardCapacitanceTypeTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledModelTool">
              OLED Model Tool
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledModelTool" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledProcessYear">OLED Process Year</label>
            <input id="oledProcessYear" type="number"
              v-model="formData.oledProcessYear"
              class="form-control" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledEmittingArea">OLED Emitting Area</label>
            <div class="input-group">
              <input id="oledEmittingArea" type="number"
                v-model="formData.oledEmittingArea"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledEmittingArea">um2</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledAnodeMaterial">OLED Anode Material</label>
            <input id="oledAnodeMaterial" type="text"
              v-model="formData.oledAnodeMaterial"
              class="form-control" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledCathodeMaterial">OLED Cathode Material</label>
            <input id="oledCathodeMaterial" type="text"
              v-model="formData.oledCathodeMaterial"
              class="form-control" disabled/>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledStructure">
              OLED Structure
            </label>
            <div class="input-group">
              <input type="text" class="form-control"
                v-model="formData.oledStructureTitle" disabled/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledWorkingBrightness">OLED Working Brightness</label>
            <div class="input-group">
              <input id="oledWorkingBrightness" type="number"
                v-model="formData.oledWorkingBrightness"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledWorkingBrightness">nits</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledWorkingCurrent">OLED Working Current</label>
            <div class="input-group">
              <input id="oledWorkingCurrent" type="number"
                v-model="formData.oledWorkingCurrent"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledWorkingCurrent">uA</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledWorkingVoltage">OLED Working Voltage</label>
            <div class="input-group">
              <input id="oledWorkingVoltage" type="number"
                v-model="formData.oledWorkingVoltage"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledWorkingVoltage">V</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledWorkingLuminanceEfficiency">
              OLED Working Luminance Efficiency
            </label>
            <div class="input-group">
              <input id="oledWorkingLuminanceEfficiency" type="number"
                v-model="formData.oledWorkingLuminanceEfficiency"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledWorkingLuminanceEfficiency">
                cd/A
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledPeakWaveLength">OLED Peak Wave Length</label>
            <div class="input-group">
              <input id="oledPeakWaveLength" type="number"
                v-model="formData.oledPeakWaveLength"
                class="form-control" disabled/>
              <label class="input-group-text" for="oledPeakWaveLength">nm</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <div class="form-group">
            <label for="oledCie1931ColorCoordinatesX">
              OLED CIE 1931 Color Coordinates
            </label>
            <div class="input-group">
              <input id="oledCie1931ColorCoordinatesX" type="number"
                v-model="formData.oledCie1931ColorCoordinatesX"
                class="form-control" disabled/>
              <input id="oledCie1931ColorCoordinatesY" type="number"
                v-model="formData.oledCie1931ColorCoordinatesY"
                class="form-control" disabled/>
            </div>
          </div>
        </div>
      </template>

      <div v-if="!isPfaSi" class="col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label for="temperDuringMeasurement">
            Temperature During Measurement
          </label>
          <div class="input-group">
            <input type="number" class="form-control"
              v-model="formData.temperDuringMeasurement" disabled/>
            <label class="input-group-text" for="temperDuringMeasurement">℃</label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="remark">其他備註資訊</label>
          <textarea class="form-control" rows="5"
            v-model="formData.mcNote" disabled>
          </textarea>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ModelCardApiService } from '@/utils/apiService';

export default {
  data() {
    return {
      formData: {
        deviceCategory: 0,
        deviceActiveLayer: 0
      }
    };
  },
  props: {
    mcId: { type: Number, default: 0 },
    deviceCategory: { type: Number, default: 0 },
    deviceActiveLayer: { type: Number, default: 0 },
    mcModel: { type: Object, default: null }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      roleIdList: 'getRoleIdList',
      downloadPermission: 'getDownloadPermission',
      isRDArc: 'isRDArc',
      isRDPlatform: 'isRDPlatform',
      isITAdmin: 'isITAdmin',
      rdGenUnit: 'getRdUnit'
    }),
    isPfaSi() {
      const vm = this;
      if (!vm.formData) { return false; }
      return (vm.formData.deviceCategory === 60
        && vm.formData.deviceActiveLayer === 170);
    }
  },
  methods: {
    getMCInfo() {
      const vm = this;
      if (vm.mcId === 0) {
        return null;
      }

      const params = {
        mcId: vm.mcId,
        deviceCategory: vm.deviceCategory,
        deviceActiveLayer: vm.deviceActiveLayer,
        roleIdList: vm.roleIdList
      };

      return ModelCardApiService.getModelCardDetail1(params).then(
        (response) => {
          const result = response.data;
          vm.formData = result.data;
          // console.log(vm.formData);
          return result.data;
        }
      );
    }
  },
  created() {
    const vm = this;
    vm.$bus.$emit('setLoading', true);
  },
  mounted() {
    const vm = this;
    if (vm.mcId === 0) {
      return;
    }
    vm.$bus.$emit('setLoading', true);
    // model ok, no need to query
    if (vm.mcModel && vm.mcModel.mcId > 0) {
      vm.formData = vm.mcModel;
      console.log(vm.formData);
      vm.$bus.$emit('setLoading', false);
    } else {
      console.log(`props mcModel null. ${vm.mcModel.mcId}`);
      vm.getMCInfo();
      vm.$bus.$emit('setLoading', false);
    }
  }
};
</script>
