<template>
  <div>
    <div class="row">
      <table class="table bg-light py-1" v-show="isReadonly">
        <tbody>
          <tr class="text-center"><td class="h4">發光資訊</td></tr>
        </tbody>
      </table>
      <div class="col-12 table-responsive p-4 mb-0">
        <form>
          <div class="form-group row">
            <label for="modelName" class="col-sm-4 col-form-label text-center"
              >Panel Brightness <span class="required">*</span></label
            >
            <div class="col-sm-8">
              <div class="input-group">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="panelBrightness"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 230px; max-width: 230px"
                    v-model="formData.panelBrightness"
                    :disabled="isReadonly"
                  />
                </validation-provider>
                <label class="input-group-text">cd/m<sup>2</sup></label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="lightingDuty"
              class="col-sm-4 col-form-label text-center"
              >Lighting Duty <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <div class="form-inline">
                <label class="text-danger" style="width: 30px">R：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="lightingDutyR"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.lightingDutyR"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-success" style="width: 30px">G：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="lightingDutyG"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.lightingDutyG"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-primary" style="width: 30px">B：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="lightingDutyB"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.lightingDutyB"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="lightingDuty"
              class="col-sm-4 col-form-label text-center"
              >Circular Polarizer Transparency <span class="required">*</span>
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <validation-provider
                  v-slot="{ failed }"
                  :rules="{ required: true, number: true }"
                >
                  <input
                    id="circularPolarizerTransparency"
                    type="number"
                    :class="{ 'form-control': true, 'is-invalid': failed }"
                    style="width: 230px; max-width: 230px"
                    v-model="formData.circularPolarizerTransparency"
                    :disabled="isReadonly"
                  />
                </validation-provider>
                <label class="input-group-text">%</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="modelName" class="col-sm-4 col-form-label text-center"
              >Efficiency after Overcoat <span class="required">*</span></label
            >
            <div class="col-sm-8">
              <div class="form-inline">
                <label class="text-danger" style="width: 30px">R：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="efficiencyAfterOvercoatR"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.efficiencyAfterOvercoatR"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-success" style="width: 30px">G：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="efficiencyAfterOvercoatG"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.efficiencyAfterOvercoatG"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-primary" style="width: 30px">B：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed }"
                    :rules="{ required: true, number: true }"
                  >
                    <input
                      id="efficiencyAfterOvercoatB"
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      v-model="formData.efficiencyAfterOvercoatB"
                      :disabled="isReadonly"
                    />
                  </validation-provider>
                  <label class="input-group-text">%</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-sm-4 col-form-label text-center"
              >LED Number per Pixel <span class="required">*</span></label
            >
            <div class="col-sm-8">
              <div class="form-inline">
                <label class="text-danger" style="width: 30px">R：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed, failedRules }"
                    :rules="{
                      requiredForLedNumberPerPixel: {
                        ledCircuitNumber: formData.ledCircuitNumberR,
                        ledCircuitType: formData.ledCircuitTypeR,
                      },
                      positiveInt: formData.ledCircuitNumberR,
                      ledNumberPerPixelRule: {
                        ledCircuitNumber: formData.ledCircuitNumberR,
                        ledCircuitType: formData.ledCircuitTypeR,
                      },
                    }"
                  >
                    <input
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 100px"
                      v-model="formData.ledCircuitNumberR"
                      :disabled="isReadonly"
                      placeholder="Number"
                    />
                    <select
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 200px"
                      v-model="formData.ledCircuitTypeR"
                      :disabled="isReadonly"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.circuitType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="ml-2 text-danger">
                      {{ failedRules["positiveInt"] }}
                      {{ failedRules["ledNumberPerPixelRule"] }}
                    </span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-success" style="width: 30px">G：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed, failedRules }"
                    :rules="{
                      requiredForLedNumberPerPixel: {
                        ledCircuitNumber: formData.ledCircuitNumberG,
                        ledCircuitType: formData.ledCircuitTypeG,
                      },
                      positiveInt: formData.ledCircuitNumberG,
                      ledNumberPerPixelRule: {
                        ledCircuitNumber: formData.ledCircuitNumberG,
                        ledCircuitType: formData.ledCircuitTypeG,
                      },
                    }"
                  >
                    <input
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 100px"
                      v-model="formData.ledCircuitNumberG"
                      :disabled="isReadonly"
                      placeholder="Number"
                    />
                    <select
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 200px"
                      v-model="formData.ledCircuitTypeG"
                      :disabled="isReadonly"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.circuitType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="ml-2 text-danger">
                      {{ failedRules["positiveInt"] }}
                      {{ failedRules["ledNumberPerPixelRule"] }}</span
                    >
                  </validation-provider>
                </div>
              </div>
              <div class="form-inline">
                <label class="text-primary" style="width: 30px">B：</label>
                <div class="input-group">
                  <validation-provider
                    v-slot="{ failed, failedRules }"
                    :rules="{
                      requiredForLedNumberPerPixel: {
                        ledCircuitNumber: formData.ledCircuitNumberB,
                        ledCircuitType: formData.ledCircuitTypeB,
                      },
                      positiveInt: formData.ledCircuitNumberB,
                      ledNumberPerPixelRule: {
                        ledCircuitNumber: formData.ledCircuitNumberB,
                        ledCircuitType: formData.ledCircuitTypeB,
                      },
                    }"
                  >
                    <input
                      type="number"
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 100px"
                      v-model="formData.ledCircuitNumberB"
                      :disabled="isReadonly"
                      placeholder="Number"
                    />
                    <select
                      :class="{ 'form-control': true, 'is-invalid': failed }"
                      style="width: 200px"
                      v-model="formData.ledCircuitTypeB"
                      :disabled="isReadonly"
                    >
                      <option value=""></option>
                      <option
                        v-for="(item, index) in selectOptions.circuitType"
                        v-bind:value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="ml-2 text-danger">
                      {{ failedRules["positiveInt"] }}
                      {{ failedRules["ledNumberPerPixelRule"] }}</span
                    >
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { CodeMappingApiService } from '@/utils/apiService';

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    isReadonly: { type: Boolean, default: false }
  },
  data() {
    return {
      selectOptions: {
        circuitType: []
      }
    };
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    setValidationRule() {
      extend('requiredForLedNumberPerPixel', {
        params: ['ledCircuitNumber', 'ledCircuitType'],
        validate(value, { ledCircuitNumber, ledCircuitType }) {
          return {
            required: true,
            valid: (['', null, undefined].indexOf(ledCircuitNumber) === -1 && ['', null, undefined].indexOf(ledCircuitType) === -1)
          };
        },
        computesRequired: true
      });

      extend('ledNumberPerPixelRule', {
        params: ['ledCircuitNumber', 'ledCircuitType'],
        validate: (value, { ledCircuitNumber, ledCircuitType }) => {
          if (
            (ledCircuitNumber <= 1 && (ledCircuitType === '2' || ledCircuitType === '3'))
            || (ledCircuitNumber > 1 && ledCircuitType === '1')
          ) {
            return false;
          }
          return true;
        },
        message: '數字大於 1 應串聯或並聯'
      });
    },
    getCodeMapping(typeName) {
      return CodeMappingApiService.get(typeName).then((response) => {
        const result = response.data;
        return result.data;
      });
    }
  },
  mounted() {
    const vm = this;
    vm.setValidationRule();

    vm.$bus.$emit('setLoading', true);

    Promise.all([
      vm.getCodeMapping('circuit_type')
    ]).then((res) => {
      vm.selectOptions.circuitType = res[0];
      vm.$bus.$emit('setLoading', false);
      // console.log(vm.selectOptions.circuitType);
    });
  }
};
</script>
