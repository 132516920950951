import { extend } from 'vee-validate';

extend('number', {
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(\-|\+)?\d+(\.\d+)?$/;
    return regex.test(value);
  },
  message(field) {
    return `${field} 請輸入數字!`;
  }
});

extend('max', { // max-length
  validate(value, args) {
    return value.length <= args;
  },
  message: '超過長度!'
});

extend('min', { // min-length
  validate(value, args) {
    return value.length >= args;
  },
  message: '長度不足!'
});

// 正整數(不含0)
extend('positiveInt', {
  validate(value) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[0-9]*[1-9][0-9]*$/;
    return regex.test(value);
  },
  message: '請輸入正整數!'
});
